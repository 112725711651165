const ACCEPT_PRIVACYPOLICY = 'ACCEPT_PRIVACYPOLICY';
const REJECT_PRIVACYPOLICY = 'REJECT_PRIVACYPOLICY';

const REJECT_PRIVACYPOLICY_MESSAGE = 'Privacy Policy Permission denied.';
const REJECT_PRIVACYPOLICY_CODE = 405;

export default {
  ACCEPT_PRIVACYPOLICY,
  REJECT_PRIVACYPOLICY,
  REJECT_PRIVACYPOLICY_MESSAGE,
  REJECT_PRIVACYPOLICY_CODE,
};
