import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import ScheduleStatistic from './ScheduleStatistic';

const renderStatistic = (userId, schedule) => (
  <ScheduleStatistic key={schedule._id} schedule={schedule} userId={userId} />
);

const Groups = ({ user: { _id, groups = [] } = {} }) => (
  <Fragment>
    <h4 className="mb-3">{I18n.t('team')}</h4>
    {groups.map(({ _id: groupId, title, schedule = [] }) => (
      <div key={groupId} className="mb-2">
        <h6 className="mb-0 fz-18">{title}</h6>
        {schedule.map(s => renderStatistic(_id, s))}
      </div>
    ))}
  </Fragment>
);

Groups.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Groups;
