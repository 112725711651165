import types from './types';

const defaultStateNotes = {
  isPrivacyPolicyAccepted: true,
};

const privacyPolicyReducer = (state = defaultStateNotes, action) => {
  switch (action.type) {
    case types.ACCEPT_PRIVACYPOLICY:
      return { isPrivacyPolicyAccepted: true };
    case types.REJECT_PRIVACYPOLICY:
      return { isPrivacyPolicyAccepted: false };
    default:
      return state;
  }
};

export { privacyPolicyReducer };
