import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router';

import Component from './Component';

const enhancer = compose(
  withState('isCreateNotesOpen', 'setIsCreateNotesOpen', false),
  withRouter,
  withHandlers({
    onCreateNotesToggle: ({ isCreateNotesOpen, setIsCreateNotesOpen }) => () =>
      setIsCreateNotesOpen(!isCreateNotesOpen),
  })
);

export default enhancer(Component);
