const getUsers = state => ({
  users: state.users,
});

const getUser = state => ({
  user: state.user,
});

export default {
  getUsers,
  getUser,
};
