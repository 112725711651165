import { get, APIAddresses, query } from '../../utils/api';
import * as actions from './actions';

const fetchLessons = (skip, limit, filter, fields) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.LESSONS}${query({
        limit,
        skip,
        filter,
        fields,
      })}`,
      dispatch
    );
    const { lessons, count } = data;
    dispatch(actions.fetchLessons(lessons, count));
    return lessons;
  } catch (error) {
    throw error;
  }
};

const fetchLessonStatistic = (userId, scheduleId) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.LESSONS}/statistic/${userId}/${scheduleId}`,
      dispatch
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const clearLessons = () => async dispatch => dispatch(actions.clearLessons());

export default {
  fetchLessons,
  clearLessons,
  fetchLessonStatistic,
};
