import types from './types';
import { normalize } from '../../utils/data';

const fetchSettings = (notes, count) => ({
  count,
  type: types.FETCH_SETTINGS,
  settings: normalize('_id', notes),
});

const clearSettings = () => ({
  type: types.CLEAR_SETTINGS,
});

const activeSetting = (_id, intended) => ({
  _id,
  intended,
  type: types.ACTIVE_SETTING,
});

const updateSetting = setting => ({
  setting,
  type: types.UPDATE_SETTING,
});

const createSetting = setting => ({
  setting,
  type: types.CREATE_SETTING,
});
export { fetchSettings, clearSettings, updateSetting, activeSetting, createSetting };
