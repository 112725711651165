import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps, lifecycle } from 'recompose';
import { activityOperations } from '../../modules/activity';

import Component from './Component';

const throttleFetch = _.throttle(async (props, value) => {
  const {
    PER_PAGE, setActivities, setIsLoading, fetchActivityForAutosuggest, memberId,
  } = props;
  try {
    setIsLoading(true);
    const activities = await fetchActivityForAutosuggest(value, 0, PER_PAGE, { memberId });
    setIsLoading(false);
    setActivities(activities);
  } catch (err) {
    setIsLoading(false);
    setActivities([]);
  }
}, 500);

const enhancer = compose(
  connect(() => ({}), {
    fetchActivityForAutosuggest: activityOperations.fetchActivityForAutosuggest,
  }),
  withState('isLoading', 'setIsLoading', false),
  withState('activities', 'setActivities', []),
  withState('search', 'setSearch', ''),
  withProps({ PER_PAGE: 5 }),
  withProps(props => ({
    setDefaultValue: (activity) => {
      if (_.get(activity, '_id')) {
        props.setSearch(activity.title || '');
        props.onSelected(activity);
      }
    },
  })),
  withHandlers({
    onSearchChange: props => (e, { newValue }) => {
      if (!newValue) {
        props.onEmpty && props.onEmpty();
      }
      props.setSearch(newValue);
    },
    onSuggestionsClearRequested: props => () => props.setActivities([]),
    onSuggestionsFetchRequested: props => ({ value }) => throttleFetch(props, value),
    getSuggestionValue: () => suggestion => suggestion.title,
    onSuggestionSelected: props => (value, { suggestion }) => {
      props.onSelected(suggestion);
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { selectedValue, setSearch } = this.props;
      if (nextProps.selectedValue !== selectedValue && !nextProps.selectedValue) {
        setSearch('');
      }
    },
    componentWillMount() {
      const { defaultValue } = this.props;
      this.props.setDefaultValue(defaultValue);
    },
    componentWillUpdate(newProps) {
      const { defaultValue } = newProps;
      if (defaultValue && defaultValue !== this.props.defaultValue) {
        this.props.setDefaultValue(defaultValue);
      }
    },
  })
);

export default enhancer(Component);
