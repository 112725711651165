import React from 'react';
import Profile from '../../containers/Profile/index';

const ProfilePage = () => (
  <div>
    <Profile />
  </div>
);

export default ProfilePage;
