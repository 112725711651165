import _ from 'lodash';
import { compose, withHandlers, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { groupsOperations } from '../../../modules/groups';
import { usersOperations } from '../../../modules/users';
import { withGetUserProp } from '../../../utils/recompose-extensions/props-selectors';

import Component from './Component';

const getCoaches = coachIds =>
  createSelector(
    state => state.users,
    (users) => {
      const coaches = [];
      coachIds.forEach((_id) => {
        if (users.values[_id]) {
          coaches.push(users.values[_id]);
        }
      });
      return coaches;
    }
  );

const enhancer = compose(
  connect(
    (state, { group }) => ({
      coaches: getCoaches(group.coachIds)(state),
    }),
    {
      setActiveGroup: groupsOperations.setActiveGroup,
      fetchUser: usersOperations.fetchUser,
    }
  ),
  withState('isMembersOpen', 'setIsMembersOpen', false),
  withGetUserProp,
  withHandlers({
    onEditModalOpen: ({ setActiveGroup, onToggle, group }) => () => {
      setActiveGroup(group._id);
      onToggle();
    },
    onMembersToggle: ({ isMembersOpen, setIsMembersOpen }) => () =>
      setIsMembersOpen(!isMembersOpen),
  }),
  lifecycle({
    componentWillMount() {
      const { group } = this.props;
      group.coachIds.map(userId => this.props.fetchUser(userId));
    },
    componentWillUpdate({ group }) {
      if (_.difference(group.coachIds, this.props.group.coachIds).length) {
        group.coachIds.map(userId => this.props.fetchUser(userId));
      }
    },
  })
);

export default enhancer(Component);
