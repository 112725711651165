import _ from 'lodash';
import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { messagesSelectors, messagesOperations, messagesTypes } from '../../../modules/messages';
import { actionsSelectors } from '../../../modules/actions';
import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...loaderSelectors.getLoader(messagesTypes.FETCH_MESSAGES)(state),
      ...messagesSelectors.getMessages(state),
      ...actionsSelectors.getFilter(messagesTypes.MESSAGES)(state),
    }),
    {
      fetchMessages: messagesOperations.fetchMessages,
      clearMessages: messagesOperations.clearMessages,
      addMessage: messagesOperations.addMessage,
    }
  ),
  withProps({ PER_PAGE: 25 }),
  withHandlers({
    onFetch: ({
      fetchMessages, messages, PER_PAGE, filter,
    }) => () =>
      fetchMessages(messages.keys.length, PER_PAGE, filter),
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchMessages(0, this.props.PER_PAGE, {});
    },
    componentWillUpdate(newProps) {
      if (!_.isEqual(newProps.filter, this.props.filter)) {
        const { PER_PAGE, isLoading, filter } = newProps;
        this.props.clearMessages();
        if (!isLoading) {
          this.props.fetchMessages(0, PER_PAGE, filter);
        }
      }
    },
    componentWillUnmount() {
      this.props.clearMessages();
    },
  })
);

export default enhancer(Component);
