import moment from 'moment';
import React, { Fragment } from 'react';
import _ from 'lodash';
import { Translate } from 'react-i18nify';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const ListItem = ({
  message,
  isToggleMessage,
  onToggleMessage,
  getUserProp,
  createBy,
  joinUsers,
}) => (
  <Fragment>
    <div className="note-row pt-1 pb-1 pl-2 pr-2">
      <Row className="cursor-pointer" onClick={onToggleMessage}>
        <Col xs={10}>
          {!isToggleMessage && (
            <Fragment>
              <div className="fw-500 fz-14">{message.title}</div>
              <div className="fw-300 fz-14">
                {_.isEmpty(createBy || {}) ? null : (
                  <Fragment>
                    <b>
                      <Translate value="create_by" />:
                    </b>{' '}
                    {getUserProp(createBy, 'firstName')} {getUserProp(createBy, 'lastName')} &nbsp;
                  </Fragment>
                )}
                <b>
                  <Translate value="create_for" />:
                </b>{' '}
                {joinUsers(3)}
              </div>
            </Fragment>
          )}
        </Col>
        <Col className="text-right" xs={2}>
          {moment(message.createAt).isSame(moment(), 'day') ? (
            <div className="fw-500 fz-14">{moment(message.createAt).format('H:mm')}</div>
          ) : (
            <Fragment>
              <div className="fw-500 fz-14">{moment(message.createAt).format('DD MMM')}</div>
              <div className="fw-500 fz-14">{moment(message.createAt).format('YYYY')}</div>
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
    {isToggleMessage && (
      <Row>
        <Col xs={12}>
          <p className="fz-32 fw-900">{message.title}</p>
        </Col>
        <Col xs={10}>
          <p className="fz-20 fw-300">
            <p>{message.description}</p>
          </p>
        </Col>
        <Col xs={10}>
          <div className="fw-300 fz-14">
            {_.isEmpty(createBy || {}) ? null : (
              <Fragment>
                <b>
                  <Translate value="create_by" />:
                </b>{' '}
                {getUserProp(createBy, 'firstName')} {getUserProp(createBy, 'lastName')} &nbsp;
              </Fragment>
            )}
            <b>
              <Translate value="create_for" />:
            </b>{' '}
            {joinUsers()}
          </div>
        </Col>
        <Col className="text-right mb-3 mt-2" lg={12}>
          <FontAwesomeIcon
            className="cursor-pointer mr-3"
            size="2x"
            icon={faAngleUp}
            onClick={onToggleMessage}
          />
        </Col>
      </Row>
    )}
  </Fragment>
);

ListItem.propTypes = {
  joinUsers: PropTypes.func.isRequired,
  getUserProp: PropTypes.func.isRequired,
  createBy: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onToggleMessage: PropTypes.func.isRequired,
  isToggleMessage: PropTypes.bool.isRequired,
};

export default ListItem;
