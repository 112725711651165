import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Throttle } from 'react-throttle';
import { FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/index';
import { SelectType } from '../../../../components/index';

const Toolbar = ({ onSearch, onSelect, userGroups }) => (
  <div className="d-flex info-btns align-items-end flex-wrap">
    <FormGroup className="mb-0 mb-lg-0">
      <Label className="input-label fz-18 fw-500">{I18n.t('search_users')}</Label>
      <Label className="input-customize w-100">
        <FontAwesomeIcon icon={faSearch} />
        <Throttle time="500" handler="onChange">
          <Input
            className="form-control rounded-0 font-weight-bold w-100"
            type="text"
            bsSize="lg"
            onChange={onSearch}
          />
        </Throttle>
      </Label>
    </FormGroup>
    <SelectType
      label={I18n.t('user_groups')}
      data={userGroups}
      name="userGroups"
      onSelect={onSelect}
    />
  </div>
);

Toolbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  userGroups: PropTypes.array.isRequired,
};

export default Toolbar;
