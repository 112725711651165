import { createSelector } from 'reselect';

const getSettings = state => ({
  settings: state.settings,
});

const getActiveSetting = createSelector(
  state => state.settings,
  (settings) => {
    const {
      active, values, keys, intended,
    } = settings;
    const activeKey = keys.find(key =>
      values[key].type === active && values[key].intended === intended);
    return values[activeKey] || {};
  }
);

export default {
  getSettings,
  getActiveSetting,
};
