import { uniq } from 'lodash';
import types from './types';

const defaultStateNotes = {
  keys: [],
  values: {},
  count: 0,
  active: '',
};

const settingsReducer = (state = defaultStateNotes, action) => {
  switch (action.type) {
    case types.FETCH_SETTINGS: {
      const keys = uniq([...state.keys, ...action.settings.keys]);
      return {
        ...state,
        keys,
        count: action.count,
        values: { ...state.values, ...action.settings.values },
      };
    }
    case types.CREATE_SETTING:
    case types.UPDATE_SETTING: {
      const keys = uniq([action.setting._id, ...state.keys]);
      return {
        ...state,
        keys,
        values: { ...state.values, [action.setting._id]: action.setting },
      };
    }
    case types.ACTIVE_SETTING: {
      return {
        ...state,
        active: action._id,
        intended: action.intended,
      };
    }
    case types.CLEAR_SETTINGS:
      return defaultStateNotes;
    default:
      return state;
  }
};

export { settingsReducer };
