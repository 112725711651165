import _ from 'lodash';
import { connect } from 'react-redux';
import { lifecycle, compose, withProps } from 'recompose';
import { usersTypes } from '../../../modules/users/index';
import { actionsSelectors } from '../../../modules/actions';
import { statisticsSelectors, statisticsOperations } from '../../../modules/statistics';
import { statisticsAsXYToOptions, statisticsAsXYToDataChart } from './chart-js-config';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...actionsSelectors.getFilter(usersTypes.USER_STATISTICS)(state),
      ...statisticsSelectors.getStatisticsAsXY(state),
    }),
    {
      fetchStatistics: statisticsOperations.fetchStatistics,
      clearStatistics: statisticsOperations.clearStatistics,
    }
  ),
  withProps({
    statisticsAsXYToDataChart,
    statisticsAsXYToOptions,
  }),
  withProps(props => ({
    fetch: async (filter) => {
      const {
        from, to, activityId, groupId,
      } = filter;

      props.clearStatistics();
      await props.fetchStatistics(from, to, activityId, groupId);
    },
  })),
  lifecycle({
    componentDidMount() {
      const { filter, fetch } = this.props;
      fetch({
        activityId: filter.activityId,
        groupId: filter.groupId,
        from: filter.from,
        to: filter.to,
      });
    },
    componentWillUpdate(newProps) {
      if (!_.isEqual(newProps.filter, this.props.filter)) {
        const { filter, fetch } = newProps;
        fetch({
          activityId: filter.activityId,
          groupId: filter.groupId,
          from: filter.from,
          to: filter.to,
        });
      }
    },
  })
);

export default enhancer(Component);
