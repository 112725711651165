import types from './types';

const defaultState = {
  user: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: action.value };
    case types.SIGN_OUT:
      return defaultState;
    case types.CHANGE_PASSWORD:
      return { ...state, user: { ...state.user, isTemporaryPassword: false } };
    default:
      return state;
  }
};
