import { uniq, omit, without } from 'lodash';
import types from './types';

const defaultStateGroups = {
  keys: [],
  values: {},
  count: 0,
  active: '',
};

const groupsReducer = (state = defaultStateGroups, action) => {
  switch (action.type) {
    case types.FETCH_GROUPS: {
      const keys = uniq([...state.keys, ...action.groups.keys]);
      return {
        ...state,
        keys,
        count: action.count,
        values: { ...state.values, ...action.groups.values },
      };
    }
    case types.CREATE_GROUP: {
      const keys = uniq([action.group._id, ...state.keys]);
      return {
        ...state,
        keys,
        values: { ...state.values, [action.group._id]: action.group },
      };
    }
    case types.UPDATE_GROUP: {
      return {
        ...state,
        values: { ...state.values, [action.group._id]: action.group },
      };
    }
    case types.REMOVE_GROUP: {
      const values = omit(state.values, [action.id]);
      const keys = without(state.keys, action.id);
      const count = keys.length;
      const active = '';
      return {
        ...state,
        values,
        keys,
        count,
        active,
      };
    }
    case types.SET_ACTIVE_GROUP: {
      return {
        ...state,
        active: action.groupId,
      };
    }
    case types.CLEAR_GROUPS: {
      return defaultStateGroups;
    }
    default:
      return state;
  }
};

export { groupsReducer };
