import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

// Takes 3 arguments:
// value - will be used as a label of the input
// name - will be used as name, when collecting data from form for example
// defaultValue - will be used as default value for input§§§
// message  - to show some error message

const TextInput = ({
  value, type, message, maxLength, ...rest
}) => (
  <FormGroup>
    <Label className="fz-18 fw-500">{value}</Label>
    <Input type={type} invalid={!!message} {...rest} maxLength={maxLength} />
    <FormFeedback>
      <Translate value={message} />
    </FormFeedback>
  </FormGroup>
);

TextInput.propTypes = {
  maxLength: PropTypes.number,
  value: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
};

TextInput.defaultProps = {
  value: '',
  type: 'text',
  message: '',
  maxLength: 500,
};

export default TextInput;
