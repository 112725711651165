import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { FormGroup, Alert } from 'reactstrap';
import 'flatpickr/dist/themes/light.css';

import Button from '../../../../components/Button';

const SaveAreaComponent = ({
  isLoading, formData, isConfirm, onToggle, onRemoveProfile,
}) => (
  <div>
    <FormGroup>
      <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
        {!isConfirm && (
          <Button
            isLoading={isLoading}
            className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
            color="primary"
            size="lg"
          >
            {formData.isCreateMode ? I18n.t('create') : I18n.t('save_and_exit')}
          </Button>
        )}

        {!isConfirm &&
          !formData.isSelfEditMode &&
          !formData.isCreateMode && (
            <Button
              className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
              color="danger"
              size="lg"
              onClick={onToggle(true)}
            >
              {I18n.t('delete_profile')}
            </Button>
          )}

        {isConfirm && (
          <div className="d-inline-block">
            <Alert color="danger">{I18n.t('remove_user_confirm_message')}</Alert>
            <Button
              className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2 mr-2"
              color="primary"
              size="lg"
              isLoading={isLoading}
              onClick={onRemoveProfile}
            >
              {I18n.t('yes')}
            </Button>
            <Button
              className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
              color="danger"
              size="lg"
              onClick={onToggle(false)}
            >
              {I18n.t('no')}
            </Button>
          </div>
        )}
      </div>
    </FormGroup>
  </div>
);

SaveAreaComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onRemoveProfile: PropTypes.func.isRequired,
};

export default SaveAreaComponent;
