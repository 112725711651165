import types from './types';
import { normalize } from '../../utils/data';

const fetchStatistics = statistics => ({
  type: types.FETCH_STATISTICS,
  statistics: normalize('_id', statistics),
});

const clearStatistics = () => ({
  type: types.CLEAR_STATISTICS,
});

export { fetchStatistics, clearStatistics };
