import _ from 'lodash';
import { withHandlers, withState, withProps } from 'recompose';

const ERROR_MESSAGE = 'something_wrong_message';

/**
 * Provide for state
 * @example
       withFormState({
          email: {},
          password: {},
          ... // <- erc
        }),
 * */

const withFormState = fields => component =>
  withState('formState', 'setFormState', {
    fields,
    errors: fields || {},
    error: null,
    payload: {},
  })(component);

/**
 * Provide set state for field
 * @example
         withClearFormErrors,
   ....
        props.clearFormErrors()
 * */

const withSetFieldState = component =>
  withProps(props => ({
    setFieldState: (name, valid = false, message = '') => {
      props.setFormState({
        ...props.formState,
        errors: { ...props.formState.errors, [name]: { message, valid } },
      });
    },
  }))(component);

const withClearFormErrors = component =>
  withHandlers({
    clearFormErrors: props => () => {
      const errors = {};
      _.keys(props.formState.errors).forEach(key => _.set(errors, key, {}));
      props.setFormState({
        ...props.formState,
        errors,
        error: null,
      });
    },
  })(component);

/**
 * Provide handle error after submit in form
 * @example
         withFormErrorHandler({
            405: { // <- if response with code 405 show message for field below
              field: 'email',
              message: 'wrong_email_address',
            },
          }),

        ........
        props
         .action()
         .catch(props.catchFormError)
 * */

const withFormErrorHandler = errorList =>
  function decorator(component) {
    return withHandlers({
      catchFormError: props => (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line
          console.log(error);
        }
        if (props.setFormState) {
          const message = _.get(error, 'response.data.message');
          if (errorList[message]) {
            const { errors } = props.formState;
            props.setFormState({
              ...props.formState,
              errors: {
                ...errors,
                [errorList[message].field]: {
                  message: errorList[message].message,
                  valid: false,
                },
              },
            });
          } else {
            props.setFormState({
              ...props.formState,
              error: message || ERROR_MESSAGE,
            });
          }
        }
      },
    })(component);
  };

const withFormReducer = component =>
  withHandlers({
    reduceForm: () => (target) => {
      const reducedData = Object.values(target).reduce((acc, curr) => {
        if (!curr.name) {
          return { ...acc };
        }
        return {
          ...acc,
          [curr.name]: curr.value,
        };
      }, {});
      return reducedData;
    },
  })(component);

export {
  withFormState,
  withFormErrorHandler,
  withClearFormErrors,
  withFormReducer,
  withSetFieldState,
};
