const getActivities = state => ({
  activities: state.activities,
});

const getActivity = state => ({
  activity: state.activity,
});

export default {
  getActivities,
  getActivity,
};
