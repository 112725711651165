import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authTypes, authOperations } from '../../../modules/authorize';
import {
  withFormState,
  withFormErrorHandler,
  withClearFormErrors,
} from '../../../utils/recompose-extensions/form';

import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const mapStateToProps = loaderSelectors.getLoader(authTypes.SIGN_IN);

const enhancer = compose(
  connect(
    mapStateToProps,
    {
      logIn: authOperations.logIn,
    }
  ),
  withRouter,
  withFormState({
    password: {},
  }),
  withClearFormErrors,
  withFormErrorHandler({
    // <- if response with message show message for field below
    'Incorrect auth.': {
      field: 'password',
      message: 'wrong_email_or_password',
    },
  }),
  withHandlers({
    onSubmit: props => async (event) => {
      event.preventDefault();
      const {
        target: {
          email: { value: email },
          password: { value: password },
        },
      } = event;
      props.clearFormErrors();

      props
        .logIn(email, password)
        .then(() => props.history.push('/'))
        .catch(props.catchFormError);
    },
  })
);

export default enhancer(Component);
