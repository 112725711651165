import React from 'react';
import { Container } from 'reactstrap';
import Header from './Header';
import List from './List';

import './style.scss';

const Participants = () => (
  <div>
    <Container>
      <Header />
      <List />
    </Container>
  </div>
);

export default Participants;
