import { compose, withHandlers, withState } from 'recompose';

import Component from './Component';

const enhancer = compose(
  withState('isCreateMessageOpen', 'setIsCreateMessageOpen', false),
  withHandlers({
    onCreateMessageClick: ({ isCreateMessageOpen, setIsCreateMessageOpen }) => () =>
      setIsCreateMessageOpen(!isCreateMessageOpen),
  })
);

export default enhancer(Component);
