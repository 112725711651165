import * as types from './types';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SWITCH_LOADER:
      return { ...state, [action.name]: action.value };
    default:
      return state;
  }
};
