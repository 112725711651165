import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Row, Col } from 'reactstrap';
import Toolbar from './Toolbar';

const Header = ({ activitiesCount, usersCount }) => (
  <Fragment>
    <Row>
      <Col>
        <h1 className="mb-3">{I18n.t('statistics')}</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="fz-18 fw-500 mb-2">{I18n.t('in_total_today')}</div>
      </Col>
    </Row>
    <Row className="mb-5 align-items-end">
      <Col className="d-flex">
        <div className="d-flex align-items-center pr-2 mr-4">
          <div className="color-label big grey-bg mr-3 default-box-shadow" />
          <div className="fz-14 fw-500">
            {I18n.t('all_activities')}: {activitiesCount.activities || 0}
          </div>
        </div>
        <div className="d-flex align-items-center pr-2 mr-4">
          <div className="color-label big grey-bg mr-3 default-box-shadow" />
          <div className="fz-14 fw-500">
            {I18n.t('all_groups')}: {activitiesCount.groups || 0}
          </div>
        </div>
        <div className="d-flex align-items-center pr-2 mr-4">
          <div className="color-label big grey-bg mr-3 default-box-shadow" />
          <div className="fz-14 fw-500">
            {I18n.t('less_18_users_message')}: {usersCount.gte13 || 0}
          </div>
        </div>
        <div className="d-flex align-items-center pr-2 mr-4">
          <div className="color-label big grey-bg mr-3 default-box-shadow" />
          <div className="fz-14 fw-500">
            {I18n.t('over_18_users_message')}: {usersCount.gte18 || 0}
          </div>
        </div>
      </Col>
    </Row>
    <Row className="mb-3 pb-2 align-items-end">
      <Col>
        <Toolbar />
      </Col>
    </Row>
  </Fragment>
);

Header.propTypes = {
  activitiesCount: PropTypes.object.isRequired,
  usersCount: PropTypes.object.isRequired,
};

export default Header;
