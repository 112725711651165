import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Label, Button, FormGroup } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Danish } from 'flatpickr/dist/l10n/da.js';

import { UserAutosuggest, ActivityAutosuggest, GroupSelector } from '../../../../components';
import { prepareDate } from '../../../../utils';

const Toolbar = ({
  filter,
  onDateChange,
  onUserSelected,
  onResetDateFilter,
  onAddActivity,
  onGroupSelect,
}) => (
  <Fragment>
    <div className="d-flex info-btns align-items-end flex-wrap flex-xl-nowrap">
      <div>
        <Label className="fz-18 fw-500">{I18n.t('select_volunteer')}</Label>
        <UserAutosuggest onSelected={onUserSelected} onEmpty={onUserSelected} />
      </div>
      <div>
        <Label className="fz-18 fw-500">{I18n.t('period')}</Label>
        <Button color="link" onClick={onResetDateFilter}>
          <FontAwesomeIcon className="fz-18" icon={faTimes} />
        </Button>
        <Flatpickr
          className="default-box-shadow position-relative calendar-field"
          options={{
            maxDate: filter.to,
            locale: Danish,
            dateFormat: 'D d/m/Y',
            wrap: true,
          }}
          onChange={onDateChange('from')}
        >
          <input type="text" data-input value={prepareDate(filter.from)} />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
      <div className="mb-0">
        <Flatpickr
          className="default-box-shadow position-relative calendar-field"
          options={{
            minDate: filter.from,
            locale: Danish,
            dateFormat: 'D d/m/Y',
            wrap: true,
          }}
          onChange={onDateChange('to')}
        >
          <input type="text" data-input value={prepareDate(filter.to)} />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
      <FormGroup className="mb-0">
        <Label className="input-label fz-18 fw-500">{I18n.t('activity')}</Label>
        <ActivityAutosuggest onSelected={onAddActivity} onEmpty={onAddActivity} />
      </FormGroup>
      <GroupSelector activityId={filter.activityId} onSelect={onGroupSelect} />
    </div>
  </Fragment>
);

Toolbar.propTypes = {
  filter: PropTypes.object.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onUserSelected: PropTypes.func.isRequired,
  onResetDateFilter: PropTypes.func.isRequired,
  onAddActivity: PropTypes.func.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
};

export default Toolbar;
