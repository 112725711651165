import { lifecycle, compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { activityOperations, activitySelectors, activityTypes } from '../../../../modules/activity';
import { actionsSelectors } from '../../../../modules/actions';
import { statisticsOperations } from '../../../../modules/statistics';
import { lessonsSelectors } from '../../../../modules/lessons';
import { statisticsAsXYToDataChart, statisticsAsXYToOptions } from './chart-js-config';
import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...activitySelectors.getActivity(state),
      ...actionsSelectors.getFilter(activityTypes.ACTIVITY_STATISTICS)(state),
      ...lessonsSelectors.getStatisticsAsXY(state),
    }),
    {
      fetchActivityStatistic: activityOperations.fetchActivityStatistic,
      clearStatistics: statisticsOperations.clearStatistics,
    }
  ),
  withProps({
    statisticsAsXYToDataChart,
    statisticsAsXYToOptions,
  }),
  lifecycle({
    async componentWillUpdate({ activity, filter }) {
      const {
        filter: oldFilter,
        activity: oldActivity,
        clearStatistics,
        fetchActivityStatistic,
      } = this.props;

      if (!_.isEqual(filter, oldFilter) || !_.isEqual(activity, oldActivity)) {
        clearStatistics();
        await fetchActivityStatistic({ ...filter, activityId: activity._id });
      }
    },
  })
);

export default enhancer(Component);
