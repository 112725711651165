import _ from 'lodash';
import React, { Fragment } from 'react';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';

import { TextInput } from '../../../components';

const Admin = ({ user, onFieldChange, formState }) => (
  <Fragment>
    <TextInput
      value={`${I18n.t('mobile_number')}*`}
      name="phone"
      defaultValue={_.get(user, ['profile', 'phone']) || ''}
      required
      onChange={onFieldChange('phone')}
      message={_.get(formState, 'errors.phone.message') || ''}
    />
  </Fragment>
);

Admin.propTypes = {
  user: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default Admin;
