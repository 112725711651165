import { uniq } from 'lodash';
import types from './types';

const defaultStateNotes = {
  keys: [],
  values: {},
};

const statisticsReducer = (state = defaultStateNotes, action) => {
  switch (action.type) {
    case types.FETCH_STATISTICS: {
      const keys = uniq([...state.keys, ...action.statistics.keys]);
      return {
        keys,
        values: { ...state.values, ...action.statistics.values },
      };
    }
    case types.CLEAR_STATISTICS:
      return defaultStateNotes;
    default:
      return state;
  }
};

export { statisticsReducer };
