const SETTINGS = 'SETTINGS';
const CLEAR_SETTINGS = 'CLEAR_SETTINGS';
const FETCH_SETTINGS = 'FETCH_SETTINGS';
const CREATE_SETTING = 'CREATE_SETTING';
const UPDATE_SETTING = 'UPDATE_SETTING';
const ACTIVE_SETTING = 'ACTIVE_SETTING';

const GDRP = 'GDRP';
const OVER_13 = 'over-13';
const UNDER_13 = 'under-13';

export default {
  SETTINGS,
  FETCH_SETTINGS,
  UPDATE_SETTING,
  ACTIVE_SETTING,
  CLEAR_SETTINGS,
  CREATE_SETTING,
  GDRP,
  OVER_13,
  UNDER_13,
};
