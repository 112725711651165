import types from './types';

const search = (inModel, value) => ({
  type: types.SEARCH,
  inModel,
  value,
});

const filter = (inModel, name, value) => ({
  type: types.FILTER,
  inModel,
  name,
  value,
});
const clearFilter = inModel => ({
  type: types.CLEAR_FILTER,
  inModel,
});

export { search, filter, clearFilter };
