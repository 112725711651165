import _ from 'lodash';
import { compose, withHandlers, withState, withProps } from 'recompose';
import { connect } from 'react-redux';
import { usersOperations } from '../../modules/users';
import { withGetUserProp } from '../../utils/recompose-extensions/props-selectors';
import { handleError } from '../../utils/error-handler';

import Component from './Component';

const throttleFetch = _.throttle(async (props, value) => {
  const {
    PER_PAGE, setUsers, setIsLoading, fetchUsers, type,
  } = props;
  try {
    setIsLoading(true);
    const users = await fetchUsers(value, 0, PER_PAGE, type);
    setIsLoading(false);
    setUsers(users);
  } catch (err) {
    handleError(err);
    setIsLoading(false);
    setUsers([]);
  }
}, 500);

const enhancer = compose(
  connect(() => ({}), {
    fetchUsers: usersOperations.fetchUsersForAutosuggest,
  }),
  withGetUserProp,
  withState('isLoading', 'setIsLoading', false),
  withState('users', 'setUsers', []),
  withState('search', 'setSearch', ''),
  withProps({ PER_PAGE: 5 }),
  withHandlers({
    onSearchChange: props => (e, { newValue }) => {
      if (!newValue) {
        props.onEmpty && props.onEmpty();
      }
      props.setSearch(newValue);
    },
    onSuggestionsClearRequested: props => () => props.setUsers([]),
    onSuggestionsFetchRequested: props => ({ value }) => throttleFetch(props, value),
    getSuggestionValue: props => suggestion => props.getUserProp(suggestion, 'firstName'),
    onSuggestionSelected: props => (value, { suggestion }) => {
      if (props.clearAfterSelect) {
        props.setSearch('');
      }
      props.onSelected(suggestion);
    },
  })
);

export default enhancer(Component);
