import _ from 'lodash';
import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { actionsOperations } from '../../../../modules/actions/index';
import { activityOperations } from '../../../../modules/activity';
import { notesTypes } from '../../../../modules/notes/index';
import Component from './Component';

const enhancer = compose(
  connect(
    (state, { match }) => ({
      activity: state.activities.values[_.get(match, 'params.activityId')] || {},
    }),
    {
      filter: actionsOperations.filter(notesTypes.NOTES),
      clearFilter: actionsOperations.clearFilter(notesTypes.NOTES),
      fetchActivity: activityOperations.fetchActivity,
    }
  ),
  withState('startDate', 'setStartDate', null),
  withState('endDate', 'setEndDate', null),
  withHandlers({
    onDateChange: ({ filter, setStartDate, setEndDate }) => name => (data) => {
      if (name === 'from') setStartDate(data[0]);
      if (name === 'to') setEndDate(data[0]);
      filter(name, data[0]);
    },
    onResetFilter: ({ filter, setDate }) => () => {
      filter('from', '');
      filter('to', '');
      setDate('');
    },
  }),
  lifecycle({
    componentWillMount() {
      const { activity, match } = this.props;
      if (!activity._id) {
        this.props.fetchActivity(_.get(match, 'params.activityId'));
      }
    },
    componentWillUnmount() {
      this.props.clearFilter();
    },
  })
);

export default enhancer(Component);
