import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'flatpickr/dist/themes/light.css';
import Main from './main';
import './i18n-config';
import store from './modules/store';
import { unregister } from './registerServiceWorker';

unregister();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </Provider>,
  // eslint-disable-next-line
  document.getElementById('root')
);
