import { compose, withHandlers, withState, lifecycle } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  withClearFormErrors,
  withFormErrorHandler,
  withFormReducer,
  withFormState,
} from '../../../utils/recompose-extensions/form';

import { activityOperations } from '../../../modules/activity';
import { groupsOperations } from '../../../modules/groups';
import { messagesTypes, messagesOperations } from '../../../modules/messages';
import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const mapStateToProps = state => ({
  allActivities: state.activities,
  groups: state.groups,
  ...loaderSelectors.getLoader(messagesTypes.CREATE_MESSAGE)(state),
});

const mapDispatchToProps = {
  fetchActivities: activityOperations.fetchActivities,
  fetchGroups: groupsOperations.fetchGroups,
  createMessage: messagesOperations.createMessage,
};

const areRecipients = (users, activities, groups) => !!_.concat(users, activities, groups).length;

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('activities', 'setActivities', []),
  withState('activitiesToSend', 'setActivitiesToSend', []),
  withState('groupsToSend', 'setGroupsToSend', []),
  withState('usersToSend', 'setUsersToSend', []),
  withState('selectedActivity', 'setSelectedActivity', null),
  withState('selectedGroup', 'setSelectedGroup', null),
  withFormState({}),
  withFormErrorHandler({}),
  withClearFormErrors,
  withFormReducer,
  withHandlers({
    onSubmit: props => (e) => {
      e.preventDefault();
      props.clearFormErrors();
      if (!areRecipients(props.usersToSend, props.activitiesToSend, props.groupsToSend)) {
        return props.setFormState({ error: 'no_recipients_error' });
      }

      const reducedData = props.reduceForm(e.target);
      const request = {
        title: reducedData.title,
        description: reducedData.description,
        activityIds: props.activitiesToSend,
        groupIds: props.groupsToSend.map(group => group.id),
        userIds: props.usersToSend.map(user => user._id),
      };

      return props
        .createMessage(request)
        .then(props.toggle)
        .catch(props.catchFormError);
    },
    onUserSelected: ({ usersToSend, setUsersToSend }) => suggestion =>
      setUsersToSend([...usersToSend, suggestion]),
    onUserRemoved: ({ usersToSend, setUsersToSend }) => index =>
      setUsersToSend([...usersToSend.slice(0, index), ...usersToSend.slice(index + 1)]),
    onGroupRemoved: ({ groupsToSend, setGroupsToSend }) => id =>
      setGroupsToSend(_.filter(groupsToSend, group => group.id !== id)),
    onActivityRemoved: ({ activitiesToSend, setActivitiesToSend }) => id =>
      setActivitiesToSend(_.without(activitiesToSend, id)),
    onSelectActivity: ({
      fetchGroups,
      setSelectedActivity,
      setActivitiesToSend,
      activitiesToSend,
    }) => (activity) => {
      const activityId = activity._id;
      if (activityId) {
        fetchGroups(activityId);
        setSelectedActivity(activityId);
        setActivitiesToSend(_.uniq([...activitiesToSend, activityId]));
      }
    },
    onSelectGroup: ({ setSelectedGroup }) => e => setSelectedGroup(e._id),
    onAddRecipient: props => () => {
      if (!props.selectedActivity) {
        return;
      }

      if (props.selectedGroup) {
        const group = props.groups.values[props.selectedGroup];
        const activity = props.allActivities.values[group.activityId];
        const groupData = {
          id: group._id,
          activityId: group.activityId,
          activityTitle: activity.title,
          groupTitle: group.title,
        };
        props.setGroupsToSend([...props.groupsToSend, groupData]);
      } else {
        props.setActivitiesToSend([...props.activitiesToSend, props.selectedActivity]);
      }

      props.setSelectedActivity(null);
      props.setSelectedGroup(null);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchActivities();
    },
  })
);

export default enhancer(Component);
