import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actionsOperations, actionsSelectors } from '../../../../modules/actions';
import { usersTypes } from '../../../../modules/users';
import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...actionsSelectors.getFilter(usersTypes.USER_STATISTICS)(state),
    }),
    { filterBy: actionsOperations.filter(usersTypes.USER_STATISTICS) }
  ),
  withState('date', 'setDate', ''),
  withHandlers({
    onDateChange: ({ filterBy }) => name => data => filterBy(name, data[0]),
    onResetDateFilter: ({ filterBy, setDate }) => () => {
      filterBy('from', undefined);
      filterBy('to', undefined);
      setDate('');
    },
    onAddActivity: ({ filterBy }) => activity =>
      filterBy('activityId', _.get(activity, '_id') || ''),
    onGroupSelect: ({ filterBy }) => group => filterBy('groupId', _.get(group, '_id') || ''),
  })
);

export default enhancer(Component);
