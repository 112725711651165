import { get, query, patch, remove, post, APIAddresses } from '../../utils/api';
import * as actions from './actions';
import types from './types';

const fetchNotes = (skip, limit, filter) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.NOTES}${query({
        skip,
        limit,
        filter,
      })}`,
      dispatch,
      types.FETCH_NOTES
    );
    const { notes, count } = data;
    dispatch(actions.fetchNotes(notes, count));
  } catch (error) {
    throw error;
  }
};

const fetchNote = _id => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.NOTES}${_id}`, dispatch, types.FETCH_NOTE);

    dispatch(actions.fetchNote(data));
  } catch (error) {
    throw error;
  }
};

const updateNote = (id, formData) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.NOTES}${id}`,
      formData,
      dispatch,
      types.UPDATE_NOTE
    );
    dispatch(actions.fetchNotes(data));
  } catch (error) {
    throw error;
  }
};

const removeNote = id => async (dispatch) => {
  try {
    const { success } = await remove(`${APIAddresses.NOTES}${id}`, dispatch, types.REMOVE_NOTE);
    dispatch(actions.removeNote(id));
    return success;
  } catch (error) {
    throw error;
  }
};

const clearNotes = () => dispatch => dispatch(actions.clearNotes());
const addNote = note => dispatch => dispatch(actions.addNote(note));

const createNote = formData => async (dispatch) => {
  try {
    const { data } = await post(APIAddresses.NOTES, formData, dispatch, types.CREATE_NOTE);
    dispatch(actions.createNote(data.note));
    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchNotes,
  fetchNote,
  addNote,
  clearNotes,
  updateNote,
  removeNote,
  createNote,
};
