import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Select from '../Select';

const GroupSelect = ({ groups, onSelectGroup }) => (
  <Select
    label={I18n.t('team')}
    data={[{ _id: 'all', title: I18n.t('all') }, ...groups]}
    name="group"
    field="title"
    value="_id"
    onChange={onSelectGroup}
  />
);

GroupSelect.propTypes = {
  groups: PropTypes.array.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
};

export default GroupSelect;
