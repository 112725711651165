import { uniq } from 'lodash';
import types from './types';

const defaultStateLessons = {
  keys: [],
  values: {},
  count: 0,
};

const lessonsReducer = (state = defaultStateLessons, action) => {
  switch (action.type) {
    case types.FETCH_LESSONS: {
      const keys = uniq([...state.keys, ...action.lessons.keys]);
      return {
        keys,
        count: action.count,
        values: { ...state.values, ...action.lessons.values },
      };
    }
    case types.CLEAR_LESSONS: {
      return defaultStateLessons;
    }
    default:
      return state;
  }
};

export { lessonsReducer };
