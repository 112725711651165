import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { settingsOperations } from '../../modules/settings';
import Component from './Component';

const enhancer = compose(
  connect(() => ({}), {
    activeSetting: settingsOperations.activeSetting,
    fetchSettings: settingsOperations.fetchSettings,
    clearSettings: settingsOperations.clearSettings,
  }),
  withHandlers({
    onClickItem: props => (name, intended) => (e) => {
      e.preventDefault();
      props.activeSetting(name, intended);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchSettings();
    },
    componentWillUnmount() {
      this.props.clearSettings();
    },
  })
);

export default enhancer(Component);
