import _ from 'lodash';
import { withProps } from 'recompose';

/**
 * Provide Selector to get data from users
 * @params
 *        users - {Object}
 *        propName - {String} - 'firstName' || 'profile.firstName'
 *        orValue - {Any} - 'value to rerun if prop not found
 * @example
          withGetUserProp,
          ....
          props.getUserProp(users, 'firstName')
 * */

const withGetUserProp = component =>
  withProps({
    getUserProp: (user, propName, orValue = '') =>
      _.get(user, propName) || _.get(user, ['profile', propName]) || orValue,
  })(component);

export { withGetUserProp };
