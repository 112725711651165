import Container from './Container';

/**
 * Provide auto suggest for activity
 * @params
 *        onSelected - {func} - when select dome item
 *                     (activityId) => ... //
 *        onEmpty - {func} - when search value is empty
 *
 *
 * @example
 *        <ActivityAutosuggest onSelected={onAddActivity} />
 *
 * * */
export default Container;
