import React, { Fragment } from 'react';
import Header from '../containers/Header';

// eslint-disable-next-line
const MainLayout = Component => ({ history }) => (
  <Fragment>
    <Header />
    <Component history={history} />
  </Fragment>
);

export default MainLayout;
