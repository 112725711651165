import types from './types';
import { normalize } from '../../utils/data';

const fetchLessons = (lessons, count) => ({
  count,
  type: types.FETCH_LESSONS,
  lessons: normalize('_id', lessons),
});

const clearLessons = () => ({
  type: types.CLEAR_LESSONS,
});

export { fetchLessons, clearLessons };
