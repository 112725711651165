import { compose, withHandlers, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { actionsOperations } from '../../../modules/actions';
import { activityTypes } from '../../../modules/activity';

import Component from './Component';
import { fetchOnDelay } from './const';

const enhancer = compose(
  connect(
    null,
    {
      search: actionsOperations.search(activityTypes.ACTIVITIES),
    }
  ),
  withState('isCreateActivityOpen', 'setIsCreateActivityOpen', false),
  withState('timerId', 'setTimerId', null),
  withHandlers({
    onSearch: ({ search, timerId, setTimerId }) => ({ target: { value } }) => {
      clearTimeout(timerId);
      setTimerId(setTimeout(() => {
        search(value);
      }, fetchOnDelay));
    },
    onCreateActivityToggle: ({ isCreateActivityOpen, setIsCreateActivityOpen }) => () =>
      setIsCreateActivityOpen(!isCreateActivityOpen),
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.search('');
    },
  })
);

export default enhancer(Component);
