import { compose, withHandlers, withState } from 'recompose';
import Component from './Component';

const enhancer = compose(
  withState('isMembersOpen', 'setIsMembersOpen', false),
  withHandlers({
    onMembersToggle: ({ isMembersOpen, setIsMembersOpen }) => () => {
      setIsMembersOpen(!isMembersOpen);
    },
  })
);

export default enhancer(Component);
