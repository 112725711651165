import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Spinner } from './';

const ButtonComponent = ({ isLoading, childContainerStyle, ...props }) => (
  <Button {...props} disabled={isLoading || props.disabled}>
    <span className="spinner-wrapper" style={childContainerStyle}>
      {!!isLoading && <Spinner />}
      {props.value || props.children}
    </span>
  </Button>
);

ButtonComponent.propTypes = {
  childContainerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

ButtonComponent.defaultProps = {
  childContainerStyle: {},
  value: '',
  children: null,
  isLoading: false,
  disabled: false,
};

export default ButtonComponent;
