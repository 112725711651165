import React from 'react';
import Activities from '../../containers/Activities';
import './style.scss';

const ActivitiesPage = () => (
  <div>
    <Activities />
  </div>
);

export default ActivitiesPage;
