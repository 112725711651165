import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faMale, faFemale } from '@fortawesome/free-solid-svg-icons';
import EditProfile from '../EditProfile/index';
import Image from '../../components/Image';
import Groups from './Groups';
import UploadFiles from './UploadFiles';
import Statistics from './Statistics';
import { authTypes } from '../../modules/authorize';
import './style.scss';

const { ROLES_TRANSLATE } = authTypes;

const Profile = ({
  user, getUserProp, isEditProfileOpen, onEditProfileToggle,
}) => (
  <div>
    <Container>
      <Row className="flex-lg-nowrap">
        <Col lg={12} md={12}>
          <Row>
            <Col className="text-center" lg={6} md={12}>
              <Image imageId={getUserProp(user, 'imageId')} />
            </Col>
            <Col className="d-flex flex-column justify-content-between mt-4 mt-lg-0" lg={6} md={12}>
              <Row>
                <Col className="user-name-wr d-flex align-items-start mb-4" lg={12}>
                  {getUserProp(user, ['profile', 'gender']) === 'female' && (
                    <FontAwesomeIcon className="mr-3" icon={faFemale} />
                  )}
                  {getUserProp(user, ['profile', 'gender']) === 'male' && (
                    <FontAwesomeIcon className="mr-3" icon={faMale} />
                  )}
                  <div className="user-name fw-900">
                    {getUserProp(user, 'firstName')} {getUserProp(user, 'lastName')}
                  </div>
                </Col>
                <Col className="profile-info-wr" lg={6}>
                  <h4 className="mb-3">{I18n.t('information')}</h4>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('address')}</h6>
                    <div className="fw-300 fz-14">{getUserProp(user, 'address', ' - ')}</div>
                  </div>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('email')}</h6>
                    <div className="fw-300 fz-14">{getUserProp(user, 'email', ' - ')}</div>
                  </div>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('phone_number')}</h6>
                    <div className="fw-300 fz-14">{getUserProp(user, 'phone', ' - ')}</div>
                  </div>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('facebook')}</h6>
                    <div className="fw-300 fz-14">
                      {getUserProp(user, 'facebookUrl') ? (
                        <a href={getUserProp(user, 'facebookUrl')} target="_blank" rel="noreferrer">
                          {getUserProp(user, 'facebookUrl', ' - ')}
                        </a>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('user_groups')}</h6>
                    <div className="fw-300 fz-14">
                      {I18n.t(ROLES_TRANSLATE[getUserProp(user, ['roles', '0'])] || '')}
                    </div>
                  </div>
                  <div className="mb-3">
                    <h6 className="mb-0 fz-18">{I18n.t('status')}</h6>
                    <div className="fw-300 fz-14">
                      {getUserProp(user, 'approved') ? I18n.t('approved') : I18n.t('not_approved')}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex">
                  <Button
                    className="d-flex align-items-center justify-content-between text-white
                      rounded-0 fz-18 fw-900 default-box-shadow mr-1"
                    size="lg"
                    color="primary"
                    onClick={onEditProfileToggle}
                  >
                    <span>{I18n.t('edit_profile')}</span>
                    <FontAwesomeIcon className="ml-2" icon={faPencilAlt} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4" lg={6}>
              <UploadFiles />
            </Col>
          </Row>
          <Row>
            {getUserProp(user, 'roles', []).includes('user') && (
              <Col className="mt-4" lg={9}>
                <Statistics user={user} />
              </Col>
            )}
          </Row>
        </Col>
        <Col
          className="d-flex flex-column justify-content-between mt-5 pt-3 offset-left-groups"
          lg={3}
          md={12}
        >
          {getUserProp(user, 'groups').length ? <Groups user={user} /> : ''}
        </Col>
      </Row>
    </Container>
    {isEditProfileOpen && (
      <EditProfile isOpen={isEditProfileOpen} toggle={onEditProfileToggle} user={user} />
    )}
  </div>
);

Profile.propTypes = {
  user: PropTypes.object,
  onEditProfileToggle: PropTypes.func.isRequired,
  isEditProfileOpen: PropTypes.bool.isRequired,
  getUserProp: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  user: {},
};

export default Profile;
