const MESSAGES = 'MESSAGES';
const FETCH_MESSAGES = 'MESSAGES';
const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
const CREATE_MESSAGE = 'CREATE_MESSAGE';
const ADD_MESSAGE = 'ADD_MESSAGE';

export default {
  MESSAGES,
  FETCH_MESSAGES,
  CLEAR_MESSAGES,
  CREATE_MESSAGE,
  ADD_MESSAGE,
};
