import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Autosuggest from 'react-autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/index';
import Spinner from '../../components/Spinner';
import './style.scss';

const theme = {
  container: 'autosuggest',
  input: 'form-control',
  suggestionsList: 'list-group',
  suggestion: 'justify-content-between list-group-item',
};

const renderSuggestion = () => suggestion => <span>{suggestion.title}</span>;

const renderInputComponent = ({ isLoading }) => inputProps => (
  <div>
    <div className="input-customize">
      {!isLoading && <FontAwesomeIcon icon={faSearch} />}
      {isLoading && <Spinner />}
      <input {...inputProps} />
    </div>
  </div>
);

const renderSuggestionsContainer = ({ containerProps, children }) => (
  <div {...containerProps}>{children}</div>
);

const ActivityAutosuggest = ({
  activities,
  search,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  getSuggestionValue,
  onSuggestionSelected,
  onSearchChange,
  isLoading,
}) => (
  <div>
    <Autosuggest
      theme={theme}
      suggestions={activities}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion()}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={renderInputComponent({ isLoading })}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={{
        placeholder: I18n.t('search'),
        value: search,
        onChange: onSearchChange,
      }}
    />
  </div>
);

renderSuggestionsContainer.propTypes = {
  containerProps: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

ActivityAutosuggest.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activities: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default ActivityAutosuggest;
