import { get, APIAddresses, post, patch, remove } from '../../utils/api';
import * as actions from './actions';
import types from './types';

const fetchGroups = id => async (dispatch) => {
  try {
    const {
      data: { groups },
    } = await get(`${APIAddresses.ACTIVITIES}/${id}/groups`, dispatch, types.FETCH_GROUPS);
    dispatch(actions.fetchGroups(groups, groups.length));
    return groups;
  } catch (error) {
    throw error;
  }
};

const fetchGroup = id => async (dispatch) => {
  try {
    const {
      data: { group },
    } = await get(`${APIAddresses.GROUPS}/${id}`, dispatch, types.FETCH_GROUPS);
    dispatch(actions.createGroup(group));
    return group;
  } catch (error) {
    throw error;
  }
};

const fetchGroupCoaches = groupId => async (dispatch) => {
  try {
    const {
      data: { coaches },
    } = await get(`${APIAddresses.GROUPS}/${groupId}/coaches`, dispatch, types.FETCH_GROUPS);
    return coaches;
  } catch (error) {
    throw error;
  }
};

const createGroup = group => async (dispatch) => {
  try {
    const { data } = await post(APIAddresses.GROUPS, group, dispatch, types.CREATE_GROUP);
    dispatch(actions.createGroup(data.group));
  } catch (error) {
    throw error;
  }
};

const updateGroup = (group, _id) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.GROUPS}/${_id}`,
      group,
      dispatch,
      types.UPDATE_GROUP
    );
    dispatch(actions.updateGroup(data.group));
  } catch (error) {
    throw error;
  }
};

const removeGroup = id => async (dispatch) => {
  try {
    const { success } = await remove(`${APIAddresses.GROUPS}${id}`, dispatch, types.REMOVE_GROUP);
    dispatch(actions.removeGroup(id));
    return success;
  } catch (error) {
    throw error;
  }
};

const fetchGroupMembers = async (id) => {
  try {
    const { data } = await get(`${APIAddresses.GROUPS}/${id}/members`);
    const { members } = data;
    return members;
  } catch (error) {
    throw error;
  }
};

const setActiveGroup = groupId => async dispatch => dispatch(actions.setActiveGroup(groupId));
const clearGroups = () => async dispatch => dispatch(actions.clearGroups());

export default {
  fetchGroups,
  createGroup,
  updateGroup,
  removeGroup,
  setActiveGroup,
  fetchGroupCoaches,
  fetchGroupMembers,
  clearGroups,
  fetchGroup,
};
