import types from './types';
import { normalize } from '../../utils/data';

const fetchUsers = (users, count) => ({
  count,
  type: types.FETCH_USERS,
  users: normalize('_id', users),
});

const fetchUserGroups = groups => ({
  type: types.FETCH_USER_GROUPS,
  groups,
});

const clearUsers = () => ({
  type: types.CLEAR_USERS,
});

const clearUser = () => ({
  type: types.CLEAR_USER,
});

const createUser = user => ({
  user,
  type: types.CREATE_USER,
});

const addUser = user => ({
  user,
  type: types.ADD_USER,
});

const setActiveUser = user => ({
  user,
  type: types.SET_ACTIVE_USER,
});

export { fetchUsers, clearUsers, createUser, addUser, setActiveUser, clearUser, fetchUserGroups };
