import { lifecycle, compose, withProps, withState, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { activityOperations, activitySelectors } from '../../modules/activity';
import { groupsOperations, groupsSelectors } from '../../modules/groups';
import { usersOperations } from '../../modules/users';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...activitySelectors.getActivity(state),
      ...groupsSelectors.getGroups(state),
    }),
    {
      fetchActivity: activityOperations.fetchActiveActivity,
      clearActivity: activityOperations.clearActivity,
      fetchGroups: groupsOperations.fetchGroups,
      clearGroups: groupsOperations.clearGroups,
      clearUsers: usersOperations.clearUsers,
    }
  ),
  withRouter,
  withState('isEditActivityOpen', 'setIsEditActivityOpen', false),
  withState('isCreateGroupOpen', 'setIsCreateGroupOpen', false),
  withProps({
    fetch: ({ match, fetchActivity, fetchGroups }) => {
      fetchActivity(match.params.id);
      fetchGroups(match.params.id);
    },
  }),
  withHandlers({
    onEditActivityToggle: ({ isEditActivityOpen, setIsEditActivityOpen }) => () =>
      setIsEditActivityOpen(!isEditActivityOpen),
    onCreateGroupToggle: ({ isCreateGroupOpen, setIsCreateGroupOpen }) => () =>
      setIsCreateGroupOpen(!isCreateGroupOpen),
    onDateChange: ({ setStatisticsFromDate, setStatisticsToDate }) => name => (date) => {
      const action = name === 'from' ? setStatisticsFromDate : setStatisticsToDate;
      action(date[0]);
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetch(this.props);
    },
    componentDidMount() {
      window.scrollTo(0, 0);
    },
    componentWillUnmount() {
      this.props.clearActivity();
      this.props.clearGroups();
      this.props.clearUsers();
    },
  })
);

export default enhancer(Component);
