import { combineReducers } from 'redux';

import { activityReducer as activity, activitiesReducer as activities } from './activity/reducers';
import { notesReducer as notes } from './notes/reducers';
import { usersReducer as users, userReducer as user } from './users/reducers';
import loader from './loader/reducers';
import authorize from './authorize';
import actions from './actions';
import groups from './groups';
import messages from './messages';
import settings from './settings';
import lessons from './lessons';
import statistics from './statistics';
import privatePolicy from './privacy-policy';

export default combineReducers({
  activities,
  activity,
  loader,
  authorize,
  actions,
  groups,
  users,
  user,
  messages,
  notes,
  lessons,
  settings,
  statistics,
  privatePolicy,
});
