import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Alert, Label } from 'reactstrap';
import { Button, TextInput } from '../../../components/index';
import Pell from './Pell/PellComponent';

const SettingModal = ({
  onToggle,
  onSubmit,
  formState,
  isLoading,
  setting,
  type,
  intended,
  isShowConfirm,
  onToggleConfirm,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={!!type}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0">
        {I18n.t(`settings_${type !== 'GDRP' ? type : `${type}_${intended}`}`)}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          {!['GDRP', 'modal-2'].includes(type) && (
            <TextInput
              value={I18n.t('heading')}
              name="title"
              required
              defaultValue={setting.title || ''}
            />
          )}

          <Label className="fz-18 fw-500">{I18n.t('contents')}</Label>
          <Pell />

          <div>
            {isShowConfirm && (
              <div className="d-flex justify-content-end">
                <div className="d-inline-block">
                  <Alert color="danger">{I18n.t('settings_GDRP_message')}</Alert>
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2 mr-2"
                    color="primary"
                    size="lg"
                  >
                    {I18n.t('yes')}
                  </Button>
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                    color="danger"
                    size="lg"
                    onClick={onToggle}
                  >
                    {I18n.t('no')}
                  </Button>
                </div>
              </div>
            )}
            {!isShowConfirm && (
              <FormGroup className="d-flex justify-content-end">
                <Button
                  className="rounded-0 fw-900 ml-20"
                  color="secondary"
                  isLoading={isLoading}
                  onClick={onToggle}
                >
                  {I18n.t('cancel')}
                </Button>
                <Button
                  className="rounded-0 fw-900 ml-20"
                  type="submit"
                  color="primary"
                  isLoading={isLoading}
                  onClick={onToggleConfirm}
                >
                  {I18n.t('save_changes')}
                </Button>
              </FormGroup>
            )}
            {formState.error && (
              <Alert color="danger">{I18n.t(formState.error) || formState.error}</Alert>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

SettingModal.propTypes = {
  setting: PropTypes.object.isRequired,
  type: PropTypes.string,
  intended: PropTypes.string,
  isShowConfirm: PropTypes.bool.isRequired,
  onToggleConfirm: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SettingModal.defaultProps = {
  type: '',
  intended: '',
};

export default SettingModal;
