import React from 'react';
import './styles.scss';

const Pell = () => (
  <div className="content">
    <div id="pell-component" />
  </div>
);

export default Pell;
