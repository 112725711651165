import _ from 'lodash';
import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { usersTypes, usersSelectors, usersOperations } from '../../../modules/users';
import { actionsSelectors } from '../../../modules/actions';
import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...usersSelectors.getUsers(state),
      ...actionsSelectors.getSearchValue(usersTypes.USERS)(state),
      ...actionsSelectors.getFilter(usersTypes.USERS)(state),
    }),
    {
      fetchUsers: usersOperations.fetchUsers,
      clearUsers: usersOperations.clearUsers,
    }
  ),
  withProps({ PER_PAGE: 20 }),
  withProps(props => ({
    fetch: (search, skip, limit, filter) => {
      if (!props.isLoading) {
        props.fetchUsers(
          search,
          skip,
          limit,
          !['isNotApproval', 'isNotAgreeGDPR'].includes(filter) ? filter : '',
          filter === 'isNotApproval' ? 'false' : '',
          filter === 'isNotAgreeGDPR' ? 'false' : ''
        );
      }
    },
  })),
  withHandlers({
    onFetch: props => () => {
      const {
        fetch, search, users, PER_PAGE, filter,
      } = props;
      fetch(search, users.keys.length, PER_PAGE, filter.userGroups);
    },
  }),
  lifecycle({
    componentWillMount() {
      const {
        fetch, search, PER_PAGE, filter,
      } = this.props;
      fetch(search, 0, PER_PAGE, filter.userGroups);
    },
    componentWillUpdate(newProps) {
      if (newProps.search !== this.props.search || !_.isEqual(newProps.filter, this.props.filter)) {
        const { search, filter } = newProps;
        const { clearUsers, fetch, PER_PAGE } = this.props;
        clearUsers();
        fetch(search, 0, PER_PAGE, filter.userGroups);
      }
    },
    componentWillUnmount() {
      this.props.clearUsers();
    },
  })
);

export default enhancer(Component);
