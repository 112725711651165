import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import { actionsOperations } from '../../../../modules/actions/index';
import Component from './Component';
import { usersTypes } from '../../../../modules/users';

const userGroups = [
  {
    value: 'isNotApproval',
    field: I18n.t('awaiting_approval'),
  },
  {
    value: 'isNotAgreeGDPR',
    field: I18n.t('privacy_policy_waiting_label'),
  },
  {
    value: 'volunteer',
    field: I18n.t('volunteer'),
  },
  {
    value: 'user',
    field: I18n.t('user'),
  },
  {
    value: 'admin',
    field: I18n.t('admin'),
  },
];

const enhancer = compose(
  connect(() => ({}), {
    search: actionsOperations.search(usersTypes.USERS),
    filter: actionsOperations.filter(usersTypes.USERS),
  }),
  withProps({
    userGroups,
  }),
  withHandlers({
    onSearch: props => (e) => {
      props.search(e.target.value);
    },
    onSelect: ({ filter }) => (e) => {
      const { name, value } = e.target;
      filter(name, value);
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.filter('userGroups', 'isNotApproval');
    },
    componentWillUnmount() {
      this.props.search('');
    },
  })
);

export default enhancer(Component);
