import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Flatpickr from 'react-flatpickr';
import { FormGroup, Label } from 'reactstrap';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Danish } from 'flatpickr/dist/l10n/da.js';

import { TextInput } from '../../../components';

const Volunteer = ({
  formData, changeFormData, user, getUserProp, onFieldChange, formState,
}) => (
  <Fragment>
    <TextInput
      value={`${I18n.t('mobile_number')}*`}
      name="phone"
      defaultValue={getUserProp(user, 'phone')}
      required
      onChange={onFieldChange('phone')}
      message={_.get(formState, 'errors.phone.message') || ''}
    />

    <FormGroup>
      <Label className="fz-18 fw-500">{I18n.t('date_of_birth')}*</Label>
      <div className="d-flex row-btns">
        <Flatpickr
          className="position-relative calendar-field w-100 default-box-shadow"
          value={formData.birthDate}
          onChange={date => date.length && changeFormData('birthDate', date)}
          options={{
            locale: Danish,
            dateFormat: 'D d/m/Y',
            defaultDate: 't 2018/10/10',
            maxDate: new Date(),
            wrap: true,
          }}
        >
          <input className="w-100" type="text" data-input />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
    </FormGroup>

    <TextInput
      value={`${I18n.t('education')} / ${I18n.t('employment')}*`}
      name="education"
      defaultValue={getUserProp(user, 'education')}
      required
      onChange={onFieldChange('education')}
      message={_.get(formState, 'errors.education.message') || ''}
    />
    <TextInput
      value={`${I18n.t('i_am_the_volunteer')}*`}
      name="volunteerActivity"
      defaultValue={getUserProp(user, 'volunteerActivity')}
      required
      onChange={onFieldChange('volunteerActivity')}
      message={_.get(formState, 'errors.volunteerActivity.message') || ''}
    />
    <TextInput
      value={I18n.t('following_times_agreed')}
      name="followingAgreed"
      defaultValue={getUserProp(user, 'followingAgreed')}
    />
    <TextInput
      value={I18n.t('contact_person_on_cancellation')}
      name="contactPersonName"
      defaultValue={getUserProp(user, 'contactPersonName')}
    />
    <TextInput
      value={I18n.t('contact_person_phone_number')}
      name="contactPersonPhone"
      defaultValue={getUserProp(user, 'contactPersonPhone')}
    />
  </Fragment>
);

Volunteer.propTypes = {
  formData: PropTypes.object.isRequired,
  changeFormData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getUserProp: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default Volunteer;
