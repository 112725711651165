const routeList = {
  HOME: '/',
  ACTIVITIES: '/activities',
  STATISTICS: '/statistics',
  SETTINGS: '/settings',
  PARTICIPANTS: '/participants',
  MESSAGE: '/messages',
  MY: '/my',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/forgot-password',
  NOTES: '/notes',
  USERS: '/users',
  PPIVACYPOLICY: '/privacy-policy',
};

export { routeList };
