import { get, query, patch, post, APIAddresses } from '../../utils/api';
import * as actions from './actions';
import types from './types';

const fetchSettings = (skip, limit) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.SETTINGS}${query({
        skip,
        limit,
      })}`,
      dispatch,
      types.FETCH_SETTINGS
    );
    const { settings, count } = data;
    dispatch(actions.fetchSettings(settings, count));
  } catch (error) {
    throw error;
  }
};

const fetchSetting = (type, intended, token) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.SETTINGS}${type}${query({ intended })}`,
      dispatch,
      types.FETCH_SETTINGS,
      null,
      token
    );
    const { setting } = data;
    return setting;
  } catch (error) {
    throw error;
  }
};

const updateSetting = (formData, id) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.SETTINGS}${id}`,
      formData,
      dispatch,
      types.UPDATE_SETTING
    );
    dispatch(actions.updateSetting(data.setting));
  } catch (error) {
    throw error;
  }
};

const createSetting = formData => async (dispatch) => {
  try {
    const { data } = await post(APIAddresses.SETTINGS, formData, dispatch, types.CREATE_SETTING);
    dispatch(actions.createSetting(data.setting));
  } catch (error) {
    throw error;
  }
};

const clearSettings = () => dispatch => dispatch(actions.clearSettings());
const activeSetting = (_id, intended) => dispatch => dispatch(actions.activeSetting(_id, intended));

export default {
  fetchSettings,
  createSetting,
  updateSetting,
  activeSetting,
  clearSettings,
  fetchSetting,
};
