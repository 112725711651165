import React from 'react';
import Profiles from '../../containers/Profiles';

const ProfilesPage = () => (
  <div>
    <Profiles />
  </div>
);

export default ProfilesPage;
