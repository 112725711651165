import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withFormErrorHandler, withFormReducer } from '../../../utils/recompose-extensions/form';
import {
  activityOperations,
  activityTypes,
  activitySelectors,
} from '../../../modules/activity/index';
import { jsonToFormData } from '../../../utils/jsonToFormData';
import { loaderSelectors } from '../../../modules/loader/index';
import { routeList } from '../../../pages/routes';

import Component from './Component';

const mapStateToProps = state => ({
  ...activitySelectors.getActivity(state),
  ...loaderSelectors.getLoader(activityTypes.UPDATE_ACTIVITY)(state),
  ...loaderSelectors.getLoader(activityTypes.REMOVE_ACTIVITY)(state),
});

const enhancer = compose(
  connect(
    mapStateToProps,
    {
      updateActivity: activityOperations.updateActivity,
      removeActivity: activityOperations.removeActivity,
    }
  ),
  withRouter,
  withFormErrorHandler({}),
  withState('activityImage', 'setActivityImage', null),
  withState('isRemoveActivity', 'setIsRemoveActivity', false),
  withFormReducer,
  withHandlers({
    onFileSelect: ({ setActivityImage }) => event => setActivityImage(event.target.files[0]),
    onRemoveActivity: ({ isRemoveActivity, setIsRemoveActivity }) => () =>
      setIsRemoveActivity(!isRemoveActivity),
    onConfirmRemoveActivity: ({
      activity, history, catchFormError, removeActivity,
    }) => () => {
      removeActivity(activity._id)
        .then(() => history.push(routeList.ACTIVITIES))
        .catch(catchFormError);
    },
    onSubmit: props => (e) => {
      const { reduceForm, activity, activityImage } = props;
      e.preventDefault();

      const reducedData = reduceForm(e.target);
      const request = {
        title: reducedData.title,
        description: reducedData.description,
        contact: reducedData.contact,
        picture: activityImage,
      };

      const formData = new FormData();
      jsonToFormData(request, {}, formData);
      props
        .updateActivity(activity._id, formData)
        .then(props.toggle)
        .catch(props.catchFormError);
    },
  })
);

export default enhancer(Component);
