import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Row, Button, Col } from 'reactstrap';
import './style.scss';

const ConversionButtons = ({ onDownloadStatistics, onExportPdf }) => (
  <Row className="mt-4 mb-5">
    <Col>
      <Button
        className="rounded-0 fw-900 fz-18 conversion-btn text-left"
        color="primary"
        onClick={onExportPdf}
      >
        {I18n.t('download_pdf')}
      </Button>
      <Button
        className="rounded-0 fw-900 fz-18 ml-3 conversion-btn text-left"
        color="primary"
        onClick={onDownloadStatistics}
      >
        {I18n.t('download_excel')}
      </Button>
    </Col>
  </Row>
);

ConversionButtons.propTypes = {
  onDownloadStatistics: PropTypes.func.isRequired,
  onExportPdf: PropTypes.func.isRequired,
};

export default ConversionButtons;
