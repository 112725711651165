import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { I18n } from 'react-i18nify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faStickyNote, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import Group from './Group';
import { Image } from '../../components';
import AddGroup from './AddGroup';
import EditActivity from './EditActivity';
import { routeList } from '../../pages/routes';
import Statistics from './Statistics';

import './style.scss';

const ActivityPage = ({
  activity,
  isCreateGroupOpen,
  onCreateGroupToggle,
  groups,
  onEditActivityToggle,
  isEditActivityOpen,
}) => (
  <div>
    <Container>
      <Row className="mb-4">
        {activity.imageId && (
          <Col className="activity-image">
            <Image imageId={activity.imageId} className="rounded-0 w-100" />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={9}>
          <div className="mb-5">
            <h2>{activity.title}</h2>
            <p>{activity.description}</p>
            <div>
              <Button
                className="big-btn d-flex align-items-center justify-content-between text-white
                  rounded-0 fz-18 fw-900 default-box-shadow mt-5"
                size="lg"
                color="primary"
                onClick={onEditActivityToggle}
              >
                <span>{I18n.t('edit_activity')}</span>
                <FontAwesomeIcon className="ml-2" icon={faPencilAlt} />
              </Button>
            </div>
          </div>
          <Statistics activity={activity} />
        </Col>
        <Col lg={3}>
          <div className="sidebar-item">
            <Link
              className="d-flex align-items-center justify-content-between text-white
                rounded-0 fz-18 fw-900 default-box-shadow w-100 mb-3 btn btn-primary btn-lg"
              to={`${routeList.NOTES}/${activity._id}`}
            >
              <span>{I18n.t('notes')}</span>
              <FontAwesomeIcon className="ml-2" icon={faStickyNote} />
            </Link>
            <h5 className="fz-18">{I18n.t('contact')}</h5>
            <div className="fz-14 fw-300">{activity.contact}</div>
          </div>
          <div className="sidebar-item">
            <Button
              className="d-flex align-items-center justify-content-between text-white rounded-0
                fz-18 fw-900 default-box-shadow w-100 mb-3"
              size="lg"
              color="primary"
              onClick={onCreateGroupToggle}
            >
              <span>{I18n.t('add_team')}</span>
              <FontAwesomeIcon className="ml-2" icon={faPlusSquare} />
            </Button>
            <h5 className="fz-18">{I18n.t('team')}</h5>
            {_.values(groups.values).map(group => (
              <Group group={group} key={group._id} onToggle={onCreateGroupToggle} />
            ))}
          </div>
        </Col>
      </Row>
      {isCreateGroupOpen && (
        <AddGroup
          toggle={onCreateGroupToggle}
          isOpen={isCreateGroupOpen}
          activityId={activity._id}
        />
      )}
    </Container>
    {isEditActivityOpen && (
      <EditActivity toggle={onEditActivityToggle} isOpen={isEditActivityOpen} />
    )}
  </div>
);

ActivityPage.propTypes = {
  activity: PropTypes.object.isRequired,
  isCreateGroupOpen: PropTypes.bool.isRequired,
  onCreateGroupToggle: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  onEditActivityToggle: PropTypes.func.isRequired,
  isEditActivityOpen: PropTypes.bool.isRequired,
};

export default ActivityPage;
