import moment from 'moment';
import React from 'react';
import { subtract } from 'lodash';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { prepareDate } from '../../../../utils/helpers';
import { daysOfTheWeek } from './constants';

const ScheduleStatistic = ({ statistic, percent, schedule }) => (
  <div className="fw-300 fz-14">
    <div>
      {I18n.t('start_date')}: {I18n.t(daysOfTheWeek[schedule.day])}{' '}
      {moment(prepareDate(schedule.startAt)).format('HH:mm')}
    </div>
    <div>
      {I18n.t('participation')}: {statistic.visited} af {statistic.count} timer
    </div>
    <div>
      {I18n.t('absence')}
      {': '}
      {subtract(statistic.count, statistic.visited)}
      {' af '}
      {statistic.count} timer ({percent}%)
    </div>
    <br />
  </div>
);

ScheduleStatistic.propTypes = {
  statistic: PropTypes.object.isRequired,
  percent: PropTypes.string.isRequired,
  schedule: PropTypes.array.isRequired,
};

export default ScheduleStatistic;
