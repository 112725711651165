import { compose, withHandlers, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { init, exec } from 'pell';
import {
  withClearFormErrors,
  withFormErrorHandler,
  withFormReducer,
  withFormState,
  withSetFieldState,
} from '../../../utils/recompose-extensions/form';
import { loaderSelectors } from '../../../modules/loader/index';
import { settingsOperations, settingsTypes, settingsSelectors } from '../../../modules/settings';

import Component from './Component';

import './style.scss';

const mapStateToProps = state => ({
  ...loaderSelectors.getLoader(settingsTypes.UPDATE_SETTING)(state),
  ...settingsSelectors.getSettings(state),
  setting: settingsSelectors.getActiveSetting(state),
  type: state.settings.active,
  intended: state.settings.intended,
});

const enhancer = compose(
  connect(mapStateToProps, {
    createSetting: settingsOperations.createSetting,
    updateSetting: settingsOperations.updateSetting,
    activeSetting: settingsOperations.activeSetting,
  }),
  withFormState({}),
  withState('content', 'setContent', null),
  withState('isEditGDPR', 'setIsEditGDPR', false),
  withState('isShowConfirm', 'setIsShowConfirm', false),
  withFormErrorHandler({}),
  withClearFormErrors,
  withSetFieldState,
  withFormReducer,
  withHandlers({
    onDescriptionChange: ({ setting: { _id, type, description }, setIsEditGDPR }) => html =>
      setIsEditGDPR(_id && ['GDRP', 'modal-2'].includes(type) && description !== html),
    onToggleConfirm: ({ setIsShowConfirm, isShowConfirm, isEditGDPR }) => () =>
      setIsShowConfirm(isEditGDPR ? !isShowConfirm : isShowConfirm),
    onToggle: props => () => props.activeSetting(''),
    onSubmit: props => (e) => {
      const {
        reduceForm, type, setting, content, intended,
      } = props;
      e.preventDefault();

      const reducedData = reduceForm(e.target);

      props.clearFormErrors();
      const request = {
        type,
        intended,
        title: reducedData.title,
        description: content,
      };
      const action = !setting._id ? props.createSetting : props.updateSetting;

      return action(request, setting._id)
        .then(() => props.activeSetting(''))
        .catch(props.catchFormError);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (!!this.props.settings.active && !prevProps.settings.active) {
        const { setting, setContent } = this.props;
        this.props.setIsEditGDPR(false);
        this.props.setIsShowConfirm(false);
        const editor = init({
          element: document.getElementById('pell-component'),
          onChange: (html) => {
            setContent(html);
            this.props.onDescriptionChange(html);
          },
          defaultParagraphSeparator: 'div',
          styleWithCSS: false,
          actions: [
            'bold',
            'italic',
            'underline',
            'link',
            'code',
            'heading1',
            'heading2',
            {
              name: 'custom',
              icon: '&#10006',
              title: 'Clear Formatting',
              result: () => exec('formatBlock', 'p') && exec('removeformat') && exec('useCSS'),
            },
          ],
          classes: {
            actionbar: 'pell-actionbar',
            button: 'pell-button',
            content: 'pell-content',
            selected: 'pell-button-selected',
          },
        });
        if (setting.description) {
          editor.content.innerHTML = setting.description;
        }
      }
    },
  })
);

export default enhancer(Component);
