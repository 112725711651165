import { compose, withHandlers, withProps, lifecycle, withState } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import { groupsOperations } from '../../modules/groups';
import { withGetUserProp } from '../../utils/recompose-extensions/props-selectors';

import Component from './Component';

const enhancer = compose(
  connect(null, {
    fetchGroups: groupsOperations.fetchGroups,
    clearGroups: groupsOperations.clearGroups,
  }),
  withGetUserProp,
  withState('groups', 'setGroups', []),
  withProps({ PER_PAGE: 5 }),
  withHandlers({
    onSelectGroup: ({ onSelect, groups }) => e =>
      onSelect(_.find(groups, group => group._id === e.target.value) || {}),
  }),
  lifecycle({
    async componentWillUpdate(newProps) {
      const { activityId, setGroups, fetchGroups } = newProps;
      if (activityId && activityId !== this.props.activityId) {
        setGroups([]);
        const groups = await fetchGroups(activityId);
        setGroups(groups);
      }
      if (!activityId && newProps.groups.length) {
        setGroups([]);
        this.props.onSelect({});
      }
    },
  })
);

export default enhancer(Component);
