import React, { Fragment } from 'react';
import { I18n } from 'react-i18nify';
import { Row, Col } from 'reactstrap';
import Toolbar from './Toolbar';

const Header = () => (
  <Fragment>
    <Row>
      <Col>
        <h1 className="mb-3">{I18n.t('participant_lists')}</h1>
      </Col>
    </Row>
    <Row className="mb-3 pb-2 align-items-end">
      <Col>
        <Toolbar />
      </Col>
    </Row>
  </Fragment>
);

export default Header;
