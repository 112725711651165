import React from 'react';
import { I18n } from 'react-i18nify';
import { Container, Row, Col } from 'reactstrap';
import Header from './Header';
import List from './List';

import './style.scss';

const Notes = () => (
  <div>
    <Container>
      <Row>
        <Col>
          <h1 className="mb-3">{I18n.t('notes')}</h1>
        </Col>
      </Row>
      <Header />
      <List />
    </Container>
  </div>
);

export default Notes;
