import _ from 'lodash';
import axios from 'axios';
import APIAddresses from './urls';
import { io } from './sockets';
import { getTokenHeaderObject } from './authorization';
import { switchLoader } from '../../modules/loader/actions';
import { policyOperations } from '../../modules/privacy-policy';

const makeRequest = async (type, url, data, dispatch, loader, isFetchLast, token) => {
  const loaderName = (_.isString(loader) && loader) || _.get(loader, 'name');
  const loaderValue = !!_.isString(loader) || _.get(loader, 'value');
  try {
    loaderName && dispatch(switchLoader(loaderName, loaderValue));
    const headers = getTokenHeaderObject(token);
    const response = await axios({
      url,
      data,
      method: type,
      headers,
    });
    loaderName &&
      (isFetchLast
        ? isFetchLast() && dispatch(switchLoader(loaderName, false))
        : dispatch(switchLoader(loaderName, false)));
    return response;
  } catch (error) {
    loaderName && dispatch(switchLoader(loaderName, false));
    policyOperations.catchPrivacyPolicyError(error)(dispatch);
    throw error;
  }
};

const getFile = url =>
  axios.get(url, {
    headers: getTokenHeaderObject(),
    responseType: 'arraybuffer',
  });

const query = (data) => {
  const toString = v => (_.isDate(v) && v.toISOString()) || v;
  const isObject = (v) => {
    if (_.isDate(v)) {
      return false;
    }
    return _.isObject(v);
  };
  const toQueryParam = (obj, prevName = '') =>
    _.keys(obj).map(key =>
      (isObject(obj[key]) && toQueryParam(obj[key], `${prevName}${key}.`)) ||
        `${prevName}${key}=${toString(obj[key]) || ''}`);

  return `?${_.flatten(toQueryParam(data)).join('&')}`;
};

// Requests to server

const get = (url, ...args) => makeRequest('get', url, null, ...args);
const post = (url, ...args) => makeRequest('post', url, ...args);
const put = (url, ...args) => makeRequest('put', url, ...args);
const remove = (url, ...args) => makeRequest('delete', url, ...args);
const patch = (url, ...args) => makeRequest('patch', url, ...args);

export { APIAddresses, get, post, put, remove, patch, getFile, query, io };
