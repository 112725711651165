import _ from 'lodash';
import moment from 'moment';
import { I18n } from 'react-i18nify';
import { USER, VOLUNTEER, FEMALE, MALE } from '../../../modules/authorize/types';

const getDateFormat = (groupBy = 'day') => {
  const map = {
    day: 'DD/MM/YYYY',
    month: 'MMMM',
    year: 'YYYY',
  };
  return map[groupBy];
};

const statisticsAsXYToOptions = () => ({
  maintainAspectRatio: false,
  defaultFontSize: '12px',
  title: {
    display: true,
    fontSize: 14,
    fontColor: 'white',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontSize: 12,
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        distribution: 'series',
      },
    ],
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      fontSize: 14,
      boxWidth: 14,
      fontStyle: 'bold',
      padding: 25,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) =>
        `${tooltipItem.yLabel} ${data.datasets[tooltipItem.datasetIndex].tooltipLabel || ''}`,
    },
  },
});

const statisticsAsXYToDataChart = (statisticsAsXY) => {
  const sumStatistic = (text, field) => {
    const last = statisticsAsXY[field][statisticsAsXY[field].length - 1];
    return `${I18n.t(text)} (${_.get(last, 'y') || '0'})`;
  };
  const labels = statisticsAsXY[USER].map(({ x }) =>
    moment(x).format(getDateFormat(statisticsAsXY.groupBy)));

  return {
    labels: labels.map(l => I18n.t(l)),
    datasets: [
      {
        data: statisticsAsXY[USER],
        backgroundColor: '#1cd493',
        label: sumStatistic('user', USER),
        tooltipLabel: I18n.t('user'),
      },
      {
        data: statisticsAsXY[VOLUNTEER],
        backgroundColor: '#f9c979',
        label: sumStatistic('volunteers', VOLUNTEER),
        tooltipLabel: I18n.t('volunteers'),
      },
      {
        data: statisticsAsXY[`${USER}-${VOLUNTEER}`],
        backgroundColor: '#bd10e0',
        label: sumStatistic('user_and_volunteer', `${USER}-${VOLUNTEER}`),
        tooltipLabel: I18n.t('user_and_volunteer'),
      },
      {
        data: statisticsAsXY[`${USER}-${FEMALE}`],
        backgroundColor: '#f5a623',
        label: sumStatistic('users_girls', `${USER}-${FEMALE}`),
        tooltipLabel: I18n.t('users_girls'),
      },
      {
        data: statisticsAsXY[`${USER}-${MALE}`],
        backgroundColor: '#7ed321',
        label: sumStatistic('users_boys', `${USER}-${MALE}`),
        tooltipLabel: I18n.t('users_boys'),
      },
      {
        data: statisticsAsXY[`${VOLUNTEER}-${FEMALE}`],
        backgroundColor: '#8b572a',
        label: sumStatistic('volunteers_girls', `${VOLUNTEER}-${FEMALE}`),
        tooltipLabel: I18n.t('volunteers_girls'),
      },
      {
        data: statisticsAsXY[`${VOLUNTEER}-${MALE}`],
        backgroundColor: '#4f4b4b',
        label: sumStatistic('volunteers_boys', `${VOLUNTEER}-${MALE}`),
        tooltipLabel: I18n.t('volunteers_boys'),
      },
    ],
  };
};

export { statisticsAsXYToDataChart, statisticsAsXYToOptions };
