import types from './types';
import { normalize } from '../../utils/data';

const fetchActivities = (activities, count) => ({
  count,
  type: types.FETCH_ACTIVITIES,
  activities: normalize('_id', activities),
});
const clearActivities = () => ({
  type: types.CLEAR_ACTIVITIES,
});
const clearActivity = () => ({
  type: types.CLEAR_ACTIVITY,
});
const createActivity = activity => ({
  activity,
  type: types.CREATE_ACTIVITY,
});

const setActiveActivity = activity => ({
  activity,
  type: types.SET_ACTIVE_ACTIVITY,
});
const addActivity = activity => ({
  activity,
  type: types.ADD_ACTIVITY,
});
const setIsFetchingActivities = activity => ({
  activity,
  type: types.IS_FETCHING_ACTIVITY,
});

export {
  setIsFetchingActivities,
  fetchActivities,
  clearActivities,
  createActivity,
  setActiveActivity,
  clearActivity,
  addActivity,
};
