import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { I18n } from 'react-i18nify';
import './style.scss';

const MembersList = ({
  isOpen, toggle, group, members,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        <p>{I18n.t('register')}</p>
        <p>{group.title}</p>
      </ModalHeader>
      <ModalBody>
        <Row>
          {members.map(({ profile, _id }) => (
            <Col key={_id} lg={4} className="col-wrapper">
              <div className="user-block">{`${profile.firstName} ${profile.lastName}`}</div>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  </div>
);

MembersList.propTypes = {
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
};

export default MembersList;
