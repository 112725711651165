const getAuthToken = () => window.localStorage.getItem('token');
const setAuthToken = token => window.localStorage.setItem('token', token);
const getTokenHeaderValue = () => `Bearer ${getAuthToken()}`;

const getAuthUser = () => {
  const username = window.localStorage.getItem('username');
  const roles = window.localStorage.getItem('roles');
  return username && roles && { username, roles: roles.split(',').map(String) };
};
const setAuthUser = (email, roles) => {
  window.localStorage.setItem('email', email);
  window.localStorage.setItem('roles', roles && roles.join(','));
};

const unSetAuthUser = () => {
  setAuthUser('', []);
  setAuthToken('');
};

// eslint-disable-next-line
const getTokenHeaderObject = token => ({
  Authorization: token ? `Bearer ${token}` : getTokenHeaderValue(),
});

export {
  setAuthToken,
  getAuthToken,
  getAuthUser,
  setAuthUser,
  unSetAuthUser,
  getTokenHeaderValue,
  getTokenHeaderObject,
};
