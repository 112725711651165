const getSearchValue = inModel => state => ({
  search: state.actions.search[inModel] || '',
});

const getFilter = inModel => state => ({
  filter: state.actions.filter[inModel] || {},
});

export default {
  getSearchValue,
  getFilter,
};
