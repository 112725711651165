import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { authTypes, authOperations } from '../../../modules/authorize';
import { withFormState, withFormErrorHandler } from '../../../utils/recompose-extensions/form';

import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const mapStateToProps = loaderSelectors.getLoader(authTypes.CHANGE_PASSWORD);

const enhancer = compose(
  connect(mapStateToProps, {
    changePassword: authOperations.changePassword,
  }),
  withFormState({
    password: {
      valid: true,
    },
    newPassword: {
      valid: true,
    },
  }),
  withFormErrorHandler({
    // // <- if response with code 405 show message for field below
    'Password is in wrong format.': {
      field: 'newPassword',
      message: 'password_wrong_format',
    },
    'Password or username is incorrect': {
      field: 'password',
      message: 'password_incorrect',
    },
    'No password was given': {
      field: 'password',
      message: 'no_password_was_given',
    },
  }),
  withState('isSuccessSubmit', 'setIsSuccessSubmit', false),
  withState('isPasswordsDoNotMatch', 'setIsPasswordsDoNotMatch', false),
  withHandlers({
    onSubmit: props => async (e) => {
      e.preventDefault();
      const password = e.target.password.value;
      const newPassword = e.target.newPassword.value;
      const newPasswordAgain = e.target.newPasswordAgain.value;

      props.setIsSuccessSubmit(false);

      if (newPassword !== newPasswordAgain) {
        props.setIsPasswordsDoNotMatch(true);
      } else {
        props
          .changePassword(newPassword, password)
          .then(() => props.setIsSuccessSubmit(true))
          .catch(props.catchFormError);
        e.target.reset();
      }
    },
  })
);

export default enhancer(Component);
