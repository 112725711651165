import _ from 'lodash';
import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { notesTypes, notesOperations, notesSelectors } from '../../../modules/notes';
import { actionsSelectors } from '../../../modules/actions';
import { loaderSelectors } from '../../../modules/loader';
import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...loaderSelectors.getLoader(notesTypes.FETCH_NOTES)(state),
      ...notesSelectors.getNotes(state),
      ...actionsSelectors.getFilter(notesTypes.NOTES)(state),
      filteredNotesKeys: notesSelectors.getFilteredNotesKeys(state),
    }),
    {
      fetchNotes: notesOperations.fetchNotes,
      clearNotes: notesOperations.clearNotes,
    }
  ),
  withRouter,
  withProps({ PER_PAGE: 25 }),
  withProps({
    fetch: (props) => {
      const {
        notes, PER_PAGE, fetchNotes, filter, isLoading,
      } = props;
      const { activityId } = this.props.match.params;

      if (!isLoading) {
        fetchNotes(notes.keys.length, PER_PAGE, _.extend(filter, { activityId }));
      }
    },
  }),
  withHandlers({
    onFetch: props => () => props.fetch(props),
  }),
  lifecycle({
    componentWillMount() {
      const { notes, PER_PAGE, match } = this.props;
      const { activityId } = match.params;
      this.props.fetchNotes(notes.keys.length, PER_PAGE, { activityId });
    },
    componentWillUpdate(newProps) {
      if (!_.isEqual(newProps.filter, this.props.filter)) {
        const { PER_PAGE, isLoading, filter } = newProps;
        const { activityId } = newProps.match.params;
        this.props.clearNotes();
        if (!isLoading) {
          this.props.fetchNotes(0, PER_PAGE, _.extend(filter, { activityId }));
        }
      }
    },
    componentWillUnmount() {
      this.props.clearNotes();
    },
  })
);

export default enhancer(Component);
