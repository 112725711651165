import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '../../../components';
import ListItem from './ListItem';

const LessonList = ({ onFetch, lessons }) => (
  <InfiniteScroll
    pageStart={1}
    loadMore={onFetch}
    hasMore={lessons.count > lessons.keys.length}
    loader={
      <Col lg={12} key="loader" className="d-flex justify-content-center">
        <span className="position-relative pl-4 pr-4">
          {I18n.t('loading')} ...<Spinner />
        </span>
      </Col>
    }
  >
    {lessons.keys.map(key => <ListItem key={key} lesson={lessons.values[key]} />)}
  </InfiniteScroll>
);

LessonList.propTypes = {
  lessons: PropTypes.object.isRequired,
  onFetch: PropTypes.func.isRequired,
};

export default LessonList;
