import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import { Container, Row, Navbar, Collapse, Col, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { I18n } from 'react-i18nify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser } from '@fortawesome/free-solid-svg-icons';
import { routeList } from '../../pages/routes';

import logo from '../../images/logo-black.svg';
import './style.scss';

const Header = ({
  toggle, isOpen, menuItems, user,
}) => {
  const firstName = _.get(user, ['profile', 'firstName']);
  return (
    <div>
      <Container className="pt-2 pb-4">
        <Row>
          <Col className="d-flex align-items-start align-items-lg-center">
            <Link to={routeList.HOME}>
              <img src={logo} alt="RCYN Logo" width="116" />
            </Link>
          </Col>
          <Col>
            <Navbar className="pr-0" color="faded" light expand="lg">
              <NavbarToggler onClick={toggle} className="ml-auto" />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto text-right" navbar>
                  {menuItems.map((item, index) => (
                    // eslint-disable-next-line
                    <NavItem key={index}>
                      <NavLink
                        activeClassName="active"
                        className="nav-link ml-2 ml-lg-1 fz-18 fw-500"
                        exact
                        to={item.url}
                      >
                        {I18n.t(item.title)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <Nav className="ml-md-2 ml-lg-4 align-items-end" navbar>
                  <NavItem className="mb-2 mb-lg-0">
                    <Link
                      className="d-flex align-items-center justify-content-between
                          btn btn-primary btn-lg text-white rounded-0 fz-18 fw-900
                          default-box-shadow w-100"
                      size="lg"
                      color="primary"
                      to={routeList.SETTINGS}
                    >
                      <span>{I18n.t('settings')}</span>
                      <FontAwesomeIcon className="ml-2" icon={faCog} />
                    </Link>
                  </NavItem>
                  <NavItem className="ml-1">
                    <Link
                      className="d-flex align-items-center justify-content-between btn
                          btn-primary btn-lg text-white rounded-0 fz-18 fw-900 default-box-shadow
                          w-100"
                      size="lg"
                      color="primary"
                      to={routeList.MY}
                    >
                      <span>{firstName}</span>
                      <FontAwesomeIcon className="ml-2" icon={faUser} />
                    </Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Header.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
  user: PropTypes.object,
};

Header.defaultProps = {
  user: {},
};

export default Header;
