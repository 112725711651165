import React, { Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import MembersList from '../../Members';
import { dateformat } from './const';

const ListItem = ({ lesson, isMembersOpen, onMembersToggle }) => (
  <Fragment>
    <div className="note-row pt-1 pb-1 pl-2 pr-2">
      <Row className="cursor-pointer" onClick={onMembersToggle}>
        <Col xs={10}>
          <Fragment>
            <div className="fw-500 fz-14">{_.get(lesson, 'group.activity.title', '')}</div>
            <div className="fw-500 fz-14">{_.get(lesson, 'group.title', '')}</div>
          </Fragment>
        </Col>
        <Col className="text-right" xs={2}>
          {moment(lesson.startAt).isSame(moment(), 'day') ? (
            <Fragment>
              <div className="fw-500 fz-14">{moment(lesson.startAt).format('H:mm')}</div>
              <div className="fw-500 fz-14">{moment(lesson.endAt).format('H:mm')}</div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="fw-500 fz-14">{moment(lesson.startAt).format(dateformat)}</div>
              <div className="fw-500 fz-14">{moment(lesson.endAt).format(dateformat)}</div>
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
    {isMembersOpen && (
      <MembersList toggle={onMembersToggle} isOpen={isMembersOpen} lesson={lesson} />
    )}
  </Fragment>
);

ListItem.propTypes = {
  lesson: PropTypes.object.isRequired,
  onMembersToggle: PropTypes.func.isRequired,
  isMembersOpen: PropTypes.bool.isRequired,
};

export default ListItem;
