import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { I18n, Translate } from 'react-i18nify';
import { Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import { routeList } from '../../../../pages/routes';
import { Image } from '../../../../components';

const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const ListItem = ({
  activity, start, end, day,
}) => (
  <Col lg={6}>
    <Card className="border-0 rounded-0 card-img-wr">
      <Link to={`${routeList.ACTIVITIES}/${activity._id}`}>
        <Image imageId={activity.imageId} className="rounded-0 w-100" />
      </Link>
      <CardBody className="pl-0 pr-0 pt-3">
        <CardTitle className="mb-1 fw-700">{activity.title}</CardTitle>
        <CardText>
          {_.get(activity, 'closest_group.groupId') && (
            <small className="text-muted">
              <b>
                <Translate value="next_group_label" />:{' '}
              </b>
              {_.get(activity, 'closestGroup.title')}, {I18n.t(daysOfTheWeek[day] || '')}, {start}-
              {end}
            </small>
          )}
        </CardText>
      </CardBody>
    </Card>
  </Col>
);

ListItem.propTypes = {
  activity: PropTypes.object.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  day: PropTypes.number.isRequired,
};

export default ListItem;
