import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { actionsSelectors } from '../../../modules/actions';
import { usersTypes } from '../../../modules/users';
import Component from './Component';

const enhancer = compose(
  connect(state => ({
    ...actionsSelectors.getFilter(usersTypes.USERS)(state),
  })),
  withState('isEditProfileOpen', 'setIsEditProfileOpen', false),
  withHandlers({
    onEditProfileToggle: ({ isEditProfileOpen, setIsEditProfileOpen }) => () =>
      setIsEditProfileOpen(!isEditProfileOpen),
  })
);

export default enhancer(Component);
