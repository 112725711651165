import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { I18n } from 'react-i18nify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/index';
import Spinner from '../../components/Spinner';

import './style.scss';

const theme = {
  container: 'autosuggest',
  input: 'form-control',
  suggestionsList: 'list-group',
  suggestion: 'justify-content-between list-group-item',
};

const renderSuggestion = getUserProp => suggestion => (
  <span>
    {getUserProp(suggestion, 'firstName')} {getUserProp(suggestion, 'lastName')}
  </span>
);

const renderInputComponent = ({ isLoading }) => inputProps => (
  <div>
    <div className="input-customize">
      {!isLoading && <FontAwesomeIcon icon={faSearch} />}
      {isLoading && <Spinner />}
      <input {...inputProps} />
    </div>
  </div>
);

const renderSuggestionsContainer = ({ containerProps, children }) => (
  <div {...containerProps}>{children}</div>
);

const UserAutosuggest = ({
  users,
  search,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  getSuggestionValue,
  onSuggestionSelected,
  onSearchChange,
  getUserProp,
  isLoading,
}) => (
  <Autosuggest
    theme={theme}
    suggestions={users}
    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    onSuggestionsClearRequested={onSuggestionsClearRequested}
    getSuggestionValue={getSuggestionValue}
    renderSuggestion={renderSuggestion(getUserProp)}
    renderSuggestionsContainer={renderSuggestionsContainer}
    renderInputComponent={renderInputComponent({ isLoading })}
    onSuggestionSelected={onSuggestionSelected}
    inputProps={{
      placeholder: I18n.t('search'),
      value: search,
      onChange: onSearchChange,
    }}
  />
);

renderSuggestionsContainer.propTypes = {
  containerProps: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

UserAutosuggest.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  getUserProp: PropTypes.func.isRequired,
};

export default UserAutosuggest;
