import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { Button, TextInput } from '../../../components';
import { routeList } from '../../../pages/routes';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/first
import logo from '!file-loader!../../../images/logo.svg';
import styles from './styles';

import '../../../containers/Authorization/RestorePassword/style.scss';

const Login = ({ onSubmit, isLoading, formState }) => (
  <div className="login-background login-form-container">
    <Form onSubmit={onSubmit}>
      <FormGroup className="text-center pb-4">
        <img src={logo} alt="RCYN Logo" />
      </FormGroup>
      <FormGroup>
        <Input
          className="rounded-0 font-weight-bold"
          type="text"
          name="email"
          placeholder={I18n.t('email')}
          bsSize="lg"
        />
      </FormGroup>
      <FormGroup>
        <TextInput
          style={styles.textInputError}
          autoComplete="password"
          className="rounded-0 font-weight-bold"
          type="password"
          name="password"
          placeholder="Password"
          message={formState.errors.password.message}
          bsSize="lg"
        />
        <FormFeedback>
          <Translate value={formState.errors.password.message || ''} />
        </FormFeedback>
      </FormGroup>
      <FormGroup className="pt-1 pb-1 text-center">
        <Link className="text-white" to={routeList.FORGOT_PASSWORD}>
          {I18n.t('forgot_password')}
        </Link>
      </FormGroup>
      <Button
        className="rounded-0 font-weight-bold"
        size="lg"
        color="primary"
        block
        type="submit"
        value={I18n.t('log_in')}
        isLoading={isLoading}
      />
    </Form>
  </div>
);

Login.propTypes = {
  formState: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Login;
