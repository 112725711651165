import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSignOutAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import ChangePasswordModal from '../ChangePasswordModal/index';
import EditProfile from '../../EditProfile/index';
import { authTypes } from '../../../modules/authorize';
import Image from '../../../components/Image';
import { reduceLargeStrings } from '../../../utils';

import './style.scss';

const AuthorizeAccount = ({
  user,
  onLogOut,
  onChangePasswordToggle,
  isChangePasswordOpen,
  getUserProp,
  userGroups,
  isEditProfileOpen,
  onEditProfileToggle,
}) => (
  <div>
    <Container>
      <Row>
        <Col className="text-center" lg={6} md={12}>
          <Image imageId={getUserProp(user, 'imageId')} />
        </Col>
        <Col className="d-flex flex-column justify-content-between mt-4 mt-lg-0" lg={6} md={12}>
          <div>
            <Row>
              <Col className="user-name-wr d-flex align-items-start mb-4">
                <div className="user-name fw-900">
                  {getUserProp(user, 'firstName')} {getUserProp(user, 'lastName')}
                </div>
              </Col>
            </Row>
            <Row className="profile-info-wr">
              <Col>
                <h4 className="mb-3">{I18n.t('information')}</h4>
                <div className="mb-3">
                  <h6 className="mb-0 fz-18">{I18n.t('address')}</h6>
                  <div className="fw-300 fz-14">
                    {reduceLargeStrings(getUserProp(user, 'address', ' - '))}
                  </div>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0 fz-18">{I18n.t('email')}</h6>
                  <div className="fw-300 fz-14">
                    {reduceLargeStrings(getUserProp(user, 'email', ' - '))}
                  </div>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0 fz-18">{I18n.t('phone_number')}</h6>
                  <div className="fw-300 fz-14">
                    {reduceLargeStrings(getUserProp(user, 'phone', ' - '))}
                  </div>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0 fz-18">{I18n.t('facebook')}</h6>
                  <div className="fw-300 fz-14">
                    <a
                      href={getUserProp(user, 'facebookUrl', '#')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {reduceLargeStrings(getUserProp(user, 'facebookUrl', ' - '))}
                    </a>
                  </div>
                </div>
                <div className="mb-3">
                  <h6 className="mb-0 fz-18">{I18n.t('user_groups')}</h6>
                  <div className="fw-300 fz-14">
                    {userGroups.map(role => I18n.t(authTypes.ROLES_TRANSLATE[role]))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col className="d-flex">
                <Button
                  className="d-flex align-items-center justify-content-between text-white
                  rounded-0 fz-18 fw-900 default-box-shadow mr-1"
                  size="lg"
                  color="primary"
                  onClick={onEditProfileToggle}
                >
                  <span>{I18n.t('edit_profile')}</span>
                  <FontAwesomeIcon className="ml-2" icon={faPencilAlt} />
                </Button>
                <Button
                  className="d-flex align-items-center justify-content-between text-white
                  rounded-0 fz-18 fw-900 default-box-shadow"
                  size="lg"
                  color="primary"
                  onClick={onChangePasswordToggle}
                >
                  <span>{I18n.t('change_password')}</span>
                  <FontAwesomeIcon className="ml-2" icon={faLock} />
                </Button>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-start mt-3">
                <Button
                  className="info-btn btn btn-primary btn-lg d-flex align-items-center
              justify-content-between text-white rounded-0 fz-18 fw-900 default-box-shadow"
                  size="lg"
                  color="primary"
                  onClick={onLogOut}
                >
                  <span>{I18n.t('log_out')}</span>
                  <FontAwesomeIcon className="ml-2" icon={faSignOutAlt} />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
    {isEditProfileOpen && (
      <EditProfile isOpen={isEditProfileOpen} toggle={onEditProfileToggle} user={user} />
    )}
    <ChangePasswordModal toggle={onChangePasswordToggle} isOpen={isChangePasswordOpen} />
  </div>
);

AuthorizeAccount.propTypes = {
  user: PropTypes.object,
  onLogOut: PropTypes.func.isRequired,
  onChangePasswordToggle: PropTypes.func.isRequired,
  isChangePasswordOpen: PropTypes.bool.isRequired,
  userGroups: PropTypes.array,
  onEditProfileToggle: PropTypes.func.isRequired,
  isEditProfileOpen: PropTypes.bool.isRequired,
  getUserProp: PropTypes.func.isRequired,
};

AuthorizeAccount.defaultProps = {
  userGroups: [],
  user: {},
};

export default AuthorizeAccount;
