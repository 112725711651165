import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Spinner = () => (
  <FontAwesomeIcon
    className="spinner-style position-absolute fixed-bottom fixed-top mt-auto mb-auto"
    icon={faSpinner}
    spin
  />
);

export default Spinner;
