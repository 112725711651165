import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { I18n } from 'react-i18nify';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Label, Input } from 'reactstrap';

// Takes 3 arguments:
// label - will be used as a label of the input
// name - will be used as name, when collecting data from form for example
// data - will be used as array with option elements
// onChange - will be returned value selected element

const SelectType = ({
  label, data, name, onSelect, ...rest
}) => (
  <FormGroup className="mb-0">
    <Label className="input-label fz-18 fw-500">{label}</Label>
    <FormGroup className="select-customize">
      <FontAwesomeIcon icon={faAngleDown} />
      <Input onChange={e => onSelect(e)} type="select" name={name} {...rest}>
        {data.map((element, index) => (
          // eslint-disable-next-line
          <option key={element.value || index} value={element.value}>
            {I18n.t(element.field) || element || ''}
          </option>
        ))}
      </Input>
    </FormGroup>
  </FormGroup>
);

SelectType.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
};

SelectType.defaultProps = {
  onSelect: '',
};

export default SelectType;
