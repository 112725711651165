import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { I18n, Translate } from 'react-i18nify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import MembersList from '../Members';
import { getScheduledPeriod } from '../../../utils/helpers';
import './style.scss';
import { DEFAULT_TIMEZONE_KEY } from '../../../constants';

const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const daysOfTheWeekCuy = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const dateFormat = 'DD/MM/YYYY';

const Group = ({
  group,
  onEditModalOpen,
  onMembersToggle,
  isMembersOpen,
  coaches,
  getUserProp,
}) => (
  <div className="list-group activity-group-list mt-4">
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h6>
          <b>{group.title}</b>
        </h6>
      </div>
      <p className="fz-14">{group.description}</p>
    </div>
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h6>
          <b>
            <Translate value="period" />
          </b>
        </h6>
      </div>
      <p className="fz-14">
        {I18n.t(daysOfTheWeekCuy[moment(group.startDate).day()])}
        {moment(group.startDate).format(dateFormat)} &nbsp;-&nbsp;
        {I18n.t(daysOfTheWeekCuy[moment(group.endDate).day()])}
        {moment(group.endDate).format(dateFormat)}
      </p>
    </div>
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h6>
          <b>
            <Translate value="schedule" />
          </b>
        </h6>
      </div>
      <div className="fz-14">
        {_.sortBy(group.schedule, ['day', 'startAt']).map(({
 day, isLocalTime, fullStartAt, fullEndAt,
}, index) => {
            const { start, end } = getScheduledPeriod({
              startDate: fullStartAt,
              endDate: fullEndAt,
              //  todo: set timezone into DB on creating activity
              timezoneKey: group.timezoneKey || DEFAULT_TIMEZONE_KEY,
              isLocalTime,
            });
            return (
              // eslint-disable-next-line
              <p key={index} className="fw-500 fz-14">
                {I18n.t(daysOfTheWeek[day])}
                ,&nbsp;
                {start}-{end}
              </p>
            );
          })}
      </div>
    </div>
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div className="d-flex w-100 justify-content-between">
        <h6>
          <b>
            <Translate value="volunteer" />
          </b>
        </h6>
      </div>
      {coaches.map(coach => (
        <p key={coach._id} className="fz-14">
          {getUserProp(coach, 'firstName')} {getUserProp(coach, 'lastName')}
        </p>
      ))}
    </div>
    <div className="list-group-item list-group-item-action flex-column align-items-start">
      <div>
        <Button
          className="d-flex align-items-center
                  justify-content-between text-secondary rounded-0 fz-18 fw-900 default-box-shadow
                  w-100 mb-2"
          outline
          size="lg"
          color="secondary"
          onClick={onMembersToggle}
        >
          <span>{I18n.t('register')}</span>
          <FontAwesomeIcon className="ml-2" icon={faUsers} />
        </Button>
        <Button
          className="d-flex align-items-center
                  justify-content-between text-secondary rounded-0 fz-18 fw-900 default-box-shadow
                  w-100 mb-2"
          outline
          size="lg"
          color="secondary"
          onClick={onEditModalOpen}
        >
          <span>{I18n.t('edit_team')}</span>
          <FontAwesomeIcon className="ml-2" icon={faPencilAlt} />
        </Button>
      </div>
      {isMembersOpen && (
        <MembersList toggle={onMembersToggle} isOpen={isMembersOpen} group={group} />
      )}
    </div>
  </div>
);

Group.propTypes = {
  onEditModalOpen: PropTypes.func.isRequired,
  group: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    schedule: PropTypes.string.isRequired,
    //  todo: set timezone into DB on creating activity
    timezoneKey: PropTypes.string,
  }).isRequired,
  onMembersToggle: PropTypes.func.isRequired,
  isMembersOpen: PropTypes.bool.isRequired,
  coaches: PropTypes.array.isRequired,
  getUserProp: PropTypes.func.isRequired,
};

export default Group;
