const FETCH_LESSONS = 'FETCH_LESSONS';
const CLEAR_LESSONS = 'CLEAR_LESSONS';
const FETCH_LESSONS_STATISTICS = 'FETCH_LESSONS_STATISTICS';
const CLEAR_LESSONS_STATISTICS = 'CLEAR_LESSONS_STATISTICS';
const LESSONS = 'LESSONS';

export default {
  FETCH_LESSONS,
  CLEAR_LESSONS,
  FETCH_LESSONS_STATISTICS,
  CLEAR_LESSONS_STATISTICS,
  LESSONS,
};
