import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '../../../components';
import ListItem from './ListItem';

const NotesList = ({ onFetch, notes, filteredNotesKeys }) => (
  <InfiniteScroll
    pageStart={1}
    loadMore={onFetch}
    hasMore={notes.count > filteredNotesKeys.length}
    loader={
      <Col lg={12} key="loader" className="d-flex justify-content-center">
        <span className="position-relative pl-4 pr-4">
          {I18n.t('loading')} ...<Spinner />
        </span>
      </Col>
    }
  >
    {filteredNotesKeys.map(key => <ListItem key={key} note={notes.values[key]} />)}
  </InfiniteScroll>
);

NotesList.propTypes = {
  notes: PropTypes.object.isRequired,
  onFetch: PropTypes.func.isRequired,
  filteredNotesKeys: PropTypes.array.isRequired,
};

export default NotesList;
