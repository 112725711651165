import moment from 'moment';
import { withProps } from 'recompose';
import Component from './Component';
import { DEFAULT_TIMEZONE_KEY } from '../../../../constants';
import { getScheduledTime } from '../../../../utils/helpers';

const enhancer = withProps(({
  activity: {
    closest_group: {
      start,
      end,
      //  todo: set timezone into DB on creating activity
      timezoneKey = DEFAULT_TIMEZONE_KEY,
      isLocalTime,
    } = {},
  } = {},
}) => ({
  start: getScheduledTime({ date: start, timezoneKey, isLocalTime }),
  end: getScheduledTime({ date: end, timezoneKey, isLocalTime }),
  day:
      start &&
      moment(start)
        .utcOffset(0)
        .day(),
}));

export default enhancer(Component);
