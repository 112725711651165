import { get, query, post, patch, remove, APIAddresses } from '../../utils/api';
import * as actions from './actions';
import types from './types';
import * as statisticActions from '../statistics/actions';

const fetchActivities = (search, skip, limit) => async (dispatch, getState) => {
  try {
    const { activities: { isFetchingActivities } = {} } = getState();
    if (!isFetchingActivities) {
      dispatch(actions.setIsFetchingActivities(true));
      const { data } = await get(
        `${APIAddresses.ACTIVITIES}${query({ search, skip, limit })}`,
        dispatch,
        types.FETCH_ACTIVITIES
      );
      const { activities, count } = data;
      dispatch(actions.fetchActivities(activities, count));
      dispatch(actions.setIsFetchingActivities(false));
    }
  } catch (error) {
    throw error;
  }
};

const fetchActivityStatistic = filter => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.LESSONS}/statistic/${query({ filter })}`, dispatch);
    dispatch(statisticActions.fetchStatistics(data.lessons));
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchActivityStatisticCount = () => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.ACTIVITIES}/statistic/count`, dispatch);
    return data;
  } catch (error) {
    throw error;
  }
};
const fetchActivity = id => async (dispatch) => {
  try {
    const {
      data: { activity },
    } = await get(`${APIAddresses.ACTIVITIES}${id}`, dispatch, types.FETCH_ACTIVITIES);
    dispatch(actions.addActivity(activity));
    return activity;
  } catch (error) {
    throw error;
  }
};
const fetchActiveActivity = id => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.ACTIVITIES}${id}`, dispatch, types.FETCH_ACTIVITIES);
    dispatch(actions.setActiveActivity(data));
  } catch (error) {
    throw error;
  }
};

const clearActivities = () => dispatch => dispatch(actions.clearActivities());
const clearActivity = () => dispatch => dispatch(actions.clearActivity());

const createActivity = formData => async (dispatch) => {
  try {
    const { data } = await post(
      APIAddresses.CREATE_ACTIVITY,
      formData,
      dispatch,
      types.CREATE_ACTIVITY
    );
    dispatch(actions.createActivity(data.activity));
    return data;
  } catch (error) {
    throw error;
  }
};

const updateActivity = (id, formData) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.ACTIVITIES}${id}`,
      formData,
      dispatch,
      types.UPDATE_ACTIVITY
    );
    dispatch(actions.setActiveActivity(data));
  } catch (error) {
    throw error;
  }
};

const removeActivity = id => async (dispatch) => {
  try {
    const { success } = await remove(
      `${APIAddresses.ACTIVITIES}${id}`,
      dispatch,
      types.REMOVE_ACTIVITY
    );
    return success;
  } catch (error) {
    throw error;
  }
};

const fetchActivityForAutosuggest = (search, skip, limit, filter) => async () => {
  try {
    const { data } = await get(`${APIAddresses.ACTIVITIES}${query({
      search,
      skip,
      limit,
      filter,
    })}`);
    const { activities } = data;
    return activities;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchActivities,
  fetchActivity,
  fetchActiveActivity,
  clearActivities,
  createActivity,
  updateActivity,
  removeActivity,
  clearActivity,
  fetchActivityForAutosuggest,
  fetchActivityStatisticCount,
  fetchActivityStatistic,
};
