import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { Button } from '../../../components';

import './style.scss';

const ChangePasswordModal = ({
  toggle,
  isOpen,
  onSubmit,
  formState,
  isSuccessSubmit,
  isPasswordsDoNotMatch,
  isLoading,
  isTemporary,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('change_password')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Alert isOpen={isTemporary} color="info">
          {I18n.t('temporary_password_message')}
        </Alert>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label className="fz-18 fw-500" for="password">
              {I18n.t('enter_old_password')}
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              invalid={!formState.errors.password.valid && !isSuccessSubmit}
              valid={isSuccessSubmit}
            />
            <FormFeedback>
              <Translate value={formState.errors.password.message || ''} />
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="fz-18 fw-500" for="newPassword">
              {I18n.t('enter_new_password')}
            </Label>
            <Input
              type="password"
              name="newPassword"
              id="newPassword"
              invalid={!formState.errors.newPassword.valid && !isSuccessSubmit}
              valid={isSuccessSubmit}
            />
            <FormFeedback>
              <Translate value={formState.errors.newPassword.message || ''} />
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="fz-18 fw-500" for="newPasswordAgain">
              {I18n.t('enter_new_password_again')}
            </Label>
            <Input
              type="password"
              name="newPasswordAgain"
              id="newPasswordAgain"
              invalid={isPasswordsDoNotMatch && !isSuccessSubmit}
              valid={isSuccessSubmit}
            />
            <FormFeedback>{I18n.t('passwords_do_not_match')}</FormFeedback>
          </FormGroup>
          <FormGroup className="d-flex justify-content-end">
            <Button
              className="rounded-0 fz-24 fw-900"
              color="primary"
              size="lg"
              isLoading={isLoading}
            >
              {I18n.t('save_new_password')}
            </Button>
          </FormGroup>
          <Alert isOpen={isSuccessSubmit} color="success">
            {I18n.t('password_changed')}
          </Alert>
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

ChangePasswordModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func,
  isSuccessSubmit: PropTypes.bool.isRequired,
  isPasswordsDoNotMatch: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isTemporary: PropTypes.bool,
};

ChangePasswordModal.defaultProps = {
  toggle: () => {},
  isTemporary: false,
};

export default ChangePasswordModal;
