import types from './types';
import { normalize } from '../../utils/data';

const fetchMessages = (messages, count) => ({
  count,
  type: types.FETCH_MESSAGES,
  messages: normalize('_id', messages),
});

const clearMessages = () => ({
  type: types.CLEAR_MESSAGES,
});

const createMessage = message => ({
  type: types.CREATE_MESSAGE,
  message,
});

const addMessage = message => ({
  type: types.ADD_MESSAGE,
  message,
});

export { fetchMessages, clearMessages, addMessage, createMessage };
