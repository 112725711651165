import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Toolbar from './Toolbar';
import EditProfile from '../../EditProfile';

const filterTranslateMap = {
  isNotApproval: 'awaiting_approval',
  isNotAgreeGDPR: 'privacy_policy_waiting_label',
};

const Header = ({ filter, isEditProfileOpen, onEditProfileToggle }) => (
  <div>
    {isEditProfileOpen && (
      <EditProfile isOpen={isEditProfileOpen} toggle={onEditProfileToggle} user={{}} />
    )}

    <Row className="mb-3 pb-2 align-items-end">
      <Col lg={9}>
        <Toolbar />
      </Col>
      <Col lg={3}>
        <Button
          className="rounded-0 font-weight-bold d-flex justify-content-between
            align-items-center default-box-shadow"
          size="lg"
          color="primary"
          block
          onClick={onEditProfileToggle}
        >
          <span>{I18n.t('create_profile')}</span>
          <FontAwesomeIcon className="ml-2" icon={faUserPlus} />
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        {filter.userGroups && (
          <h3 className="fw-700">
            {I18n.t(filterTranslateMap[filter.userGroups] || filter.userGroups)}
          </h3>
        )}
      </Col>
    </Row>
  </div>
);

Header.propTypes = {
  isEditProfileOpen: PropTypes.bool.isRequired,
  onEditProfileToggle: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

export default Header;
