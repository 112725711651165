import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authTypes, authOperations } from '../../../modules/authorize';
import { withFormState, withFormErrorHandler } from '../../../utils/recompose-extensions/form';
import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const { getLoader } = loaderSelectors;
const { restorePassword } = authOperations;

const enhancer = compose(
  connect(
    state => ({
      ...getLoader(authTypes.RESTORE_PASSWORD)(state),
    }),
    { restorePassword }
  ),
  withRouter,
  withFormState({
    email: {},
  }),
  withFormErrorHandler({
    // <- if response with code 405 show message for field below
    'User not found': {
      field: 'email',
      message: 'wrong_email_address',
    },
  }),
  withState('restoreConfirmed', 'setRestoreConfirmed', false),
  withHandlers({
    onSubmit: props => (e) => {
      e.preventDefault();
      const email = e.target.email.value;
      props
        .restorePassword(email)
        .then(() => props.setRestoreConfirmed(true))
        .catch(props.catchFormError);
    },
  })
);

export default enhancer(Component);
