import { onNewMessage, onNewNote } from './listeners';
import { io } from '../../utils/api';

const initSockets = token => (dispatch) => {
  io.emit('authenticate', { token });
  onNewNote(dispatch);
  onNewMessage(dispatch);
};

export { initSockets };
