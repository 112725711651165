import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/index';

import Modal from './Modal';

const Settings = ({ onClickItem }) => (
  <div>
    <Container>
      <Modal />
      <Row>
        <Col>
          <h1 className="mb-3">{I18n.t('settings')}</h1>
        </Col>
      </Row>
      <Row>
        <Col className="col-lg-6">
          <p className="mb-3 ml-3">
            <Translate value="settings_sub-title" /> (<Translate value="user" />)
          </p>
        </Col>
        <Col className="col-lg-6">
          <p className="mb-3 ml-3">
            <Translate value="settings_sub-title" /> (<Translate value="volunteer" />)
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="col-lg-6">
          <ListGroup className="settings-list">
            <div>
              {['modal-1', 'GDRP', 'modal-3'].map(key => (
                <ListGroupItem
                  key={key}
                  className="fw-900 d-flex justify-content-between"
                  active
                  tag="a"
                  href="#"
                  onClick={onClickItem(key, 'user')}
                >
                  <Translate value={`settings_${key}`} />
                  <FontAwesomeIcon className="fz-24 cursor-pointer mr-3" icon={faPencilAlt} />
                </ListGroupItem>
              ))}
            </div>
          </ListGroup>
        </Col>
        <Col className="col-lg-6">
          <ListGroup className="settings-list">
            <div>
              {['modal-1', 'GDRP', 'modal-3'].map(key => (
                <ListGroupItem
                  key={key}
                  className="fw-900 d-flex justify-content-between"
                  active
                  tag="a"
                  href="#"
                  onClick={onClickItem(key, 'volunteer')}
                >
                  <Translate value={`settings_${key}`} />
                  <FontAwesomeIcon className="fz-24 cursor-pointer mr-3" icon={faPencilAlt} />
                </ListGroupItem>
              ))}
            </div>
          </ListGroup>
        </Col>
      </Row>

      <Row>
        <p className="mb-3 mt-3 ml-3">
          <Translate value="settings_sub-title" /> (<Translate value="all" />)
        </p>
      </Row>

      <Row>
        <Col className="col-lg-12">
          <ListGroup className="settings-list">
            <ListGroupItem
              className="fw-900 d-flex justify-content-between"
              active
              tag="a"
              href="#"
              key="GDRP-under-13"
              onClick={onClickItem('GDRP', 'under-13')}
            >
              <Translate value="settings_GDRP_under-13" />
              <FontAwesomeIcon className="fz-24 cursor-pointer mr-3" icon={faPencilAlt} />
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

Settings.propTypes = {
  onClickItem: PropTypes.func.isRequired,
};

export default Settings;
