import _ from 'lodash';
import { post, put, get, APIAddresses, patch } from '../../utils/api/index';
import { setAuthToken, setAuthUser, unSetAuthUser } from '../../utils/api/authorization';
import { setUser, unsetUser, passwordChange } from './actions';
import types from './types';

const {
  SIGN_IN, RESTORE_PASSWORD, AUTHORIZE, CHANGE_PASSWORD, UPDATE_MY,
} = types;

const logIn = (email, password) => async (dispatch) => {
  try {
    const { data } = await post(APIAddresses.SIGN_IN, { email, password }, dispatch, SIGN_IN);
    const { token, user } = data;
    setAuthToken(token);
    setAuthUser(user.email, user.roles);
    dispatch(setUser(user));
  } catch (error) {
    throw error;
  }
};

const logOut = () => (dispatch) => {
  try {
    post(APIAddresses.SIGN_OUT, {});
    unSetAuthUser();
    dispatch(unsetUser());
  } catch (error) {
    throw error;
  }
};

const authorize = (token = '') => async (dispatch) => {
  try {
    const { data } = await get(APIAddresses.GET_ACCOUNT, dispatch, AUTHORIZE, null, token);
    dispatch(setUser(data.user));
    return { user: data.user };
  } catch (error) {
    throw error;
  }
};

const updateMy = (formData, token) => async (dispatch) => {
  try {
    const response = await patch(
      APIAddresses.UPDATE_MY,
      formData,
      dispatch,
      UPDATE_MY,
      null,
      token
    );
    const user = _.get(response, 'data.user');
    dispatch(setUser(user));
    return user;
  } catch (error) {
    throw error;
  }
};

const restorePassword = email => async (dispatch) => {
  try {
    const { success } = await post(
      APIAddresses.RESTORE_PASSWORD,
      { email },
      dispatch,
      RESTORE_PASSWORD
    );
    return success;
  } catch (error) {
    throw error;
  }
};

const changePassword = (newPassword, password) => async (dispatch) => {
  try {
    const { success } = await put(
      APIAddresses.CHANGE_PASSWORD,
      {
        newPassword,
        password,
      },
      dispatch,
      CHANGE_PASSWORD
    );
    dispatch(passwordChange());
    return success;
  } catch (error) {
    throw error;
  }
};

export default {
  restorePassword,
  logIn,
  logOut,
  authorize,
  updateMy,
  changePassword,
};
