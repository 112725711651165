import { get, query, post, APIAddresses } from '../../utils/api';
import * as actions from './actions';
import types from './types';

const fetchMessages = (skip, limit, filter) => async (dispatch) => {
  try {
    const { data: { messages, count } = {} } = await get(
      `${APIAddresses.MESSAGES}${query({
        skip,
        limit,
        filter,
      })}`,
      dispatch
    );
    dispatch(actions.fetchMessages(messages, count));
  } catch (error) {
    throw error;
  }
};

const clearMessages = () => dispatch => dispatch(actions.clearMessages());

const addMessage = message => dispatch => dispatch(actions.addMessage(message));

const createMessage = formData => async (dispatch) => {
  try {
    const { data } = await post(APIAddresses.MESSAGES, formData, dispatch, types.CREATE_MESSAGE);
    dispatch(actions.createMessage(data.message));
    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchMessages,
  clearMessages,
  createMessage,
  addMessage,
};
