import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, FormFeedback, Label, Jumbotron } from 'reactstrap';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { Button } from '../../../components';
import { routeList } from '../../../pages/routes';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/first
import logo from '!file-loader!../../../images/logo.svg';
import './style.scss';

const RestorePassword = ({
  onSubmit, isLoading, formState, restoreConfirmed,
}) =>
  !restoreConfirmed ? (
    <div className="login-background d-flex align-items-center justify-content-center">
      <Form onSubmit={onSubmit}>
        <FormGroup className="text-center pb-5">
          <img src={logo} alt="RCYN Logo" />
        </FormGroup>
        <FormGroup className="m-0">
          <legend className="text-white text-center font-weight-bold">
            {I18n.t('forgot_password')}
          </legend>
        </FormGroup>
        <FormGroup>
          <Label className="text-white" for="email">
            {I18n.t('enter_email')}
          </Label>
          <Input
            className="rounded-0"
            id="email"
            name="email"
            type="email"
            placeholder="mail@email.dk"
            bsSize="lg"
            invalid={!formState.errors.email.valid}
            valid={formState.errors.email.valid}
          />
          <FormFeedback>
            <Translate value={formState.errors.email.message || ''} />
          </FormFeedback>
        </FormGroup>
        <FormGroup className="pt-2 col-form-label-sm">
          <Label className="text-white">{I18n.t('receive_new_password')}</Label>
          <Button
            isLoading={isLoading}
            className="rounded-0 font-weight-bold"
            size="lg"
            color="primary"
            block
          >
            {I18n.t('send')}
          </Button>
        </FormGroup>
      </Form>
    </div>
  ) : (
    <div className="login-background d-flex align-items-center justify-content-center flex-column">
      <div className="text-center pb-5">
        <img src={logo} alt="RCYN Logo" />
      </div>
      <Jumbotron className="rounded-0 bg-white p-3 mb-0 w-100 sent-pass-box">
        <p className="pb-5">{I18n.t('password_sent_to_email')}</p>
        <Link
          className="btn btn-primary btn-lg text-white rounded-0 font-weight-bold w-100"
          size="lg"
          color="primary"
          to={routeList.LOGIN}
          block
        >
          {I18n.t('close_the_window')}
        </Link>
      </Jumbotron>
    </div>
  );

RestorePassword.propTypes = {
  restoreConfirmed: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
};

export default RestorePassword;
