const FETCH_USERS = 'FETCH_USERS';
const CLEAR_USERS = 'CLEAR_USERS';
const CLEAR_USER = 'CLEAR_USER';
const CREATE_USER = 'CREATE_USER';
const ADD_USER = 'ADD_USER';
const USERS = 'USERS';
const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
const UPDATE_USER = 'UPDATE_USER';
const REMOVE_USER = 'REMOVE_USER';
const APPROVE_USER = 'APPROVE_USER';
const USER_STATISTICS = 'USER_STATISTICS';
const DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL';

export default {
  FETCH_USERS,
  CREATE_USER,
  CLEAR_USERS,
  CLEAR_USER,
  ADD_USER,
  USERS,
  SET_ACTIVE_USER,
  FETCH_USER_GROUPS,
  UPDATE_USER,
  REMOVE_USER,
  APPROVE_USER,
  USER_STATISTICS,
  DOWNLOAD_EXCEL,
};
