import moment from 'moment';
import _ from 'lodash';
import { get, query, patch, remove, post, APIAddresses, getFile } from '../../utils/api';
import * as actions from '../users/actions';
import * as statisticActions from '../statistics/actions';
import types from '../users/types';

const fetchUsersForAutosuggest = (search, skip, limit, type) => async () => {
  try {
    const { data } = await get(`${APIAddresses.USERS}${query({
      search,
      skip,
      limit,
      type,
    })}`);
    const { users } = data;
    return users;
  } catch (error) {
    throw error;
  }
};

const fetchUsers = (search, skip, limit, type, isApproved, isAgreeGDPR) => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.USERS}${query({
      search,
      skip,
      limit,
      type,
      isApproved,
      isAgreeGDPR,
    })}`);
    const { users, count } = data;
    dispatch(actions.fetchUsers(users, count));
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchUser = _id => async (dispatch) => {
  try {
    if (typeof _id === 'string') {
      const { data } = await get(`${APIAddresses.USERS}${_id}`, dispatch, types.FETCH_USER);
      dispatch(actions.addUser(data.user));
    }
  } catch (error) {
    throw error;
  }
};

const fetchUserGroups = (_id, skip, limit) => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.USERS}${_id}/groups${query({
      skip,
      limit,
    })}`);
    dispatch(actions.fetchUserGroups(data));
  } catch (error) {
    throw error;
  }
};

const fetchActiveUser = id => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.USERS}${id}`, dispatch, types.SET_ACTIVE_USER);
    dispatch(actions.setActiveUser(data));
  } catch (error) {
    throw error;
  }
};

const updateUser = (id, formData) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.USERS}${id}`,
      formData,
      dispatch,
      types.UPDATE_USER
    );
    dispatch(actions.setActiveUser(data));
  } catch (error) {
    throw error;
  }
};

const removeUser = id => async (dispatch) => {
  try {
    const { success } = await remove(`${APIAddresses.USERS}${id}`, dispatch, types.REMOVE_USER);
    return success;
  } catch (error) {
    throw error;
  }
};

const approveUser = (id, isApproved) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.USERS}${id}/${(isApproved && 'approve') || 'un-approve'}`,
      dispatch,
      types.APPROVE_USER
    );
    dispatch(actions.setActiveUser(data));
  } catch (error) {
    throw error;
  }
};
const deactivateUser = (id, value) => async (dispatch) => {
  try {
    const { data } = await patch(
      `${APIAddresses.USERS}${id}/de-activate/${value}`,
      dispatch,
      types.APPROVE_USER
    );
    dispatch(actions.setActiveUser(data));
  } catch (error) {
    throw error;
  }
};

const createUser = formData => async (dispatch) => {
  try {
    const response = await post(APIAddresses.USERS, formData, dispatch, types.CREATE_USER);
    const user = _.get(response, 'data.user');
    dispatch(actions.createUser(user));
    return user;
  } catch (error) {
    throw error;
  }
};

const fetchFiles = fileIds => async () => {
  try {
    const promises = fileIds.map(att => get(`${APIAddresses.FILES}${att}/info`));
    const files = Promise.all(promises).then(responses =>
      responses.map(response => _.pick(_.get(response, ['data', 'file']), ['name', '_id'])));
    return await files;
  } catch (error) {
    throw error;
  }
};

const fetchSingleFile = fileId => async () => {
  try {
    const { data, headers } = (await getFile(`${APIAddresses.FILES}${fileId}`)) || {};
    return { data, headers };
  } catch (error) {
    throw error;
  }
};

const clearUsers = () => dispatch => dispatch(actions.clearUsers());
const clearUser = () => dispatch => dispatch(actions.clearUser());

const fetchUserStatistics = (userId, filter) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.LESSONS}/statistic/${userId}${query({ filter })}`,
      dispatch
    );
    dispatch(statisticActions.fetchStatistics(data.lessons.map(l =>
      _.extend(l, {
        _id: moment(l.startAt).format('DD/MM/YYYY'),
      }))));
    return data;
  } catch (error) {
    throw error;
  }
};

const fetchUserStatisticsCount = () => async (dispatch) => {
  try {
    const { data } = await get(`${APIAddresses.USERS}/statistic/count`, dispatch);
    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchUsersForAutosuggest,
  fetchUsers,
  fetchUser,
  clearUsers,
  clearUser,
  fetchActiveUser,
  fetchUserGroups,
  updateUser,
  removeUser,
  approveUser,
  fetchFiles,
  createUser,
  fetchUserStatistics,
  fetchUserStatisticsCount,
  deactivateUser,
  fetchSingleFile,
};
