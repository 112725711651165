import types from './types';
import authTypes from '../authorize/types';

const DEFAULT_ACTIONS = {
  search: {},
  filter: {},
};

export default (state = DEFAULT_ACTIONS, action) => {
  switch (action.type) {
    case authTypes.SIGN_OUT:
      return DEFAULT_ACTIONS;

    case types.SEARCH: {
      const { inModel, value } = action;
      return { ...state, search: { ...state.search, [inModel]: value } };
    }
    case types.FILTER: {
      const { inModel, value, name } = action;
      const filter = state.filter[inModel] || {};
      return { ...state, filter: { ...state.filter, [inModel]: { ...filter, [name]: value } } };
    }
    case types.CLEAR_FILTER: {
      const { inModel } = action;
      return { ...state, filter: { ...state.filter, [inModel]: {} } };
    }
    default:
      return state;
  }
};
