import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Alert, Label, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons/index';
import {
  Button,
  TextInput,
  UserAutosuggest,
  GroupSelector,
  ActivityAutosuggest,
} from '../../../components/index';
import styles from './styles';

const transformActivity = (activityId, allActivities) => {
  const activity = allActivities.values[activityId];
  return {
    activityId,
    activityTitle: activity.title,
  };
};

const CreateMessage = ({
  toggle,
  isOpen,
  onSubmit,
  isLoading,
  allActivities,
  onUserSelected,
  usersToSend,
  activitiesToSend,
  groupsToSend,
  onUserRemoved,
  onSelectActivity,
  onSelectGroup,
  onAddRecipient,
  onGroupRemoved,
  onActivityRemoved,
  selectedActivity,
  formState,
}) => (
  <div>
    <Modal className="modal-fs" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('send_message')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          <TextInput value={I18n.t('heading')} name="title" required />
          <TextInput
            value={I18n.t('message_content')}
            name="description"
            type="textarea"
            rows="4"
          />
          <FormGroup>
            <div className="fw-900 fz-18">{I18n.t('add_recipient')}</div>
            <div className="d-flex align-items-end">
              <div>
                <Label className="fz-18 fw-500">{I18n.t('search_users')}</Label>
                <UserAutosuggest onSelected={onUserSelected} clearAfterSelect />
              </div>
            </div>
            <div className="info-btns d-flex align-items-end mt-3">
              <FormGroup className="mb-0">
                <Label className="input-label fz-18 fw-500">{I18n.t('activity')}</Label>
                <ActivityAutosuggest
                  onSelected={onSelectActivity}
                  selectedValue={_.get(allActivities, ['values', selectedActivity, 'title'])}
                />
              </FormGroup>

              <FormGroup className="mb-0">
                <GroupSelector activityId={selectedActivity} onSelect={onSelectGroup} />
              </FormGroup>
              <Button
                childContainerStyle={styles.addNewRecipient}
                className="rounded-0 px-0 fz-18 fw-700"
                color="primary"
                size="lg"
                isLoading={isLoading}
                onClick={onAddRecipient}
              >
                {I18n.t('add_recipient')}
              </Button>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="fw-900 fz-18">{I18n.t('recipients')}</div>
            <hr />
            <Row>
              {usersToSend.map((user, index) => (
                <Col lg={4} key={user._id} className="fz-18 mb-2">
                  <FontAwesomeIcon
                    className="mr-2 cursor-pointer"
                    icon={faTrash}
                    onClick={() => onUserRemoved(index)}
                  />
                  <span>{`${user.profile.firstName} ${user.profile.lastName}`}</span>
                </Col>
              ))}
              {activitiesToSend
                .map(id => transformActivity(id, allActivities))
                .map(res => (
                  <Col lg={4} key={res.activityId} className="fz-18 mb-2">
                    <FontAwesomeIcon
                      className="mr-2 cursor-pointer"
                      icon={faTrash}
                      onClick={() => onActivityRemoved(res.activityId)}
                    />
                    <span>{res.activityTitle}</span>
                  </Col>
                ))}
              {groupsToSend.map(res => (
                <Col lg={4} key={res.id} className="fz-18 mb-2">
                  <FontAwesomeIcon
                    className="mr-2 cursor-pointer"
                    icon={faTrash}
                    onClick={() => onGroupRemoved(res.id)}
                  />
                  <span>{res.activityTitle}</span>
                  <p className="fz-18 ml-4">{res.groupTitle}</p>
                </Col>
              ))}
            </Row>
            <hr />
          </FormGroup>
          <FormGroup className="d-flex justify-content-end">
            <Button
              className="rounded-0 fz-24 fw-900"
              color="primary"
              size="lg"
              isLoading={isLoading}
            >
              {I18n.t('send_message')}
            </Button>
          </FormGroup>
          {formState.error && (
            <Alert color="danger">
              <Translate value={formState.error} />
            </Alert>
          )}
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

CreateMessage.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allActivities: PropTypes.object.isRequired,

  usersToSend: PropTypes.array.isRequired,
  activitiesToSend: PropTypes.array.isRequired,
  groupsToSend: PropTypes.array.isRequired,

  onUserSelected: PropTypes.func.isRequired,
  onUserRemoved: PropTypes.func.isRequired,
  onSelectActivity: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  onAddRecipient: PropTypes.func.isRequired,
  onGroupRemoved: PropTypes.func.isRequired,
  onActivityRemoved: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,

  selectedActivity: PropTypes.string,
};

CreateMessage.defaultProps = {
  selectedActivity: null,
};

export default CreateMessage;
