import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Alert } from 'reactstrap';
import { Button, TextInput } from '../../../components/index';

import './style.scss';

const CreateNote = ({
  toggle, isOpen, onSubmit, formState, isLoading,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('write_note')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          <TextInput value={I18n.t('heading')} name="title" required />

          <TextInput value={I18n.t('contents')} name="description" type="textarea" rows="4" />
          <FormGroup className="d-flex justify-content-end">
            <Button
              className="rounded-0 fz-24 fw-900"
              color="primary"
              size="lg"
              isLoading={isLoading}
            >
              {I18n.t('create_note')}
            </Button>
          </FormGroup>
          {formState.error && (
            <Alert color="danger">
              <Translate value={formState.error} />
            </Alert>
          )}
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

CreateNote.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CreateNote;
