import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './styles/main.scss';

import Auth from './containers/Authorization/Authorize';
import ProfilesPage from './pages/Profiles/ProfilesPage';
import ProfilePage from './pages/Profile/ProfilePage';
import ActivitiesPage from './pages/Activities/ActivitiesPage';
import ActivityPage from './pages/Activity/ActivityPage';
import StatisticsPage from './pages/Statistics/StatisticsPage';
import ParticipantsPage from './pages/Participants/ParticipantsPage';
import MessagesPage from './pages/Messages/MessagesPage';
import LoginPage from './pages/Login/LoginPage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import MyPage from './pages/MyPage/MyPage';
import NotesPage from './pages/Notes/NotesPage';
import SettingsPage from './pages/Settings/SettingsPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

import MainLayout from './layouts/MainLayout';

import { routeList } from './pages/routes';

const Main = () => (
  <Switch>
    <Route exact path={routeList.HOME} component={Auth(MainLayout(ProfilesPage), ['admin'])} />
    <Route
      exact
      path={routeList.ACTIVITIES}
      component={Auth(MainLayout(ActivitiesPage), ['admin'])}
    />
    <Route
      path={`${routeList.ACTIVITIES}/:id`}
      component={Auth(MainLayout(ActivityPage), ['admin'])}
    />
    <Route path={routeList.STATISTICS} component={Auth(MainLayout(StatisticsPage), ['admin'])} />
    <Route path={routeList.SETTINGS} component={Auth(MainLayout(SettingsPage), ['admin'])} />
    <Route
      path={routeList.PARTICIPANTS}
      component={Auth(MainLayout(ParticipantsPage), ['admin'])}
    />
    <Route path={routeList.MESSAGE} component={Auth(MainLayout(MessagesPage), ['admin'])} />
    <Route path={routeList.MY} component={Auth(MainLayout(MyPage), ['admin'])} />
    <Route
      path={`${routeList.NOTES}/:activityId`}
      component={Auth(MainLayout(NotesPage), ['admin'])}
    />

    <Route path={routeList.LOGIN} component={LoginPage} />
    <Route path={routeList.FORGOT_PASSWORD} component={ForgotPasswordPage} />

    <Route path={`${routeList.USERS}/:id`} component={Auth(MainLayout(ProfilePage), ['admin'])} />
    <Route path={`${routeList.PPIVACYPOLICY}/`} component={PrivacyPolicy} />
    <Route path={`${routeList.PPIVACYPOLICY}/callback`} component={PrivacyPolicy} />
  </Switch>
);

export default Main;
