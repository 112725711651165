import _ from 'lodash';
import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { authTypes, authOperations, authSelectors } from '../../../modules/authorize';
import { loaderSelectors } from '../../../modules/loader';
import { withGetUserProp } from '../../../utils/recompose-extensions/props-selectors';

import Component from './Component';

const { logOut } = authOperations;
const { getLoader } = loaderSelectors;
const { getAutUser } = authSelectors;

const enhancer = compose(
  connect(
    state => ({
      ...getLoader(authTypes.SIGN_OUT)(state),
      user: authSelectors.getAutUser(state),
      userGroups: _.get(getAutUser(state), 'roles'),
    }),
    { logOut }
  ),
  withRouter,
  withGetUserProp,
  withState('isChangePasswordOpen', 'setIsChangePasswordOpen', false),
  withState('isEditProfileOpen', 'setIsEditProfileOpen', false),
  withHandlers({
    onEditProfileToggle: ({ isEditProfileOpen, setIsEditProfileOpen }) => () =>
      setIsEditProfileOpen(!isEditProfileOpen),
    onChangePasswordToggle: ({ isChangePasswordOpen, setIsChangePasswordOpen }) => () =>
      setIsChangePasswordOpen(!isChangePasswordOpen),
    onLogOut: props => () => props.logOut(),
  })
);

export default enhancer(Component);
