import React from 'react';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import Toolbar from './Toolbar';
import CreateNote from '../CreateNote';

const Header = ({ onCreateNotesToggle, isCreateNotesOpen, match }) => (
  <div>
    <Row className="mb-3 pb-2 align-items-end">
      <Col lg={9}>
        <Toolbar match={match} />
      </Col>
      <Col lg={3}>
        <Button
          className="rounded-0 font-weight-bold d-flex justify-content-between
            align-items-center default-box-shadow"
          size="lg"
          color="primary"
          block
          onClick={onCreateNotesToggle}
        >
          <span>{I18n.t('write_note')}</span>
        </Button>
      </Col>
    </Row>
    {isCreateNotesOpen && <CreateNote toggle={onCreateNotesToggle} isOpen={isCreateNotesOpen} />}
  </div>
);

Header.propTypes = {
  match: PropTypes.object.isRequired,
  onCreateNotesToggle: PropTypes.func.isRequired,
  isCreateNotesOpen: PropTypes.bool.isRequired,
};

export default Header;
