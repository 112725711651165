import { get } from 'lodash';
import {
  compose,
  withHandlers,
  lifecycle,
  withProps,
  withState,
  withPropsOnChange,
} from 'recompose';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { routeList } from '../../pages/routes';
import { settingsOperations, settingsTypes } from '../../modules/settings';
import Component from './Component';
import { authSelectors, authOperations, authTypes } from '../../modules/authorize';
import { isOver13 } from '../../utils/models/users';

const isAdmin = user =>
  !![authTypes.ADMIN, authTypes.SUPER_ADMIN].find(role => user.roles.includes(role));

const enhancer = compose(
  connect(
    state => ({
      user: authSelectors.getAutUser(state),
      isLoading:
        !!get(state, ['loader', authTypes.AUTHORIZE]) ||
        !!get(state, ['loader', settingsTypes.FETCH_SETTINGS]),
    }),
    {
      fetchSetting: settingsOperations.fetchSetting,
      authorize: authOperations.authorize,
      updateMy: authOperations.updateMy,
    }
  ),
  withState('message', 'setMessage', ''),
  withState('token', 'setToken', ''),
  withState('isOver13', 'setIsOver13', true),
  withProps(props => ({
    setPrivacyPolicyMessage: (user, token) => {
      const over13 = isAdmin(user) || isOver13(user);
      const role = isAdmin(user) ? 'volunteer' : user.roles[0];
      props.setIsOver13(over13);
      props
        .fetchSetting(settingsTypes.GDRP, over13 ? role : settingsTypes.UNDER_13, token)
        .then(setting => props.setMessage(get(setting, 'description')));
    },
  })),
  withHandlers({
    onConfirm: props => accept => (e) => {
      e.preventDefault();
      props.updateMy({ isAgreeGDPR: accept }, props.token).then((user) => {
        const search = user.isAgreeGDPR ? 'success=true' : 'reject=true';
        // for mobile app
        window.location = `${routeList.PPIVACYPOLICY}/callback?${search}`;
      });
    },
  }),
  withPropsOnChange(['location'], (props) => {
    const { success, reject } = queryString.parse(props.location.search);
    if (success || reject) {
      props.history.push(routeList.HOME);
    }
    return true;
  }),
  lifecycle({
    componentDidMount() {
      const { location } = this.props;
      const { token } = queryString.parse(location.search);
      this.props.setToken(token);

      this.props.authorize(token).then((response) => {
        this.props.setPrivacyPolicyMessage(response.user);
      });
    },
  })
);

export default enhancer(Component);
