import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { USER, VOLUNTEER, FEMALE, MALE } from '../authorize/types';
import { actionsSelectors } from '../actions';
import { usersTypes } from '../users';

const statisticsSelector = state => state.statistics;

const getStatistics = createSelector(
  statisticsSelector,
  statistics => statistics
);

const getStatisticsArray = createSelector(
  statisticsSelector,
  statistics => statistics.keys.map(key => statistics.values[key])
);

const getSortedStatistics = createSelector(
  getStatisticsArray,
  statistics => _.sortBy(statistics, 'timestamp')
);

const periodMaps = {
  day: 'day',
  month: 'month',
  year: 'year',
};

const getGroupedStatistics = createSelector(
  getSortedStatistics,
  actionsSelectors.getFilter(usersTypes.USER_STATISTICS),
  (statistics, { filter }) => {
    const { from, to } = filter || {};
    const min = from || _.get(statistics, '0.timestamp');
    const max = to || _.get(statistics, `${statistics.length - 1}.timestamp]`);
    const minDate = moment(min);
    const maxDate = moment(max);
    const dayBetween = maxDate.diff(moment(minDate), 'd');
    let groupBy = periodMaps.day;

    if (dayBetween + 1 > moment(min).daysInMonth()) {
      groupBy = (dayBetween > 365 && periodMaps.year) || periodMaps.month;
      const grouped = _.groupBy(statistics, s => moment(s.timestamp)[groupBy]());
      const argued = _.keys(grouped).map(key => ({
        timestamp: moment(grouped[key][0].timestamp),
        // timestamp: moment()[groupBy](key),
        payload: grouped[key][grouped[key].length - 1].payload,
      }));
      return {
        statistics: argued,
        groupBy,
      };
    }
    return {
      statistics,
      groupBy,
    };
  }
);

const getStatisticsAsXY = createSelector(
  getGroupedStatistics,
  ({ statistics, groupBy }) => {
    const mapToXY = field => statistics.map(s => ({ x: moment(s.timestamp), y: _.get(s, field) }));
    const statisticsAsXY = {
      groupBy,
      [USER]: mapToXY(`payload.${USER}`),
      [VOLUNTEER]: mapToXY(`payload.${VOLUNTEER}`),
      [`${USER}-${VOLUNTEER}`]: mapToXY(`payload.${USER}-${VOLUNTEER}`),
      [`${USER}-${FEMALE}`]: mapToXY(`payload.${USER}-${FEMALE}`),
      [`${USER}-${MALE}`]: mapToXY(`payload.${USER}-${MALE}`),
      [`${VOLUNTEER}-${FEMALE}`]: mapToXY(`payload.${VOLUNTEER}-${FEMALE}`),
      [`${VOLUNTEER}-${MALE}`]: mapToXY(`payload.${VOLUNTEER}-${MALE}`),
    };
    return { statisticsAsXY };
  }
);

export default {
  getStatistics,
  getStatisticsAsXY,
};
