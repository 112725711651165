const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const SET_USER = 'SET_USER';
const AUTHORIZE = 'AUTHORIZE';
const UPDATE_MY = 'UPDATE_MY';

export const ADMIN = 'admin';
export const SUPER_ADMIN = 'super-admin';
export const USER = 'user';
export const VOLUNTEER = 'volunteer';
export const FEMALE = 'female';
export const MALE = 'male';

const ROLES_TRANSLATE = {
  [ADMIN]: 'admin',
  [SUPER_ADMIN]: 'super_admin',
  [USER]: 'user',
  [VOLUNTEER]: 'volunteer',
};

export default {
  ADMIN,
  SUPER_ADMIN,
  USER,
  VOLUNTEER,
  SIGN_IN,
  RESTORE_PASSWORD,
  SET_USER,
  SIGN_OUT,
  AUTHORIZE,
  CHANGE_PASSWORD,
  UPDATE_MY,
  ROLES_TRANSLATE,
};
