import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import 'flatpickr/dist/themes/light.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Common from './fields/Common';
import Admin from './fields/Admin';
import Volunteer from './fields/Volunteer';
import User from './fields/User';
import Button from '../../components/Button';

import ApproveAreaComponent from './Areas/ApproveAreaComponent';
import SaveArea from './Areas/SaveArea';

import './style.scss';

const roleComponent = (role, params) => {
  switch (role) {
    case 'admin':
    case 'super-admin':
      return <Admin {...params} />;
    case 'user':
      return <User {...params} />;
    case 'volunteer':
      return <Volunteer {...params} />;
    default:
      return null;
  }
};

const attachFileLabel = role =>
  role === 'user' ? 'attach_a_file_user' : 'attach_a_file_volunteer';

const EditProfile = ({
  isLoading,
  formState,
  getUserProp,
  user,
  isOpen,
  toggle,
  onSubmit,
  onFileSelect,
  avatar,
  onAttachFile,
  onRemoveAttachment,
  attachments,
  onRemoveProfile,
  onToggleApprove,
  onAddFileButtonClick,
  onCheck,
  userAttachments,
  onRemoveUserAttachment,
  formData,
  changeFormData,
  onFieldChange,
}) => (
  <div>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('profile')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          <Common
            {...{
              formData,
              formState,
              changeFormData,
              user,
              avatar,
              onFileSelect,
              getUserProp,
              onFieldChange,
            }}
          />

          {roleComponent(formData.userRole, {
            formData,
            formState,
            changeFormData,
            user,
            getUserProp,
            onFieldChange,
          })}

          <FormGroup>
            <Button
              isLoading={isLoading}
              className="rounded-0 fz-18 fw-900 d-flex justify-content-between"
              color="primary"
              size="lg"
              onClick={onAddFileButtonClick}
            >
              {I18n.t(attachFileLabel(formData.userRole))}
              <FontAwesomeIcon className="ml-2" icon={faUpload} />
            </Button>
            <Input
              id="select-file"
              className="d-none"
              type="file"
              name="file"
              accept=".pdf"
              multiple
              invalid={!!_.get(formState, 'errors.file.message')}
              onChange={onAttachFile('file')}
            />
            <FormFeedback>
              <Translate value={_.get(formState, 'errors.file.message') || ''} />
            </FormFeedback>
          </FormGroup>
          <hr className="separator mt-4 mb-4" />
          <ul className="d-inline-block files-list m-0 p-0">
            {attachments.map((attachment, index) => (
              // eslint-disable-next-line
              <li key={index} className="d-flex justify-content-between align-items-center">
                <a href={null} onClick={event => event.preventDefault()}>
                  {attachment.name}
                </a>
                <FontAwesomeIcon
                  className="ml-2 cursor-pointer"
                  icon={faTrashAlt}
                  onClick={onRemoveAttachment(index)}
                />
              </li>
            ))}
            {userAttachments.map((attachment, index) => (
              // eslint-disable-next-line
              <li key={index} className="d-flex justify-content-between align-items-center">
                <a href={null} onClick={event => event.preventDefault()}>
                  {attachment.name}
                </a>
                <FontAwesomeIcon
                  className="ml-2 cursor-pointer"
                  icon={faTrashAlt}
                  onClick={onRemoveUserAttachment(index)}
                />
              </li>
            ))}
          </ul>
          <hr className="separator mt-4 mb-4" />

          <ApproveAreaComponent
            formData={formData}
            onCheck={onCheck}
            isLoading={isLoading}
            onToggleApprove={onToggleApprove}
          />

          <SaveArea formData={formData} isLoading={isLoading} onRemoveProfile={onRemoveProfile} />

          <FormGroup>
            {formData.isApprovedMessage && (
              <Alert color="success">
                <Translate value={(formData.isApproved && 'user_approved') || 'user_unapproved'} />
              </Alert>
            )}
          </FormGroup>
          <FormGroup>
            {formState.error && (
              <Alert color="danger">
                <Translate value={formState.error} />
              </Alert>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

EditProfile.propTypes = {
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  avatar: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  formState: PropTypes.object.isRequired,
  getUserProp: PropTypes.func.isRequired,
  onAttachFile: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onRemoveUserAttachment: PropTypes.func.isRequired,
  onRemoveProfile: PropTypes.func.isRequired,
  onAddFileButtonClick: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  attachments: PropTypes.array.isRequired,
  userAttachments: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  changeFormData: PropTypes.func.isRequired,
  onToggleApprove: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

EditProfile.defaultProps = {
  avatar: null,
};

export default EditProfile;
