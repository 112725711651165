import { uniq, get } from 'lodash';
import types from './types';

const defaultState = { keys: [], values: {}, count: 0 };
const defaultStateUser = {};

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_USERS: {
      const keys = uniq([...state.keys, ...action.users.keys]);
      return {
        keys,
        count: action.count,
        values: { ...state.values, ...action.users.values },
      };
    }
    case types.ADD_USER:
    case types.CREATE_USER: {
      const _id = get(action, 'user._id');
      const keys = uniq([_id, ...state.keys]);
      return _id
        ? {
          ...state,
          keys,
          values: { ...state.values, [_id]: action.user },
        }
        : state;
    }
    case types.CLEAR_USERS:
      return defaultState;
    default:
      return state;
  }
};

const userReducer = (state = defaultStateUser, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_USER: {
      return {
        ...state,
        ...action.user.user,
      };
    }
    case types.FETCH_USER_GROUPS: {
      return {
        ...state,
        groups: action.groups.groups,
      };
    }
    case types.CLEAR_USER: {
      return {
        _id: state._id,
      };
    }
    default:
      return state;
  }
};

export { usersReducer, userReducer };
