import _ from 'lodash';
import React from 'react';
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withState,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';

import { authOperations, authTypes, authSelectors } from '../../../modules/authorize';
import { initSockets } from '../../../modules/sockets';
import { getAuthToken } from '../../../utils/api/authorization';
import { loaderSelectors } from '../../../modules/loader';
import { policySelectors, policyOperations } from '../../../modules/privacy-policy';
import { routeList } from '../../../pages/routes';
import ChangePasswordModal from '../ChangePasswordModal/index';

const { AUTHORIZE } = authTypes;

/**
 * Provide authorization for routers
 *
 * @param {object} React Component
 * @param {Array} allowed roles
 * @param {Sting } path to redirect or React Component
 *
 * @example
 *  import auth from '..../requireAuthentication';
 *
 *  <Route path="/my" component={Auth(Profile, ['admin'], '/login')} />
 *
 * */

const isAllow = (authorize, roles) => {
  const { user } = authorize;
  if (!user) {
    return false;
  }
  if (_.includes(roles, '*') || _.includes(user.roles, 'super-admin')) {
    return true;
  }
  if (!roles.find(role => _.includes(user.roles, role))) {
    return false;
  }
  return true;
};

const userHasTemporaryPassword = ({ authorize }) =>
  !!_.get(authorize, ['user', 'isTemporaryPassword']);

const Auth = (Component, roles = [], path = routeList.LOGIN) => {
  // eslint-disable-next-line react/prop-types
  const AuthenticatedComponent = ({ isLoading }) => !isLoading && <Component />;
  const enhance = compose(
    connect(
      state => ({
        ...authSelectors.getAuthorize(state),
        ...loaderSelectors.getLoader(AUTHORIZE),
        ...policySelectors.getPrivacyPolicy(state),
      }),
      { auth: authOperations.authorize, initSockets, ...policyOperations }
    ),
    branch(
      userHasTemporaryPassword,
      renderComponent(() => <ChangePasswordModal isOpen isTemporary />)
    ),
    withState('isLoaded', 'setIsLoaded', false),
    withPropsOnChange(
      // <- check if users change
      ['authorize', 'isPrivacyPolicyAccepted'],
      (props) => {
        if (props.isLoaded && !isAllow(props.authorize, roles)) {
          props.history.push(path);
        }
        if (props.isLoaded && !props.isPrivacyPolicyAccepted) {
          props.history.push(routeList.PPIVACYPOLICY);
        }
        if (props.isLoaded && !_.get(props, 'authorize.user.isAgreeGDPR')) {
          props.rejecyPrivacyPolicy();
        }
        return props;
      }
    ),
    lifecycle({
      componentWillMount() {
        const { user } = this.props.authorize;
        if (!getAuthToken()) {
          return this.props.history.push(path);
        }
        this.props.initSockets(getAuthToken());
        if (!user) {
          return this.props.auth().catch(() => this.props.history.push(path));
        }
        if (!isAllow(this.props.authorize, roles)) {
          this.props.history.push(path);
        }

        return null;
      },
      componentDidMount() {
        this.props.setIsLoaded(() => true);
      },
    })
  );
  return enhance(AuthenticatedComponent);
};

export default Auth;
