export default {
  SIGN_IN: '/api/v1/auth/sign-in',
  SIGN_OUT: '/api/v1/auth/sign-out',
  RESTORE_PASSWORD: '/api/v1/auth/restore-password',
  CHANGE_PASSWORD: '/api/v1/auth/change-password',
  GET_ACCOUNT: '/api/v1/auth/my',
  UPDATE_MY: '/api/v1/auth/my',
  GET_IMAGE: '/api/v1/images/',
  FILES: '/api/v1/files/',
  CREATE_ACTIVITY: '/api/v1/activities',
  ACTIVITIES: '/api/v1/activities/',
  USERS: '/api/v1/users/',
  GROUPS: '/api/v1/groups/',
  NOTES: '/api/v1/notes/',
  MESSAGES: '/api/v1/messages/',
  SETTINGS: '/api/v1/settings/',
  STATISTICS: '/api/v1/statistics/',
  LESSONS: '/api/v1/lessons/',
};
