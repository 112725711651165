import React from 'react';
import { Container } from 'reactstrap';
import Header from './Header';
import Graph from './Graph';
import ConversionButtons from './ConversionButtons';

const Statistics = () => (
  <div>
    <Container>
      <Header />
      <Graph />
      <ConversionButtons />
    </Container>
  </div>
);

export default Statistics;
