import { compose, withState, lifecycle, withHandlers } from 'recompose';
import { Buffer } from 'buffer';

import { getFile, APIAddresses } from '../../utils/api/index';
import Component from './Component';

const enhancer = compose(
  withState('isLoading', 'setIsLoading', false),
  withState('src', 'setSrc', ''),
  withHandlers(() => {
    let img = null;
    return {
      loadImage: ({ setSrc, setIsLoading }) => (imageId) => {
        setIsLoading(true);
        getFile(`${APIAddresses.GET_IMAGE}${imageId}`)
          .then((response) => {
            const str = new Buffer(response.data, 'binary').toString('base64');
            setSrc(`data:${response.headers['content-type']};base64,${str}`);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(true);
            setSrc('');
          });
      },
      registerImgRef: () => (ref) => {
        img = ref;
      },
      renderImage: () => (file) => {
        if (file && img) {
          const reader = new FileReader();
          reader.onload = e => img && img.setAttribute('src', e.target.result);
          reader.readAsDataURL(file);
        }
      },
    };
  }),

  lifecycle({
    componentWillMount() {
      const { imageId } = this.props;
      if (imageId) {
        this.props.loadImage(imageId);
      }
    },
    componentWillUpdate(newProps) {
      const { imageId } = this.props;
      if (imageId !== newProps.imageId) {
        this.props.loadImage(newProps.imageId);
      }
      if (newProps.image && newProps.image.size !== this.props.size) {
        this.props.renderImage(newProps.image);
      }
    },
    componentDidMount() {
      const { image } = this.props;
      if (image instanceof File) {
        this.props.renderImage(image);
      }
    },
  })
);

export default enhancer(Component);
