import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withGetUserProp } from '../../utils/recompose-extensions/props-selectors';
import { usersOperations, usersSelectors } from '../../modules/users';
import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...usersSelectors.getUser(state),
    }),
    {
      fetchUser: usersOperations.fetchActiveUser,
      clearUser: usersOperations.clearUser,
      fetchUserGroups: usersOperations.fetchUserGroups,
    }
  ),
  withRouter,
  withGetUserProp,
  withState('isChangePasswordOpen', 'setIsChangePasswordOpen', false),
  withState('isEditProfileOpen', 'setIsEditProfileOpen', false),
  withHandlers({
    onEditProfileToggle: ({ isEditProfileOpen, setIsEditProfileOpen }) => () =>
      setIsEditProfileOpen(!isEditProfileOpen),
  }),
  lifecycle({
    componentWillMount() {
      const { match, fetchUser, fetchUserGroups } = this.props;
      fetchUser(match.params.id);
      fetchUserGroups(match.params.id, 0);
    },
    componentDidMount() {
      window.scrollTo(0, 0);
    },
    componentWillUnmount() {
      this.props.clearUser();
    },
  })
);

export default enhancer(Component);
