import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { compose, withState, withHandlers, withProps, lifecycle } from 'recompose';
import EditProfileComponents from './Component';
import { authOperations, authSelectors, authTypes } from '../../modules/authorize';
import { usersOperations } from '../../modules/users';
import { jsonToFormData } from '../../utils/jsonToFormData';
import { loaderSelectors } from '../../modules/loader';
import { findEmptyString } from '../../utils/helpers';
import { PHONE, EMAIL } from '../../utils/regexes';
import { routeList } from '../../pages/routes';
import { isOver13 } from '../../utils/models/users';

import {
  withFormState,
  withFormErrorHandler,
  withClearFormErrors,
  withFormReducer,
  withSetFieldState,
} from '../../utils/recompose-extensions/form';
import { withGetUserProp } from '../../utils/recompose-extensions/props-selectors';

const regexMaps = {
  email: EMAIL,
  phone: PHONE,
  firstParentPhone: PHONE,
  secondParentPhone: PHONE,
};

const allowedType = 'application/pdf';
const maxFileSize = 1024 * 1024 * 8; // 8mb;

const mapStateToProps = state => ({
  ...loaderSelectors.getLoader(authTypes.UPDATE_MY)(state),
  authUser: authSelectors.getAutUser(state),
});

const mapDispatchToProps = {
  updateMy: authOperations.updateMy,
  updateUser: usersOperations.updateUser,
  createUser: usersOperations.createUser,
  removeUser: usersOperations.removeUser,
  approveUser: usersOperations.approveUser,
  deactivateUser: usersOperations.deactivateUser,
  fetchFiles: usersOperations.fetchFiles,
};

const createRequestObject = (array) => {
  const resultObject = {};
  array.forEach((e, i) => _.extend(resultObject, { [i]: e }));
  return resultObject;
};

const enhancer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withGetUserProp,
  withFormState(),
  withFormErrorHandler({
    'A user with the given username is already registered': {
      field: 'email',
      message: 'email_exists_message',
    },
  }),
  withSetFieldState,
  withClearFormErrors,
  withState('formData', 'setFormData', (props) => {
    const birthDate = _.get(props.user, ['profile', 'birthDate']);
    return {
      userRole: _.get(props.user, ['roles', '0']) || 'admin',
      birthDate: birthDate ? new Date(birthDate) : undefined,
      gender: _.get(props.user, ['profile', 'gender']) || 'male',
      documentApproval: _.get(props.user, 'documentApproval') || {},
      isSelfEditMode: _.get(props, 'user._id') === _.get(props, 'authUser._id'),
      isCreateMode: !_.get(props, 'user._id'),
      isApproved: !!_.get(props.user, 'approved'),
      isApprovedMessage: false,
      isActiveMessage: false,
      isAgreeGDPR: _.get(props.user, 'isAgreeGDPR') || false,
      isAgreeGDPRDisable: isOver13(props.user),
      isActive: !!_.get(props.user, 'active'),
    };
  }),
  withProps(props => ({
    changeFormData: (name, value) => props.setFormData({ ..._.set(props.formData, name, value) }),
    validateForm: (formState) => {
      const { errors } = formState;
      const getValidStatus = key => _.isBoolean(errors[key].valid) && !errors[key].valid;
      const isInvalid = _.keys(errors).find(getValidStatus);

      if (isInvalid) {
        props.setFormState({ ...props.formState, error: 'fill_all_fields' });
        return false;
      }
      return true;
    },
  })),
  withState('avatar', 'setAvatar', null),
  withState('attachments', 'setAttachments', []),
  withState('userAttachments', 'setUserAttachments', []),
  withState('filesToRemove', 'setFilesToRemove', []),
  withFormReducer,
  withHandlers({
    onFieldChange: props => name => ({ target: { value } } = {}) => {
      const trimmedValue = `${value}`.trim();
      const isEmpty = findEmptyString({ [name]: trimmedValue });
      if (isEmpty) {
        return props.setFieldState(name, false, 'fill_in_the_field');
      }

      if (_.keys(regexMaps).includes(name)) {
        if (!regexMaps[name].test(trimmedValue)) {
          return props.setFieldState(name, false, `${name}_regex_validation_message`);
        }
      }
      return props.setFieldState(name, true);
    },
    onCheck: ({ changeFormData }) => field => e => changeFormData(field, e.target.checked),
    onRemoveProfile: props => () =>
      props
        .removeUser(props.user._id)
        .then(() => props.history.push(routeList.HOME))
        .catch(props.catchFormError),
    onToggleApprove: props => fieldName => () => {
      const method = fieldName === 'isApproved' ? props.approveUser : props.deactivateUser;
      method(props.user._id, !props.formData[fieldName])
        .then(() => {
          props.changeFormData(fieldName, !props.formData[fieldName]);
          props.changeFormData(`is${fieldName}Message`, true);
        })
        .catch(props.catchFormError);
    },
    onAddFileButtonClick: () => () => document.getElementById('select-file').click(),
    onFileSelect: ({ setAvatar }) => event => setAvatar(event.target.files[0]),
    onAttachFile: ({
      attachments,
      setAttachments,
      setFieldState,
      setFormState,
      formState,
    }) => name => ({ target: { files } }) => {
      const failFileExt = _.range(0, files.length).find(idx => files[idx].type !== allowedType);
      const reducer = (acc, idx) => acc && files[idx].size > maxFileSize;
      const failFileSize = _.range(0, files.length).reduce(reducer, true);

      if (failFileSize) {
        return setFormState({ ...formState, error: 'max_file_size' });
      }

      if (failFileExt) {
        return setFieldState(name, false, 'only_pdf_message');
      }
      setFieldState(name, true, '');
      return setAttachments([...attachments, ...files]);
    },
    onRemoveAttachment: ({ attachments, setAttachments }) => index => () =>
      setAttachments([...attachments.slice(0, index), ...attachments.slice(index + 1)]),
    onRemoveUserAttachment: props => index => () => {
      const { userAttachments, filesToRemove } = props;
      const attachment = userAttachments[index];
      props.setFilesToRemove([...filesToRemove, attachment._id]);
      props.setUserAttachments([
        ...userAttachments.slice(0, index),
        ...userAttachments.slice(index + 1),
      ]);
    },
    onSubmit: props => async (event) => {
      event.preventDefault();
      const {
        avatar, user, attachments, filesToRemove, formData, formState,
      } = props;
      props.clearFormErrors();
      const reducedData = props.reduceForm(event.target);

      const userData = {
        email: reducedData.email,
        profile: {
          ...reducedData,
          gender: formData.gender,
          birthDate: formData.birthDate || '', // to avoid replace null to 'null' by FormData
        },
        documentApproval: formData.documentApproval,
        role: formData.userRole,
        isAgreeGDPR: formData.isAgreeGDPR,
        avatar,
      };

      if (!props.validateForm(formState)) {
        return null;
      }

      const attachmentsObject = createRequestObject(attachments);
      const filesToRemoveObject = createRequestObject(filesToRemove);

      if (!formData.isSelfEditMode) {
        _.extend(userData, { attachments: attachmentsObject, filesToRemove: filesToRemoveObject });
      }
      const requestData = new FormData();
      jsonToFormData(userData, {}, requestData);
      try {
        if (formData.isCreateMode) {
          await props.createUser(requestData);
        }
        if (formData.isSelfEditMode) {
          await props.updateMy(requestData);
        }
        if (!formData.isSelfEditMode && !formData.isCreateMode) {
          // ========
          await props.updateUser(user._id, requestData);
          // ========
        }
        return props.toggle();
      } catch (error) {
        return props.catchFormError(error);
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      const { user } = this.props;
      if (user._id) {
        this.props.fetchFiles(user.attachments).then(this.props.setUserAttachments);
      }
    },
  })
);

export default enhancer(EditProfileComponents);
