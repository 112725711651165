import types from './types';

export const setUser = user => ({
  value: user,
  type: types.SET_USER,
});
export const unsetUser = user => ({
  value: user,
  type: types.SIGN_OUT,
});
export const passwordChange = () => ({
  type: types.CHANGE_PASSWORD,
});
