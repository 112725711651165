import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import { I18n } from 'react-i18nify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { UserAutosuggest } from '../../../../components/index';

const VolunteerSelector = ({
  volunteers, onRemoveVolunteer, onAddVolunteer, getUserProp,
}) => (
  <Row>
    <Col md={12}>
      <FormGroup>
        <Label className="fz-18 fw-500">{I18n.t('select_volunteer')}</Label>
        <UserAutosuggest onSelected={onAddVolunteer} type="volunteer" clearAfterSelect />
      </FormGroup>
    </Col>
    <Col md={12}>
      <FormGroup>
        {volunteers.map(volunteer => (
          <p className="fz-18" key={volunteer._id}>
            <FontAwesomeIcon
              className="mr-2 cursor-pointer"
              icon={faTrash}
              onClick={onRemoveVolunteer(volunteer._id)}
            />
            {getUserProp(volunteer, 'firstName')} - {getUserProp(volunteer, 'lastName')}
          </p>
        ))}
      </FormGroup>
    </Col>
  </Row>
);

VolunteerSelector.propTypes = {
  getUserProp: PropTypes.func.isRequired,
  volunteers: PropTypes.array.isRequired,
  onAddVolunteer: PropTypes.func.isRequired,
  onRemoveVolunteer: PropTypes.func.isRequired,
};

export default VolunteerSelector;
