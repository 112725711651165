import _ from 'lodash';
import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { lessonsSelectors, lessonsOperations, lessonsTypes } from '../../../modules/lessons';
import { actionsSelectors } from '../../../modules/actions';
import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const fields = {
  group: {
    title: 1,
  },
  activity: {
    title: 1,
  },
};

const enhancer = compose(
  connect(
    state => ({
      ...loaderSelectors.getLoader(lessonsTypes.FETCH_LESSONS)(state),
      ...lessonsSelectors.getLessons(state),
      ...actionsSelectors.getFilter(lessonsTypes.LESSONS)(state),
    }),
    {
      fetchLessons: lessonsOperations.fetchLessons,
      clearLessons: lessonsOperations.clearLessons,
    }
  ),
  withProps({ PER_PAGE: 25 }),
  withHandlers({
    onFetch: ({
      fetchLessons, lessons, PER_PAGE, filter,
    }) => () =>
      fetchLessons(lessons.keys.length, PER_PAGE, filter, fields),
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchLessons(0, this.props.PER_PAGE, {}, fields);
    },
    componentWillUpdate(newProps) {
      if (!_.isEqual(newProps.filter, this.props.filter)) {
        const { PER_PAGE, isLoading, filter } = newProps;
        this.props.clearLessons();
        if (!isLoading) {
          this.props.fetchLessons(0, PER_PAGE, filter, fields);
        }
      }
    },
    componentWillUnmount() {
      this.props.clearLessons();
    },
  })
);

export default enhancer(Component);
