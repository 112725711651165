import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { activityOperations } from '../../../modules/activity';
import { usersOperations } from '../../../modules/users';
import Component from './Component';

const enhancer = compose(
  connect(() => ({}), {
    fetchActivityStatisticCount: activityOperations.fetchActivityStatisticCount,
    fetchUserStatisticsCount: usersOperations.fetchUserStatisticsCount,
  }),
  withState('activitiesCount', 'setActivitiesCount', {}),
  withState('usersCount', 'setUsersCount', {}),
  lifecycle({
    componentWillMount() {
      this.props
        .fetchActivityStatisticCount()
        .then(activities => this.props.setActivitiesCount(activities));
      this.props.fetchUserStatisticsCount().then(users => this.props.setUsersCount(users));
    },
  })
);

export default enhancer(Component);
