import { lifecycle, compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { usersSelectors, usersOperations, usersTypes } from '../../../../modules/users';
import { statisticsOperations } from '../../../../modules/statistics';
import { lessonsSelectors } from '../../../../modules/lessons';
import { actionsSelectors } from '../../../../modules/actions';
import { statisticsAsXYToDataChart, statisticsAsXYToOptions } from './chart-js-config';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...usersSelectors.getUser(state),
      ...actionsSelectors.getFilter(usersTypes.USER_STATISTICS)(state),
      ...lessonsSelectors.getStatisticsAsXY(state),
    }),
    {
      fetchUserStatistics: usersOperations.fetchUserStatistics,
      clearStatistics: statisticsOperations.clearStatistics,
    }
  ),
  withProps({
    statisticsAsXYToDataChart,
    statisticsAsXYToOptions,
  }),
  withProps({
    fetch: async (props) => {
      const { filter, user } = props;
      props.clearStatistics();
      await props.fetchUserStatistics(user._id, filter);
    },
  }),
  lifecycle({
    async componentWillMount() {
      await this.props.fetch(this.props);
    },
    async componentWillUpdate(newProps) {
      if (!_.isEqual(newProps.filter, this.props.filter)) {
        await this.props.fetch(newProps);
      }
    },
  })
);

export default enhancer(Component);
