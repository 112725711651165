import { Throttle } from 'react-throttle';
import React from 'react';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';

import { Row, Col, FormGroup, Input, Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlusSquare } from '@fortawesome/free-solid-svg-icons/index';

import CreateActivity from '../CreateActivity/';

const Header = ({ onSearch, onCreateActivityToggle, isCreateActivityOpen }) => (
  <div>
    <Row className="mb-3 pb-2">
      <Col lg={9}>
        <FormGroup className="mb-2 mb-lg-0">
          <Label className="input-customize w-100">
            <FontAwesomeIcon icon={faSearch} />
            <Throttle time="500" handler="onChange">
              <Input
                className="form-control rounded-0 font-weight-bold w-100"
                type="text"
                bsSize="lg"
                onChange={onSearch}
              />
            </Throttle>
          </Label>
        </FormGroup>
      </Col>
      <Col lg={3}>
        <Button
          className="rounded-0 font-weight-bold d-flex justify-content-between
                align-items-center"
          size="lg"
          color="primary"
          block
          onClick={onCreateActivityToggle}
        >
          <span>{I18n.t('add_activity')}</span>
          <FontAwesomeIcon icon={faPlusSquare} />
        </Button>
      </Col>
    </Row>
    {isCreateActivityOpen && (
      <CreateActivity toggle={onCreateActivityToggle} isOpen={isCreateActivityOpen} />
    )}
  </div>
);

Header.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onCreateActivityToggle: PropTypes.func.isRequired,
  isCreateActivityOpen: PropTypes.bool.isRequired,
};

export default Header;
