import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Graph from './Graph';
import Header from './Header';

const Statistics = ({ activity } = {}) => (
  <div>
    <h2>
      {I18n.t('statistics_on')} {activity.title}
    </h2>
    <Header />
    <div className="mt-4">
      <Graph />
    </div>
  </div>
);

Statistics.propTypes = {
  activity: PropTypes.shape({ title: PropTypes.string.isRequired }).isRequired,
};

export default Statistics;
