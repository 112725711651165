import { compose, lifecycle, withHandlers, withState } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import { lessonsOperations } from '../../../../modules/lessons/index';
import Component from './Component';

const toPercent = (statistic) => {
  const percentValue = _.round(
    _.subtract(100, _.multiply(_.divide(statistic.visited, statistic.count), 100)),
    1
  );
  return !_.isNaN(percentValue) ? _.toString(percentValue) : '0';
};

const enhancer = compose(
  connect(() => ({}), {
    fetchLessonStatistic: lessonsOperations.fetchLessonStatistic,
  }),
  withState('statistic', 'setStatistic', {}),
  withState('percent', 'setPercent', ''),
  withHandlers(() => ({
    loadStatistic: ({ setStatistic, fetchLessonStatistic, setPercent }) => (userId, scheduleId) => {
      fetchLessonStatistic(userId, scheduleId).then((response) => {
        setStatistic(response);
        setPercent(toPercent(response));
      });
    },
  })),
  lifecycle({
    componentWillMount() {
      const { userId, schedule } = this.props;
      if (userId) {
        this.props.loadStatistic(userId, schedule._id);
      }
    },
  })
);

export default enhancer(Component);
