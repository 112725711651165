import { uniq, omit } from 'lodash';
import types from './types';

const defaultStateNotes = { keys: [], values: {}, count: 0 };

const notesReducer = (state = defaultStateNotes, action) => {
  switch (action.type) {
    case types.FETCH_NOTES: {
      const keys = uniq([...state.keys, ...action.notes.keys]);
      return {
        keys,
        count: action.count,
        values: { ...state.values, ...action.notes.values },
      };
    }
    case types.REMOVE_NOTE: {
      return {
        keys: state.keys.filter(key => key !== action.id),
        count: state.count - 1,
        values: omit(state.values, [action.id]),
      };
    }
    case types.ADD_NOTE:
    case types.FETCH_NOTE:
    case types.CREATE_NOTE: {
      const keys = uniq([action.note._id, ...state.keys]);

      return {
        ...state,
        keys,
        values: { ...state.values, [action.note._id]: action.note },
      };
    }
    case types.CLEAR_NOTES:
      return defaultStateNotes;
    default:
      return state;
  }
};

export { notesReducer };
