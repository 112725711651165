import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

const Graph = ({ statisticsAsXY, statisticsAsXYToDataChart, statisticsAsXYToOptions }) => (
  <Row>
    <Col lg={12}>
      <Bar
        data={statisticsAsXYToDataChart(statisticsAsXY)}
        options={statisticsAsXYToOptions(statisticsAsXY)}
        height={500}
      />
    </Col>
  </Row>
);

Graph.propTypes = {
  statisticsAsXYToDataChart: PropTypes.func.isRequired,
  statisticsAsXYToOptions: PropTypes.func.isRequired,
  statisticsAsXY: PropTypes.object.isRequired,
};

export default Graph;
