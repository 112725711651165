import Container from './Container';

/**
 *  Provide Image component to render image on screen
 *  @params
 *         imageId - {String} - id of image
 *         image - {File} - to render selected image from client
 *  @example
 *          <Image image={imageId} image={image}/>
 *
 * */

export default Container;
