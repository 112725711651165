import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flatpickr from 'react-flatpickr';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Danish } from 'flatpickr/dist/l10n/da.js';
import { TextInput } from '../../../components';

const User = ({
  formData, changeFormData, user, getUserProp, onFieldChange, formState,
}) => (
  <Fragment>
    <TextInput
      value={I18n.t('nickname')}
      name="patronymic"
      defaultValue={getUserProp(user, 'patronymic')}
    />
    <FormGroup>
      <Label className="fz-18 fw-500">{I18n.t('date_of_birth')}*</Label>
      <div className="d-flex row-btns">
        <Flatpickr
          className="position-relative calendar-field w-100 default-box-shadow"
          value={formData.birthDate}
          onChange={date => date.length && changeFormData('birthDate', date)}
          options={{
            locale: Danish,
            dateFormat: 'D d/m/Y',
            defaultDate: 't 2018/10/10',
            maxDate: new Date(),
            wrap: true,
          }}
        >
          <input className="w-100" type="text" data-input />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
    </FormGroup>
    <TextInput
      value={I18n.t('recreation')}
      name="hobby"
      defaultValue={getUserProp(user, 'hobby')}
    />
    <TextInput
      value={`${I18n.t('school')}*`}
      name="school"
      defaultValue={getUserProp(user, 'school')}
      required
      onChange={onFieldChange('school')}
      message={_.get(formState, 'errors.school.message') || ''}
    />
    <TextInput
      value={`${I18n.t('mobile_number')}*`}
      name="phone"
      defaultValue={getUserProp(user, 'phone')}
      required
      onChange={onFieldChange('phone')}
      message={_.get(formState, 'errors.phone.message') || ''}
    />
    <TextInput
      value={`${I18n.t('parents')} 1 ${I18n.t('name')}*`}
      name="firstParentName"
      defaultValue={getUserProp(user, 'firstParentName')}
      required
      onChange={onFieldChange('firstParentName')}
      message={_.get(formState, 'errors.firstParentName.message') || ''}
    />
    <TextInput
      value={`${I18n.t('parents')} 1 ${I18n.t('phone_number')}*`}
      name="firstParentPhone"
      defaultValue={getUserProp(user, 'firstParentPhone')}
      required
      onChange={onFieldChange('firstParentPhone')}
      message={_.get(formState, 'errors.firstParentPhone.message') || ''}
    />
    <TextInput
      value={`${I18n.t('parents')} 2 ${I18n.t('name')}`}
      name="secondParentName"
      defaultValue={getUserProp(user, 'secondParentName')}
      onChange={onFieldChange('secondParentName')}
      message={_.get(formState, 'errors.secondParentName.message') || ''}
    />
    <TextInput
      value={`${I18n.t('parents')} 2 ${I18n.t('phone_number')}`}
      name="secondParentPhone"
      defaultValue={getUserProp(user, 'secondParentPhone')}
      onChange={onFieldChange('secondParentPhone')}
      message={_.get(formState, 'errors.secondParentPhone.message') || ''}
    />
    <TextInput
      value={I18n.t('how_did_you_know_about_RCYN')}
      name="knownFrom"
      defaultValue={getUserProp(user, 'knownFrom')}
    />
  </Fragment>
);

User.propTypes = {
  formData: PropTypes.object.isRequired,
  changeFormData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getUserProp: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default User;
