import { compose, withState, lifecycle } from 'recompose';
import MembersList from './Component';
import { groupsOperations } from '../../../modules/groups';

const enhancer = compose(
  withState('members', 'setMembers', []),
  lifecycle({
    async componentDidMount() {
      const { group, setMembers } = this.props;
      const members = await groupsOperations.fetchGroupMembers(group._id);
      setMembers(members);
    },
  })
);

export default enhancer(MembersList);
