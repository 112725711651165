import React, { Fragment } from 'react';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';

import { Row, Col, Button } from 'reactstrap';
import Toolbar from './Toolbar';
import CreateMessage from '../CreateMessage';

const Header = ({ onCreateMessageClick, isCreateMessageOpen }) => (
  <Fragment>
    <Row>
      <Col>
        <h1 className="mb-3">{I18n.t('message')}</h1>
      </Col>
      <Col className="text-right">
        <Button
          className="rounded-0 pr-5 pl-5 font-weight-bold"
          size="lg"
          color="primary"
          onClick={onCreateMessageClick}
        >
          <span>{I18n.t('write_message')}</span>
        </Button>
      </Col>
    </Row>
    <Row className="mb-3 pb-2 align-items-end">
      <Col>
        <Toolbar />
      </Col>
    </Row>
    {isCreateMessageOpen && (
      <CreateMessage toggle={onCreateMessageClick} isOpen={isCreateMessageOpen} />
    )}
  </Fragment>
);

Header.propTypes = {
  onCreateMessageClick: PropTypes.func.isRequired,
  isCreateMessageOpen: PropTypes.bool.isRequired,
};

export default Header;
