import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { notesSelectors, notesTypes } from '../../../../modules/notes';
import { authSelectors } from '../../../../modules/authorize';
import { loaderSelectors } from '../../../../modules/loader';
import Component from './Component';

const enhancer = compose(
  connect(state => ({
    ...loaderSelectors.getLoader(notesTypes.FETCH_NOTES)(state),
    ...notesSelectors.getNotes(state),
    ...authSelectors.getAutUser(state),
  })),
  withState('isNoteOpen', 'setIsNoteOpen', false),
  withState('isEditNoteOpen', 'setIsEditNoteOpen', false),
  withHandlers({
    onClickNote: ({ isNoteOpen, setIsNoteOpen }) => () => setIsNoteOpen(!isNoteOpen),
    onEditNote: props => () => {
      const { setIsEditNoteOpen } = props;
      setIsEditNoteOpen(true);
    },
    onEditToggle: ({ isEditNoteOpen, setIsEditNoteOpen }) => () =>
      setIsEditNoteOpen(!isEditNoteOpen),
  })
);

export default enhancer(Component);
