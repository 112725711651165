import _ from 'lodash';
import moment from 'moment';
import { I18n } from 'react-i18nify';

const getDateFormat = (groupBy = 'day') => {
  const map = {
    day: 'DD/MM/YYYY',
    month: 'MMMM',
    year: 'YYYY',
  };
  return map[groupBy];
};

const toPercent = (visited = 0, count = 0) => _.round((visited * 100) / count) || 0;

const statisticsAsXYToOptions = (statisticsAsXY) => {
  const totalCount = _.sumBy(statisticsAsXY.count, 'y');
  const totalVisited = _.sumBy(statisticsAsXY.visited, 'y');

  return {
    maintainAspectRatio: false,
    defaultFontSize: '18px',
    title: {
      display: true,
      text: `${I18n.t('visited')}: ${totalVisited} ${I18n.t('out_of')} ${totalCount} (${toPercent(
        totalVisited,
        totalCount
      )}%)`,
      fontSize: 18,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontSize: 18,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          bounds: 'ticks',
          distribution: 'series',
        },
      ],
    },
    legend: {
      display: true,
      labels: {
        fontSize: 20,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) =>
          `${tooltipItem.yLabel} ${data.datasets[tooltipItem.datasetIndex].label || ''}`,
      },
    },
  };
};

const statisticsAsXYToDataChart = (statisticsAsXY) => {
  const labels = statisticsAsXY.count.map(({ x }) =>
    moment(x).format(getDateFormat(statisticsAsXY.groupBy)));
  return {
    labels: labels.map(l => I18n.t(l)),
    datasets: [
      {
        data: statisticsAsXY.count,
        backgroundColor: '#b0e477',
        label: I18n.t('total'),
        stack: 1,
        fontSize: 20,
      },
      {
        data: statisticsAsXY.visited,
        backgroundColor: '#f9c979',
        label: I18n.t('visited'),
        stack: 2,
      },
    ],
  };
};

export { statisticsAsXYToDataChart, statisticsAsXYToOptions };
