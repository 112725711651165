import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';
import { Button, Spinner } from '../../components';
import './style.scss';

const PrivacyPolicy = ({
  isLoading, onConfirm, message, isOver13,
}) =>
  !isLoading ? (
    <Modal className="modal-lg privacy-policy-modal" isOpen>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0">{I18n.t('privacy_policy')}</ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <Form>
          <FormGroup
            className="d-flex flex-column
           flex-sm-row flex-md-row justify-content-lg-end mt-4"
          >
            <Button
              className="btn-sm-block btn-xs-block rounded-0 fw-900 mt-1 mr-2"
              color="secondary"
              isLoading={isLoading}
              onClick={onConfirm(false)}
            >
              {I18n.t(isOver13 ? 'cancel' : 'ok')}
            </Button>

            {isOver13 && (
              <Button
                className="btn-sm-block  btn-xs-block rounded-0 fw-900 mt-1"
                type="submit"
                color="primary"
                isLoading={isLoading}
                onClick={onConfirm(true)}
              >
                {I18n.t('accept')}
              </Button>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  ) : (
    <span className="spinner-wrapper mt-4">
      <Spinner />
    </span>
  );

PrivacyPolicy.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOver13: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

PrivacyPolicy.defaultProps = {
  message: '',
};

export default PrivacyPolicy;
