import React from 'react';
import { I18n } from 'react-i18nify';
import { Container, Row, Col } from 'reactstrap';

import Header from './Header';
import List from './List';

const Activities = () => (
  <div>
    <Container>
      <Row>
        <Col>
          <h1 className="mb-4">{I18n.t('activities')}</h1>
        </Col>
      </Row>
      <Header />
      <List />
    </Container>
  </div>
);

export default Activities;
