import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  withClearFormErrors,
  withFormErrorHandler,
  withFormReducer,
  withFormState,
  withSetFieldState,
} from '../../../utils/recompose-extensions/form';
import { loaderSelectors } from '../../../modules/loader/index';
import { notesOperations, notesTypes } from '../../../modules/notes/index';
import { jsonToFormData } from '../../../utils/jsonToFormData';
import Component from './Component';

const mapStateToProps = state => ({
  ...loaderSelectors.getLoader(notesTypes.CREATE_NOTE)(state),
});

const enhancer = compose(
  connect(mapStateToProps, {
    createNote: notesOperations.createNote,
  }),
  withRouter,
  withState('activityId', 'setActivityId', ''),
  withFormState({
    activity: {},
  }),
  withFormErrorHandler({}),
  withClearFormErrors,
  withSetFieldState,
  withFormReducer,
  withHandlers({
    onSubmit: props => (e) => {
      const { reduceForm } = props;
      const { activityId } = props.match.params;

      e.preventDefault();

      const reducedData = reduceForm(e.target);

      props.clearFormErrors();
      const request = {
        activityId,
        title: reducedData.title,
        description: reducedData.description,
      };
      const formData = new FormData();
      jsonToFormData(request, {}, formData);

      return props
        .createNote(formData)
        .then(() => props.toggle())
        .catch(props.catchFormError);
    },
  })
);

export default enhancer(Component);
