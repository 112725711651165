import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '../../../components';
import ListItem from './ListItem';

const ActivityList = ({ activities, onActivitiesFetch }) => (
  <InfiniteScroll
    pageStart={1}
    className="row"
    loadMore={onActivitiesFetch}
    hasMore={activities.count > activities.keys.length}
    loader={
      <Col lg={12} key="loader" className="d-flex justify-content-center">
        <span className="position-relative pl-4 pr-4">
          {I18n.t('loading')} ...
          <Spinner />
        </span>
      </Col>
    }
  >
    {activities.keys.map(key => (
      <ListItem key={key} activity={activities.values[key]} />
    ))}
  </InfiniteScroll>
);

ActivityList.propTypes = {
  activities: PropTypes.object.isRequired,
  onActivitiesFetch: PropTypes.func.isRequired,
};

export default ActivityList;
