import moment from 'moment';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import EditNote from '../../EditNote';

const ListItem = ({
  note, isNoteOpen, onClickNote, onEditNote, isEditNoteOpen, onEditToggle,
}) => (
  <Fragment>
    <div className="note-row pt-1 pb-1 pl-2 pr-2">
      <Row className="cursor-pointer" onClick={onClickNote}>
        <Col xs={10} sm={11}>
          {!isNoteOpen && (
            <Fragment>
              <div className="fw-500 fz-14">{note.title}</div>
              <div className="fz-14 fw-300 desc-row">{note.description}</div>
            </Fragment>
          )}
        </Col>
        <Col className="text-right" xs={2} sm={1}>
          {moment(note.createAt).isSame(moment(), 'day') ? (
            <div className="fw-500 fz-14">{moment(note.createAt).format('H:mm')}</div>
          ) : (
            <Fragment>
              <div className="fw-500 fz-14">{moment(note.createAt).format('DD MMM')}</div>
              <div className="fw-500 fz-14">{moment(note.createAt).format('YYYY')}</div>
            </Fragment>
          )}
        </Col>
      </Row>
    </div>
    {isNoteOpen && (
      <Row>
        <Col xs={12}>
          <div className="fz-32 fw-900">{note.title}</div>
        </Col>
        <Col xs={10}>
          <div className="fz-20 fw-300 word-wrap-brake">{note.description}</div>
        </Col>
        <Col className="text-right mb-3 mt-2" lg={12}>
          <FontAwesomeIcon
            className="fz-24 cursor-pointer mr-3"
            icon={faPencilAlt}
            onClick={onEditNote}
          />
        </Col>
        {isEditNoteOpen && <EditNote toggle={onEditToggle} isOpen={isEditNoteOpen} note={note} />}
      </Row>
    )}
  </Fragment>
);

ListItem.propTypes = {
  note: PropTypes.object.isRequired,
  isNoteOpen: PropTypes.bool.isRequired,
  onClickNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  onEditToggle: PropTypes.func.isRequired,
  isEditNoteOpen: PropTypes.bool.isRequired,
};

export default ListItem;
