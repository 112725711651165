import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PrivacyPolicyComponent from '../../containers/PrivacyPolicy/index';

const PrivacyPolicy = props => (
  <div>
    <Container className="pt-2 pb-4">
      <Row>
        <Col>
          <div className="d-flex justify-content-center text-center">
            <PrivacyPolicyComponent {...props} />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PrivacyPolicy;
