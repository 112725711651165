import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Button,
  Alert,
} from 'reactstrap';
import { I18n, Translate } from 'react-i18nify';
import Flatpickr from 'react-flatpickr';
import { Danish } from 'flatpickr/dist/l10n/da.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTrash, faAngleDown, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { TextInput } from '../../../components';
import VolunteerSelector from './VolunteerSelector';
import styles from './styles';

import { getScheduledPeriod } from '../../../utils/helpers';
import { DEFAULT_TIMEZONE_KEY } from '../../../constants';

const AddGroup = ({
  group,
  daysInRange,
  addSchedule,
  onSubmit,
  fromTime,
  toTime,
  startDate,
  endDate,
  eventDay,
  setFromTime,
  setToTime,
  setEventDay,
  schedules,
  removeSchedule,
  toggle,
  isOpen,
  volunteers,
  onAddVolunteer,
  onRemoveVolunteer,
  formState,
  toggleRemoveGroup,
  isRemoveGroup,
  onConfirmRemoveGroup,
  setStartDate,
  setEndDate,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {group.id ? I18n.t('edit_team') : I18n.t('add_team')}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <TextInput
              defaultValue={group.title}
              required
              value={I18n.t('team_name')}
              name="title"
            />
          </FormGroup>
          <FormGroup>
            <Label className="fz-18 fw-500">{I18n.t('description_of_activity')}</Label>
            <Input defaultValue={group.description} type="textarea" rows="5" name="description" />
          </FormGroup>

          <VolunteerSelector
            volunteers={volunteers}
            onAddVolunteer={onAddVolunteer}
            onRemoveVolunteer={onRemoveVolunteer}
          />

          <Row className="align-items-end mb-4">
            <Col md={5} lg={4}>
              <FormGroup className="mb-0">
                <Label className="fz-18 fw-500">{I18n.t('start_date')}</Label>
                <Flatpickr
                  className="position-relative calendar-field w-100 default-box-shadow"
                  value={startDate}
                  required
                  onChange={setStartDate}
                  options={{
                    maxDate: endDate,
                    locale: Danish,
                    dateFormat: 'D d/m/Y',
                    wrap: true,
                  }}
                >
                  <input required className="w-100" type="text" data-input />
                  <button className="position-absolute" type="button" data-toggle>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </button>
                </Flatpickr>
              </FormGroup>
            </Col>
            <Col md={5} lg={4}>
              <FormGroup className="mb-0">
                <Label className="fz-18 fw-500">{I18n.t('end_date')}</Label>
                <Flatpickr
                  className="position-relative calendar-field w-100 default-box-shadow"
                  value={endDate || startDate}
                  required
                  onChange={setEndDate}
                  options={{
                    minDate: startDate,
                    locale: Danish,
                    dateFormat: 'D d/m/Y',
                    wrap: true,
                  }}
                >
                  <input required className="w-100" type="text" data-input />
                  <button className="position-absolute" type="button" data-toggle>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </button>
                </Flatpickr>
              </FormGroup>
            </Col>
          </Row>

          <Row className="align-items-end mb-4">
            <Col md={4} lg={3}>
              <FormGroup className="mb-0">
                <Label className="fz-18 fw-500">{I18n.t('weekday')}</Label>
                <FormGroup className="select-customize min-w-100">
                  <FontAwesomeIcon icon={faAngleDown} />
                  <Input
                    type="select"
                    name="day"
                    value={eventDay}
                    onChange={e => setEventDay(e.target.value)}
                  >
                    {daysInRange.map(day => (
                      <option key={day} value={day}>
                        {I18n.t(day)}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col md={4} lg={3}>
              <FormGroup className="mb-0">
                <Label className="fz-18 fw-500">{I18n.t('from')}</Label>
                <Flatpickr
                  className="position-relative calendar-field w-100 default-box-shadow"
                  value={fromTime}
                  onChange={date => setFromTime(date)}
                  options={{
                    locale: Danish,
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                    wrap: true,
                  }}
                >
                  <input className="w-100" type="text" data-input />
                  <button className="position-absolute" type="button" data-toggle>
                    <FontAwesomeIcon icon={faClock} />
                  </button>
                </Flatpickr>
              </FormGroup>
            </Col>
            <Col md={4} lg={3}>
              <FormGroup className="mb-0">
                <Label className="fz-18 fw-500">{I18n.t('to')}</Label>
                <Flatpickr
                  className="position-relative calendar-field w-100 default-box-shadow"
                  value={toTime}
                  onChange={date => setToTime(date)}
                  options={{
                    locale: Danish,
                    enableTime: true,
                    noCalendar: true,
                    time_24hr: true,
                    wrap: true,
                  }}
                >
                  <input className="w-100" type="text" data-input />
                  <button className="position-absolute" type="button" data-toggle>
                    <FontAwesomeIcon icon={faClock} />
                  </button>
                </Flatpickr>
              </FormGroup>
            </Col>
            <Col className="mt-2 mt-lg-0" md={4} lg={3}>
              <Button
                className="d-flex px-2 align-items-center justify-content-center text-white
                  rounded-0 fz-16 fw-900 default-box-shadow mr-1"
                size="lg"
                color="primary"
                style={styles.addTimeButton}
                onClick={addSchedule}
              >
                <span>{I18n.t('add_time')}</span>
              </Button>
            </Col>
          </Row>
          <FormGroup>
            {schedules.map(({
 day, fullStartAt, fullEndAt, isLocalTime,
}, index) => {
              const { start, end } = getScheduledPeriod({
                startDate: fullStartAt,
                endDate: fullEndAt,
                //  todo: set timezone into DB on creating activity
                timezoneKey: group.timezoneKey || DEFAULT_TIMEZONE_KEY,
                isLocalTime,
              });
              return (
                // eslint-disable-next-line
                <p className="fz-18" key={index}>
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faTrash}
                    onClick={() => removeSchedule(index)}
                  />
                  {`${I18n.t(day)} ${start} - ${end}`}
                </p>
              );
            })}
          </FormGroup>
          <FormGroup>
            {formState.error && (
              <Alert color="danger">
                <Translate value={formState.error} {...formState.payload} />
              </Alert>
            )}
          </FormGroup>
          {group._id ? (
            <FormGroup>
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                {isRemoveGroup && (
                  <div className="d-inline-block">
                    <Button
                      className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2 mr-2"
                      color="primary"
                      size="lg"
                      onClick={onConfirmRemoveGroup}
                    >
                      {I18n.t('yes')}
                    </Button>
                    <Button
                      className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                      color="danger"
                      size="lg"
                      onClick={toggleRemoveGroup}
                    >
                      {I18n.t('no')}
                    </Button>
                  </div>
                )}
                {!isRemoveGroup && (
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                    color="danger"
                    size="lg"
                    onClick={toggleRemoveGroup}
                  >
                    {I18n.t('delete_group')}
                  </Button>
                )}
                <Button className="rounded-0 fz-24 fw-900" color="primary" type="submit" size="lg">
                  {I18n.t('save_changes')}
                </Button>
              </div>
            </FormGroup>
          ) : (
            <FormGroup className="d-flex justify-content-end">
              <Button className="rounded-0 fz-24 fw-900" size="lg" color="primary" type="submit">
                <span>{I18n.t('create_team')}</span>
              </Button>
            </FormGroup>
          )}
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

AddGroup.propTypes = {
  formState: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  daysInRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  addSchedule: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fromTime: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  toTime: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  eventDay: PropTypes.string.isRequired,
  setFromTime: PropTypes.func.isRequired,
  setToTime: PropTypes.func.isRequired,
  setEventDay: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string,
    from: PropTypes.Date,
    to: PropTypes.Date,
  })).isRequired,
  removeSchedule: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  volunteers: PropTypes.array.isRequired,
  onAddVolunteer: PropTypes.func.isRequired,
  onRemoveVolunteer: PropTypes.func.isRequired,
  toggleRemoveGroup: PropTypes.func.isRequired,
  isRemoveGroup: PropTypes.bool.isRequired,
  onConfirmRemoveGroup: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

AddGroup.defaultProps = {
  fromTime: null,
  toTime: null,
};

export default AddGroup;
