const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
const ADD_ACTIVITY = 'ADD_ACTIVITY';
const SET_ACTIVE_ACTIVITY = 'SET_ACTIVE_ACTIVITY';
const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';
const ACTIVITIES = 'ACTIVITIES';
const ACTIVITY_STATISTICS = 'ACTIVITY_STATISTICS';
const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
const FETCH_GROUPS = 'FETCH_GROUPS';
const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
const IS_FETCHING_ACTIVITY = 'IS_FETCHING_ACTIVITY';

export default {
  IS_FETCHING_ACTIVITY,
  CLEAR_ACTIVITIES,
  CLEAR_ACTIVITY,
  ADD_ACTIVITY,
  FETCH_ACTIVITIES,
  SET_ACTIVE_ACTIVITY,
  ACTIVITIES,
  CREATE_ACTIVITY,
  FETCH_GROUPS,
  UPDATE_ACTIVITY,
  REMOVE_ACTIVITY,
  ACTIVITY_STATISTICS,
};
