import React from 'react';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import Graph from './Graph/index';
import Header from './Header/index';

const Statistics = ({ user }) => (
  <div>
    <h2>{I18n.t('participation_statistics')}</h2>
    <Header user={user} />
    <div className="mt-4">
      <Graph />
    </div>
  </div>
);

Statistics.propTypes = {
  user: PropTypes.object,
};
Statistics.defaultProps = {
  user: {},
};

export default Statistics;
