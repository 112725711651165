import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Image } from '../../../../components';
import { routeList } from '../../../../pages/routes';
import { authTypes } from '../../../../modules/authorize';

const { ROLES_TRANSLATE } = authTypes;

const ListItem = ({ user, getUserProp }) => (
  <Col sm={6} md={4} lg={3} xl={3}>
    <Card className="border-0 rounded-0 card-img-wr">
      <Link to={`${routeList.USERS}/${user._id}`}>
        <Image imageId={getUserProp(user, 'imageId')} />
      </Link>
      <CardBody className="pl-0 pr-0 pt-1">
        <CardTitle className="mb-0 fw-700">
          {getUserProp(user, 'firstName')} {getUserProp(user, 'lastName')}
        </CardTitle>
        <CardText>
          <small className="text-muted">
            {I18n.t(ROLES_TRANSLATE[getUserProp(user, ['roles', '0'])] || '')}
          </small>
        </CardText>
      </CardBody>
    </Card>
  </Col>
);

ListItem.propTypes = {
  user: PropTypes.object.isRequired,
  getUserProp: PropTypes.func.isRequired,
};

export default ListItem;
