import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap';
import { I18n } from 'react-i18nify';
import './style.scss';

const MembersList = ({
  isOpen, toggle, lesson, members,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-3 pb-2 border-0" toggle={toggle}>
        <div className="fz-24 fw-700">{_.get(lesson, 'group.activity.title', '')}</div>
      </ModalHeader>
      <ModalBody className="pl-4 pr-4 pb-4 ml-2 mr-2 pt-0">
        <Row>
          <Col lg={4} className="team-name pl-2 pr-2">
            {lesson.group.title}
          </Col>
          <Col lg={4} className="d-flex align-items-center pl-2 pr-2">
            <div className="color-label grey-bg mr-2" />
            <div className="fz-18 fw-700">{I18n.t('have_not_participated')}</div>
          </Col>
          <Col lg={4} className="d-flex align-items-center pl-2 pr-2">
            <div className="color-label green-bg mr-2" />
            <div className="fz-18 fw-700">{I18n.t('have_participated')}</div>
          </Col>
        </Row>
        <Row className="mt-3">
          {members.map(({ profile, _id }) => (
            <Col key={_id} lg={4} className="col-wrapper pl-2 pr-2">
              <div
                className={`fz-14 fw-700 pt-2 pb-2 pl-3
                ${_.indexOf(lesson.memberIds, _id) !== -1 ? 'green-bg' : 'grey-bg'}
              `}
              >
                {`${profile.firstName} ${profile.lastName}`}
              </div>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  </div>
);

MembersList.propTypes = {
  lesson: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
};

export default MembersList;
