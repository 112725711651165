import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const LOADER_URL = '/images/loader.svg';

class Image extends React.Component {
  render() {
    const { src, registerImgRef, isLoading } = this.props;
    return (
      <div className="d-flex h-100 justify-content-center align-items-center placeholder">
        <img
          id="image"
          ref={registerImgRef}
          src={(isLoading && LOADER_URL) || src}
          className={`w-100 ${(isLoading && 'loader') || ''}`}
          alt=""
        />
      </div>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  registerImgRef: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Image;
