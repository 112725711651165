import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { groupsOperations, groupsTypes } from '../../../modules/groups';
import { getTimeAndMinutesUTC } from './helpers';
import { formatDate } from '../../../utils/helpers';
import {
  withFormState,
  withFormErrorHandler,
  withClearFormErrors,
} from '../../../utils/recompose-extensions/form';

import Component from './Component';

const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const getDaysRange = (start, end, days = []) => {
  if (start.isSameOrBefore(end)) {
    const startDate = moment(start);
    const day = daysOfTheWeek[startDate.day()];
    return getDaysRange(startDate.add(1, 'day'), end, [...days, day]);
  }
  return days;
};

const defaultTime = moment()
  .startOf('day')
  .toDate();

const defaultEndTime = moment()
  .endOf('day')
  .toDate();

const enhancer = compose(
  connect(
    state => ({
      isLoading: !!_.get(state, [
        'loader',
        groupsTypes.CREATE_GROUP || groupsTypes.UPDATE_GROUP || groupsTypes.REMOVE_GROUP,
      ]),
      group: state.groups.values[state.groups.active] || {},
    }),
    {
      createGroup: groupsOperations.createGroup,
      updateGroup: groupsOperations.updateGroup,
      removeGroup: groupsOperations.removeGroup,
      setActiveGroup: groupsOperations.setActiveGroup,
      fetchGroupCoaches: groupsOperations.fetchGroupCoaches,
    }
  ),
  withFormState({}),
  withClearFormErrors,
  withFormErrorHandler({}),
  withState('daysInRange', 'setDaysInRange', daysOfTheWeek),
  withState('volunteers', 'setVolunteers', []),
  withState('fromTime', 'setFromTime', [defaultTime]),
  withState('toTime', 'setToTime', [defaultEndTime]),
  withState('startDate', 'setStartDateState', ({ group }) =>
    group.startDate ? moment(group.startDate).toDate() : defaultTime),
  withState('endDate', 'setEndDateState', ({ group }) =>
    group.endDate ? moment(group.endDate).toDate() : defaultEndTime),
  withState('eventDay', 'setEventDay', ({ daysInRange }) => daysInRange[0]),
  withState('schedules', 'setSchedules', []),
  withState('isRemoveGroup', 'setIsRemoveGroup', false),
  withHandlers({
    setInitialEventDay: ({ daysInRange, setEventDay }) => () => setEventDay(daysInRange[0]),
  }),
  withHandlers({
    filterDays: ({ setDaysInRange }) => (startDate, endDate) => {
      const diffInDays = Math.abs(startDate.diff(endDate, 'days'));
      if (diffInDays > 5) {
        setDaysInRange(daysOfTheWeek);
      } else {
        setDaysInRange(getDaysRange(startDate, endDate));
      }
    },
    onConfirmRemoveGroup: ({
      group, toggle, catchFormError, removeGroup,
    }) => () =>
      removeGroup(group._id)
        .then(toggle)
        .catch(catchFormError),

    toggleRemoveGroup: ({ isRemoveGroup, setIsRemoveGroup }) => () =>
      setIsRemoveGroup(!isRemoveGroup),

    addSchedule: ({
      fromTime,
      toTime,
      eventDay,
      setFromTime,
      setToTime,
      schedules,
      setSchedules,
      setInitialEventDay,
    }) => () => {
      const start = fromTime[0];
      const end = toTime[0];

      const schedule = {
        from: fromTime[0],
        to: toTime[0],
        day: eventDay,
        fullStartAt: start,
        fullEndAt: end,
        // This field is needed to differ old schedule docs and new ones in DB, to shift or not
        // the time according to DST.
        // The old docs were created without a knowledge of a timezone
        isLocalTime: true,
      };

      setSchedules([...schedules, schedule]);
      setFromTime([defaultTime]);
      setToTime([defaultTime]);
      setInitialEventDay();
    },
    removeSchedule: ({ schedules, setSchedules }) => (index) => {
      const updatedSchedules = [...schedules.slice(0, index), ...schedules.slice(index + 1)];
      setSchedules(updatedSchedules);
    },
    onAddVolunteer: ({ volunteers, setVolunteers }) => volunteer =>
      !_.find(volunteers, v => v._id === volunteer._id) &&
      setVolunteers([...volunteers, volunteer]),
    onRemoveVolunteer: ({ volunteers, setVolunteers }) => volunteerId => () => {
      const index = _.findIndex(volunteers, v => v._id === volunteerId);
      volunteers.splice(index, 1);
      setVolunteers(volunteers);
    },
    onSubmit: props => (e) => {
      e.preventDefault();
      props.clearFormErrors();

      const {
        volunteers,
        activityId,
        schedules,
        toggle,
        group,
        setFormState,
        formState,
        startDate,
        endDate,
        daysInRange,
      } = props;
      const { title, description } = e.target;

      const reducer = (acc, { day }) => daysInRange.includes(day) && acc;
      const isValidDay = schedules.reduce(reducer, true);

      if (!isValidDay) {
        return setFormState({ ...formState, error: 'event_not_in_days_range' });
      }
      if (!schedules.length) {
        return setFormState({ ...formState, error: 'no_schedule_message' });
      }
      if (moment(startDate).isAfter(endDate)) {
        return setFormState({ ...formState, error: 'start_before_end_date_message' });
      }
      const failSchedule = schedules.find(({ from, to }) => moment(from).isAfter(to));
      if (failSchedule) {
        const { from, to } = failSchedule;
        return setFormState({
          ...formState,
          error: 'start_before_end_time_message',
          payload: {
            from: moment(from).format('HH:mm'),
            to: moment(to).format('HH:mm'),
          },
        });
      }

      const formData = {
        activityId,
        startDate: [
          moment(startDate[0] || startDate)
            .startOf('day')
            .toDate(),
        ],
        endDate: [
          moment(endDate[0] || endDate)
            .endOf('day')
            .toDate(),
        ] || [
          moment(startDate[0] || startDate)
            .endOf('day')
            .toDate(),
        ],
        title: title.value.trim(),
        description: description.value.trim(),
        coachIds: volunteers.map(({ _id }) => _id),
        schedule: schedules.map(({
          _id, from, to, day, isLocalTime,
        }) => ({
          _id,
          fullStartAt: from,
          fullEndAt: to,
          startAt: getTimeAndMinutesUTC(from),
          endAt: getTimeAndMinutesUTC(to),
          day: daysOfTheWeek.indexOf(day),
          isLocalTime,
        })),
      };
      const action = !group._id ? props.createGroup : props.updateGroup;
      return action(formData, group._id)
        .then(toggle)
        .catch();
    },
  }),
  withHandlers({
    setStartDate: ({
      setStartDateState, endDate, filterDays, setInitialEventDay,
    }) => (date = []) => {
      const startDate = moment(date[0]);
      setStartDateState(startDate.toDate());
      filterDays(startDate, moment(endDate));
      setInitialEventDay();
    },
    setEndDate: ({
      setEndDateState, startDate, filterDays, setInitialEventDay,
    }) => (date = []) => {
      const endDate = moment(date[0]);
      setEndDateState(endDate.toDate());
      filterDays(moment(startDate), endDate);
      setInitialEventDay();
    },
  }),
  lifecycle({
    componentWillMount() {
      const {
        setVolunteers,
        setSchedules,
        filterDays,
        group: {
          startDate, endDate, _id, schedule,
        },
        setInitialEventDay,
        fetchGroupCoaches,
      } = this.props;
      // If we edit a group
      if (_id) {
        fetchGroupCoaches(_id).then(coaches => setVolunteers(coaches));

        const formatted = schedule.map(({
          day, startAt, endAt, ...rest
        }) => {
          const { preparedStartDate, preparedEndDate } = formatDate(startAt, endAt);
          return {
            ...rest,
            day: daysOfTheWeek[day],
            from: preparedStartDate,
            to: preparedEndDate,
          };
        });

        setSchedules(formatted);
      }
      filterDays(moment(startDate), moment(endDate));
      setTimeout(setInitialEventDay, 0);
    },
    componentWillUnmount() {
      this.props.setActiveGroup('');
    },
  })
);

export default enhancer(Component);
