const NOTES = 'NOTES';
const FETCH_NOTES = 'FETCH_NOTES';
const FETCH_NOTE = 'FETCH_NOTE';
const ADD_NOTE = 'ADD_NOTE';
const CLEAR_NOTES = 'CLEAR_NOTES';
const CLEAR_NOTE = 'CLEAR_NOTE';
const UPDATE_NOTE = 'UPDATE_NOTE';
const REMOVE_NOTE = 'REMOVE_NOTE';
const CREATE_NOTE = 'CREATE_NOTE';

export default {
  FETCH_NOTES,
  FETCH_NOTE,
  CLEAR_NOTES,
  CLEAR_NOTE,
  UPDATE_NOTE,
  REMOVE_NOTE,
  NOTES,
  CREATE_NOTE,
  ADD_NOTE,
};
