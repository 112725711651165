import Container from './Container';

/**
 * Provide auto suggest for users
 * @params
 *        onSelected - {func} - when select dome item
 *                     (users) => ... //
 *        onEmpty - {func} - when search value is empty
 *        type - {String} - volunteer || admin || users
 *        clearAfterSelect {Boolean} default false - clear search input after select

 *
 * @example
 *        <UserAutosuggest onSelected={onAddVolunteer} type="volunteer" clearAfterSelect />
 *
 * * */
export default Container;
