import * as actions from './actions';

const search = inModel => value => dispatch => dispatch(actions.search(inModel, value));
const filter = inModel => (name, value) => dispatch =>
  dispatch(actions.filter(inModel, name, value));
const clearFilter = inModel => () => dispatch => dispatch(actions.clearFilter(inModel));

export default {
  search,
  filter,
  clearFilter,
};
