import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import { Danish } from 'flatpickr/dist/l10n/da.js';

const ToolBar = ({
  onDateChange, onResetFilter, startDate, endDate,
}) => (
  <div className="d-flex info-btns align-items-end flex-wrap">
    <div>
      <label htmlFor="flatpickr" className="fz-18 fw-500">
        {I18n.t('period')}
        <Button color="link" onClick={onResetFilter}>
          <FontAwesomeIcon className="fz-18" icon={faTimes} />
        </Button>
      </label>
      <Flatpickr
        className="default-box-shadow position-relative calendar-field"
        onChange={onDateChange('from')}
        options={{
          maxDate: endDate,
          locale: Danish,
          dateFormat: 'D d/m/Y',
          wrap: true,
        }}
      >
        <input id="flatpickr" type="text" data-input />
        <button className="position-absolute" type="button" data-toggle>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </button>
      </Flatpickr>
    </div>

    <div>
      <Flatpickr
        className="default-box-shadow position-relative calendar-field"
        onChange={onDateChange('to')}
        options={{
          minDate: startDate,
          locale: Danish,
          dateFormat: 'D d/m/Y',
          wrap: true,
        }}
      >
        <button className="position-absolute" type="button" data-toggle>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </button>
        <input type="text" data-input />
      </Flatpickr>
    </div>
  </div>
);

ToolBar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onResetFilter: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

ToolBar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default ToolBar;
