import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose, withHandlers, withState, withProps, lifecycle } from 'recompose';
import { createSelector } from 'reselect';
import { withGetUserProp } from '../../../../utils/recompose-extensions/props-selectors';
import Component from './Component';
import { usersOperations } from '../../../../modules/users';

const getUsers = message =>
  createSelector(
    state => state.users,
    users =>
      (_.get(message, 'userIds.length') && message.userIds) ||
      message.userIds.map(_id => users.values[_id])
  );

const enhancer = compose(
  connect(
    (state, { message }) => ({
      createBy: message.createBy || {},
      users: getUsers(message)(state),
    }),
    {
      fetchUser: usersOperations.fetchUser,
    }
  ),
  withGetUserProp,
  withState('isToggleMessage', 'setIsToggleMessage', false),
  withProps(({ message }) => {
    const duration = moment.duration(moment().diff(moment(message.createAt))).asDays();
    return {
      messageTime:
        (duration > 1 && moment(message.createAt).format('DD MMM')) ||
        moment(message.createAt).format('HH:MM'),
      messageYear: (duration > 1 && moment(message.createAt).format('YYYY')) || '',
    };
  }),
  withHandlers({
    joinUsers: ({ getUserProp, users }) => (length = 0) => {
      const lastIndex = length || users.length;
      const str = users
        .map((user, index) =>
          index < lastIndex &&
            user &&
            `${getUserProp(user, 'firstName')} ${getUserProp(user, 'lastName')}`)
        .filter(v => !!v)
        .join(', ');
      return `${str} ${(users.length > lastIndex && '...') || ''}`;
    },
    onToggleMessage: ({ isToggleMessage, setIsToggleMessage }) => () =>
      setIsToggleMessage(!isToggleMessage),
  }),
  lifecycle({
    componentDidMount() {
      const { createBy, message, users } = this.props;
      if (!createBy._id && message.createBy) {
        this.props.fetchUser(message.createBy._id);
      }
      users.forEach((user, index) => {
        if (!_.get(user, '_id')) {
          message.userIds[index] && this.props.fetchUser(message.userIds[index]);
        }
      });
    },
  })
);

export default enhancer(Component);
