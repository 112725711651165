import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Label, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Flatpickr from 'react-flatpickr';
import { Danish } from 'flatpickr/dist/l10n/da.js';
import { GroupSelector } from '../../../../components';
import { prepareDate } from '../../../../utils';

const Header = ({
  filter, activity, onDateChange, onGroupSelect, onResetDateFilter,
}) => (
  <Fragment>
    <div className="d-flex info-btns align-items-end flex-wrap pt-3">
      <div>
        <Label className="fz-18 fw-500">{I18n.t('period')}</Label>
        <Button color="link" onClick={onResetDateFilter}>
          <FontAwesomeIcon className="fz-18" icon={faTimes} />
        </Button>
        <Flatpickr
          className="default-box-shadow position-relative calendar-field"
          options={{
            maxDate: filter.to,
            locale: Danish,
            dateFormat: 'D d/m/Y',
            wrap: true,
          }}
          onChange={onDateChange('from')}
        >
          <input type="text" data-input readOnly value={prepareDate(filter.from)} />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
      <div>
        <Flatpickr
          className="default-box-shadow position-relative calendar-field"
          options={{
            minDate: filter.from,
            locale: Danish,
            dateFormat: 'D d/m/Y',
            wrap: true,
          }}
          onChange={onDateChange('to')}
        >
          <input type="text" data-input readOnly value={prepareDate(filter.to)} />
          <button className="position-absolute" type="button" data-toggle>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </button>
        </Flatpickr>
      </div>
      <GroupSelector activityId={activity._id} onSelect={onGroupSelect} />
    </div>
  </Fragment>
);

Header.propTypes = {
  filter: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  onResetDateFilter: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
};

export default Header;
