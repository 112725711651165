import { notesOperations } from '../../modules/notes';
import { messagesOperations } from '../../modules/messages';
import { io } from '../../utils/api';
import { NEW_NOTE, NEW_MESSAGE } from './events';

const onNewNote = (dispatch) => {
  io.removeListener(NEW_NOTE); // should remove prev listener before add new
  io.on(NEW_NOTE, ({ note }) => notesOperations.addNote(note)(dispatch));
};

const onNewMessage = (dispatch) => {
  io.removeListener(NEW_MESSAGE);
  io.on(NEW_MESSAGE, ({ message }) => dispatch(messagesOperations.addMessage(message)(dispatch)));
};

export { onNewNote, onNewMessage };
