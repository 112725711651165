import { uniq } from 'lodash';
import types from './types';

const defaultStateActivities = {
  keys: [],
  values: {},
  count: 0,
  isFetchingActivities: false,
};
const defaultStateActivity = {};

const activitiesReducer = (state = defaultStateActivities, action) => {
  switch (action.type) {
    case types.FETCH_ACTIVITIES: {
      const keys = uniq([...state.keys, ...action.activities.keys]);
      return {
        keys,
        count: action.count,
        values: { ...state.values, ...action.activities.values },
      };
    }
    case types.ADD_ACTIVITY:
    case types.CREATE_ACTIVITY: {
      const keys = uniq([action.activity._id, ...state.keys]);
      return {
        ...state,
        keys,
        values: { ...state.values, [action.activity._id]: action.activity },
      };
    }
    case types.IS_FETCHING_ACTIVITY: {
      const { activity } = action;
      return {
        ...state,
        isFetchingActivities: activity,
      };
    }
    case types.CLEAR_ACTIVITIES:
      return defaultStateActivities;
    default:
      return state;
  }
};

const activityReducer = (state = defaultStateActivity, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_ACTIVITY: {
      return {
        ...state,
        ...action.activity.activity,
      };
    }
    case types.CLEAR_ACTIVITY: {
      return {
        _id: state._id,
      };
    }
    default:
      return state;
  }
};

export { activitiesReducer, activityReducer };
