import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../components/Spinner';

const UploadFiles = ({ attachedFiles, downloadFile, isLoadingFiles }) => (
  <Fragment>
    <hr className="separator mt-4 mb-4" />
    <ul className="d-inline-block files-list m-0 p-0">
      {attachedFiles.map(({ _id, name } = {}) => (
        <li key={_id} className="position-relative fw-500 t-underline">
          {isLoadingFiles[_id] && <Spinner />}
          <button
            disabled={isLoadingFiles[_id]}
            className="border-0 bg-transparent ml-4"
            onClick={() => downloadFile({ _id, name })}
          >
            {name}
          </button>
        </li>
      ))}
    </ul>
    <hr className="separator mt-4 mb-4" />
  </Fragment>
);

UploadFiles.propTypes = {
  isLoadingFiles: PropTypes.object.isRequired,
  attachedFiles: PropTypes.array.isRequired,
  downloadFile: PropTypes.func.isRequired,
};

export default UploadFiles;
