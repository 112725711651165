const FETCH_GROUPS = 'FETCH_GROUPS';
const FETCH_GROUP_COACHES = 'FETCH_GROUP_COACHES';
const CREATE_GROUP = 'CREATE_GROUP';
const CLEAR_GROUPS = 'CLEAR_GROUPS';
const UPDATE_GROUP = 'UPDATE_GROUP';
const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
const REMOVE_GROUP = 'REMOVE_GROUP';

export default {
  FETCH_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  REMOVE_GROUP,
  SET_ACTIVE_GROUP,
  FETCH_GROUP_COACHES,
  CLEAR_GROUPS,
};
