import types from './types';
import { normalize } from '../../utils/data';

const fetchNotes = (notes, count) => ({
  count,
  type: types.FETCH_NOTES,
  notes: normalize('_id', notes),
});

const fetchNote = note => ({
  note,
  type: types.FETCH_NOTE,
});
const addNote = note => ({
  note,
  type: types.ADD_NOTE,
});

const removeNote = id => ({
  id,
  type: types.REMOVE_NOTE,
});

const clearNotes = () => ({
  type: types.CLEAR_NOTES,
});

const createNote = note => ({
  note,
  type: types.CREATE_NOTE,
});

export { fetchNotes, fetchNote, clearNotes, removeNote, createNote, addNote };
