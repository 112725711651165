import { compose, withState, withHandlers } from 'recompose';

import Component from './Component';

const enhancer = compose(
  withState('isConfirm', 'setIsConfirm', false),
  withHandlers({
    onToggle: ({ setIsConfirm }) => value => () => setIsConfirm(value),
  })
);

export default enhancer(Component);
