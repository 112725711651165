import types from './types';

const acceptPrivacyPolicy = () => ({
  type: types.ACCEPT_PRIVACYPOLICY,
});

const rejectPrivacyPolicy = () => ({
  type: types.REJECT_PRIVACYPOLICY,
});

export { acceptPrivacyPolicy, rejectPrivacyPolicy };
