import { createSelector } from 'reselect';
import types from './types';

const getNotes = state => ({
  notes: state.notes,
});

const getFilteredNotesKeys = createSelector(
  state => state.notes,
  stete => stete.actions.filter[types.NOTES] || {},
  (notes, filter) =>
    (filter.activityId &&
      notes.keys.filter(key => notes.values[key].activityId === filter.activityId)) ||
    notes.keys
);

export default {
  getNotes,
  getFilteredNotesKeys,
};
