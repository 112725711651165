import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withState, lifecycle, withHandlers } from 'recompose';
import FileSaver from 'file-saver';
import { usersOperations } from '../../../modules/users';
import Component from './Component';

const mapStateToProps = state => ({
  user: state.user || {},
});

const mapDispatchToProps = {
  fetchFiles: usersOperations.fetchFiles,
  fetchFile: usersOperations.fetchSingleFile,
};

const areFilesChanged = (oldFiles = [], newFiles = []) =>
  _.difference(oldFiles, newFiles).length || _.difference(newFiles, oldFiles).length;

const enhancer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('attachedFiles', 'setAttachedFiles', []),
  withState('isLoadingFiles', 'setIsLoadingFiles', {}),
  withHandlers({
    downloadFile: ({ fetchFile, isLoadingFiles, setIsLoadingFiles }) => async ({
      _id,
      name,
    } = {}) => {
      setIsLoadingFiles({ ...isLoadingFiles, ...{ [_id]: true } });
      const { data } = (await fetchFile(_id)) || {};
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, name);
      setIsLoadingFiles({ ...isLoadingFiles, ...{ [_id]: false } });
    },
  }),

  lifecycle({
    componentWillMount() {
      const { setAttachedFiles, fetchFiles, user } = this.props;
      fetchFiles(user.attachments || []).then(setAttachedFiles);
    },
    componentDidUpdate(newProps) {
      const { setAttachedFiles, fetchFiles, user } = this.props;

      if (areFilesChanged(_.get(user, 'attachments'), _.get(newProps.user, 'attachments'))) {
        fetchFiles(user.attachments).then(setAttachedFiles);
      }
    },
  })
);

export default enhancer(Component);
