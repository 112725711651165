import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import {
  withClearFormErrors,
  withFormErrorHandler,
  withFormReducer,
  withFormState,
} from '../../../utils/recompose-extensions/form';

import Component from './Component';
import { activityOperations, activityTypes } from '../../../modules/activity/index';
import { jsonToFormData } from '../../../utils/jsonToFormData';
import { loaderSelectors } from '../../../modules/loader/index';
import { withGetUserProp } from '../../../utils/recompose-extensions/props-selectors';

const mapStateToProps = state => ({
  ...loaderSelectors.getLoader(activityTypes.CREATE_ACTIVITY)(state),
});

const enhancer = compose(
  connect(
    mapStateToProps,
    {
      createActivity: activityOperations.createActivity,
    }
  ),
  withGetUserProp,
  withFormState({}),
  withFormErrorHandler({}),
  withClearFormErrors,
  withState('activityImage', 'setActivityImage', null),
  withState('defaultImageActivity', 'setDefaultImageActivity', null),
  withFormReducer,
  withHandlers({
    onFileSelect: ({ setActivityImage }) => event => setActivityImage(event.target.files[0]),
    onSubmit: props => async (event) => {
      const { reduceForm } = props;
      event.preventDefault();
      props.clearFormErrors();

      const reducedData = reduceForm(event.target);
      const request = {
        title: reducedData.title,
        description: reducedData.description,
        contact: reducedData.contact,
        picture: props.activityImage,
      };
      const formData = new FormData();
      jsonToFormData(request, {}, formData);

      props
        .createActivity(formData)
        .then(() => props.toggle())
        .catch(props.catchFormError);
    },
  })
);

export default enhancer(Component);
