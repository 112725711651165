import { createStore, applyMiddleware } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import ReduxPromise from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise, ReduxThunk)(createStore);

const store = createStoreWithMiddleware(reducer, undefined, composeWithDevTools(autoRehydrate()));

persistStore(store, { whitelist: ['authorize'] });

export default store;
