import React from 'react';
import Settings from '../../containers/Settings';

const SettingsPage = () => (
  <div>
    <Settings />
  </div>
);

export default SettingsPage;
