import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withFormErrorHandler, withFormReducer } from '../../../utils/recompose-extensions/form';
import { notesOperations, notesTypes } from '../../../modules/notes';
import { activityOperations } from '../../../modules/activity';
import { jsonToFormData } from '../../../utils/jsonToFormData';
import { loaderSelectors } from '../../../modules/loader/index';

import Component from './Component';

const mapStateToProps = (state, { note }) => ({
  activity: state.activities.values[note.activityId] || {},
  ...loaderSelectors.getLoader(notesTypes.UPDATE_NOTE)(state),
  ...loaderSelectors.getLoader(notesTypes.REMOVE_NOTE)(state),
});

const enhancer = compose(
  connect(mapStateToProps, {
    updateNote: notesOperations.updateNote,
    removeNote: notesOperations.removeNote,
    fetchActivity: activityOperations.fetchActivity,
  }),
  withFormErrorHandler({}),
  withState('activityId', 'setActivityId', ''),
  withState('isRemoveNote', 'setIsRemoveNote', false),
  withFormReducer,
  withHandlers({
    onRemoveNote: ({ isRemoveNote, setIsRemoveNote }) => () => setIsRemoveNote(!isRemoveNote),
    onConfirmRemoveNote: props => () => {
      const { note } = props;
      props.removeNote(note._id).catch(props.catchFormError);
    },
    onSubmit: props => (e) => {
      const { reduceForm, note } = props;
      e.preventDefault();

      const reducedData = reduceForm(e.target);
      const request = {
        title: reducedData.title,
        description: reducedData.description,
      };

      const formData = new FormData();
      jsonToFormData(request, {}, formData);
      props
        .updateNote(note._id, formData)
        .then(() => props.toggle())
        .catch(props.catchFormError);
    },
  }),
  lifecycle({
    componentWillMount() {
      const { activity, note, fetchActivity } = this.props;
      if (!activity._id) {
        fetchActivity(note.activityId);
      }
    },
  })
);

export default enhancer(Component);
