import _ from 'lodash';
import moment from 'moment';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import jsPDF from 'jspdf';
import { I18n } from 'react-i18nify';
import FileSaver from 'file-saver';
import { usersTypes } from '../../../modules/users';
import { statisticsOperations } from '../../../modules/statistics';
import { activityOperations } from '../../../modules/activity';
import { groupsOperations } from '../../../modules/groups';
import { actionsSelectors } from '../../../modules/actions';
import Component from './Component';

const toDateStr = date => (date && moment(date).format('DD/MM/YY')) || '*';

const enhancer = compose(
  connect(
    state => ({
      ...actionsSelectors.getFilter(usersTypes.USER_STATISTICS)(state),
    }),
    {
      fetchExcelStatistics: statisticsOperations.fetchExcelStatistics,
      fetchActivity: activityOperations.fetchActivity,
      fetchGroup: groupsOperations.fetchGroup,
    }
  ),
  withHandlers({
    onDownloadStatistics: ({ filter, fetchExcelStatistics }) => () => {
      fetchExcelStatistics(filter).then((response) => {
        const blob = new Blob([response]);
        FileSaver.saveAs(blob, 'Statistics.xlsx');
      });
    },
    onExportPdf: ({ filter, fetchActivity, fetchGroup }) => async () => {
      const {
        from, to, activityId, groupId,
      } = filter || {};
      // Works with canvas
      const canvas = document.querySelector('.chartjs-render-monitor');
      if (!canvas) {
        return;
      }
      // creates image
      const canvasImg = canvas.toDataURL('image/png', 1.0);

      // creates PDF doc
      const doc = new jsPDF('landscape');
      // Optional - set properties on the document
      doc.setProperties({
        title: I18n.t('statistics'),
        author: 'RCYN',
        creator: 'RCYN',
      });

      // ------------- Add filter information ---------//
      doc.setFontSize(22);
      doc.text(I18n.t('statistics'), 125, 10);

      doc.setFontSize(18);

      doc.text(`${I18n.t('period')}:`, 10, 20);
      doc.text(`${I18n.t('activities')}:`, 100, 20);
      doc.text(`${I18n.t('team')}:`, 200, 20);

      doc.setFontSize(14);

      doc.text(`${toDateStr(from)} - ${toDateStr(to)}`, 10, 30);
      if (activityId) {
        const activity = await fetchActivity(activityId);
        doc.text(_.get(activity, 'title') || '', 100, 30);
      } else {
        doc.text('*', 100, 30);
      }
      if (groupId) {
        const group = await fetchGroup(groupId);
        doc.text(_.get(group, 'title') || '', 200, 30);
      } else {
        doc.text('*', 200, 30);
      }
      // ---------------------------------------------//

      // ------------- Add graph ----------------------//
      doc.setFontSize(20);
      doc.addImage(canvasImg, 'JPEG', 10, 50, 280, 150);
      doc.save('statistics.pdf');
      // ---------------------------------------------//
    },
  })
);

export default enhancer(Component);
