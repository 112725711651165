export const TIMEZONES = {
  // Central Europe (includes Denmark)
  CE: {
    // 31 Oct 3:00:00
    winter: {
      month: 9,
      date: 31,
      hour: 3,
      // CET
      utcOffset: 60, // minutes
    },
    // 26 Mar 2:00:00
    summer: {
      month: 2,
      date: 26,
      hour: 2,
      // CEST
      utcOffset: 120, // minutes
    },
  },
};

export const DEFAULT_TIMEZONE_KEY = 'CE';
