import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { activityTypes, activityOperations, activitySelectors } from '../../../modules/activity';
import { actionsSelectors } from '../../../modules/actions';
import { loaderSelectors } from '../../../modules/loader';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...loaderSelectors.getLoader(activityTypes.FETCH_ACTIVITIES)(state),
      ...activitySelectors.getActivities(state),
      ...actionsSelectors.getSearchValue(activityTypes.ACTIVITIES)(state),
    }),
    {
      fetchActivities: activityOperations.fetchActivities,
      clearActivities: activityOperations.clearActivities,
    }
  ),
  withRouter,
  withProps({ PER_PAGE: 10 }),
  withHandlers({
    onActivitiesFetch: props => () => {
      const {
        activities, PER_PAGE, fetchActivities, search, isLoading,
      } = props;
      if (!isLoading) fetchActivities(search, activities.keys.length, PER_PAGE);
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.onActivitiesFetch();
    },
    componentWillUpdate({ search: newSearchValue }) {
      const { search, clearActivities, onActivitiesFetch } = this.props;
      if (newSearchValue !== search) {
        clearActivities();
        setTimeout(onActivitiesFetch, 0); // stack delay for clearing Activities
      }
    },
    componentWillUnmount() {
      this.props.clearActivities();
    },
  })
);

export default enhancer(Component);
