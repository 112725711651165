import { uniq } from 'lodash';
import types from './types';

const defaultStateActivities = { keys: [], values: {}, count: 0 };

const messagesReducer = (state = defaultStateActivities, action) => {
  switch (action.type) {
    case types.FETCH_MESSAGES: {
      const keys = uniq([...state.keys, ...action.messages.keys]);
      return {
        keys,
        count: action.count,
        values: { ...state.values, ...action.messages.values },
      };
    }
    case types.CREATE_MESSAGE:
    case types.ADD_MESSAGE: {
      const keys = uniq([action.message._id, ...state.keys]);

      return {
        ...state,
        keys,
        values: { ...state.values, [action.message._id]: action.message },
      };
    }
    case types.CLEAR_MESSAGES:
      return defaultStateActivities;
    default:
      return state;
  }
};

export { messagesReducer };
