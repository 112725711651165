import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { usersTypes } from '../users';
import { actionsSelectors } from '../actions';

const getLessons = state => ({
  lessons: state.lessons,
});

const statisticsSelector = state => state.statistics;

const getStatisticsArray = createSelector(
  statisticsSelector,
  statistics => statistics.keys.map(key => statistics.values[key])
);

const getSortedStatistics = createSelector(
  getStatisticsArray,
  statistics => _.sortBy(statistics, 'startAt')
);

const periodMaps = {
  day: 'day',
  month: 'month',
  year: 'year',
};

const getGroupedLessonsStatistics = createSelector(
  getSortedStatistics,
  actionsSelectors.getFilter(usersTypes.USER_STATISTICS),
  (statistics, { filter: { from, to } = {} }) => {
    const min = from || _.get(statistics, '0.startAt');
    const max = to || _.get(statistics, `${statistics.length - 1}.startAt]`);
    const minDate = moment(min);
    const maxDate = moment(max);
    const dayBetween = maxDate.diff(moment(minDate), 'd');
    let groupBy = periodMaps.day;

    if (dayBetween + 1 > moment(min).daysInMonth()) {
      groupBy = (dayBetween > 365 && periodMaps.year) || periodMaps.month;
      const grouped = _.groupBy(statistics, s => moment(s.startAt)[groupBy]());
      const argued = _.keys(grouped).map(key => ({
        startAt: moment(grouped[key][0].startAt),
        // startAt: moment()[groupBy](key),
        visited: _.sum(grouped[key].map(g => g.visited)),
        count: _.sum(grouped[key].map(g => g.count)),
      }));

      return {
        statistics: _.sortBy(argued, 'startAt'),
        groupBy,
      };
    }
    return {
      statistics,
      groupBy,
    };
  }
);

const getStatisticsAsXY = createSelector(
  getGroupedLessonsStatistics,
  ({ statistics, groupBy }) => {
    const mapToXY = field => statistics.map(s => ({ x: s.startAt, y: _.get(s, field) }));
    const statisticsAsXY = {
      groupBy,
      visited: mapToXY('visited'),
      count: mapToXY('count'),
    };
    return { statisticsAsXY };
  }
);

export default {
  getLessons,
  getStatisticsAsXY,
};
