import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { FormGroup, Input, Label } from 'reactstrap';
import 'flatpickr/dist/themes/light.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons/index';
import { ADMIN, SUPER_ADMIN } from '../../../modules/authorize/types';
import Button from '../../../components/Button';

const ApproveAreaComponent = ({
  isLoading, formData, onCheck, onToggleApprove,
}) =>
  (![ADMIN, SUPER_ADMIN].includes(formData.userRole) && (
    <Fragment>
      <FormGroup>
        <Label
          className="custom-input d-flex align-items-center justify-content-between
        cursor-pointer fw-700"
        >
          {I18n.t('accept_privacy_policy_message')}
          <Input
            className="ml-2"
            type="checkbox"
            checked={!!_.get(formData, 'isAgreeGDPR')}
            onChange={onCheck('isAgreeGDPR')}
            disabled={formData.isAgreeGDPRDisable}
          />
          <FontAwesomeIcon
            className={`unchecked fz-24 ${formData.isAgreeGDPRDisable ? 'disabled-icon' : ''}`}
            icon={faSquare}
          />
          <FontAwesomeIcon
            className={`checked fz-24 ${formData.isAgreeGDPRDisable ? 'disabled-icon' : ''}`}
            icon={faCheckSquare}
          />
        </Label>
        <Label
          className="custom-input d-flex align-items-center justify-content-between
        cursor-pointer fw-700"
        >
          {I18n.t(`${formData.userRole}_approve_message_1`)}
          <Input
            className="ml-2"
            type="checkbox"
            checked={!!_.get(formData, 'documentApproval.personalId')}
            onChange={onCheck('documentApproval.personalId')}
          />
          <FontAwesomeIcon className="unchecked fz-24" icon={faSquare} />
          <FontAwesomeIcon className="checked fz-24" icon={faCheckSquare} />
        </Label>
        <Label
          className="custom-input d-flex align-items-center justify-content-between
        cursor-pointer fw-700"
        >
          {I18n.t(`${formData.userRole}_approve_message_2`)}
          <Input
            className="ml-2"
            type="checkbox"
            checked={!!_.get(formData, 'documentApproval.scanId')}
            onChange={onCheck('documentApproval.scanId')}
          />
          <FontAwesomeIcon className="unchecked fz-24" icon={faSquare} />
          <FontAwesomeIcon className="checked fz-24" icon={faCheckSquare} />
        </Label>
      </FormGroup>
      <FormGroup>
        {!_.get(formData, 'isCreateMode') && (
          <Button
            isLoading={isLoading}
            className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
            color={`${!formData.isActive ? 'primary' : 'danger'}`}
            size="lg"
            onClick={onToggleApprove('isActive')}
          >
            {formData.isActive ? I18n.t('de_activate') : I18n.t('activate')}
          </Button>
        )}
      </FormGroup>

      <FormGroup>
        {!_.get(formData, 'isCreateMode') && (
          <Button
            isLoading={isLoading}
            className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
            color="primary"
            size="lg"
            disabled={
              !(
                !!formData.isApproved ||
                (!!formData.documentApproval.personalId && !!formData.documentApproval.scanId)
              )
            }
            onClick={onToggleApprove('isApproved')}
          >
            {!formData.isApproved ? I18n.t('approve_profile') : I18n.t('un_approve_profile')}
          </Button>
        )}
      </FormGroup>
    </Fragment>
  )) ||
  null;

ApproveAreaComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onToggleApprove: PropTypes.func.isRequired,
};

export default ApproveAreaComponent;
