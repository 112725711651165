import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Image, Button, TextInput, ImageInput } from '../../../components/index';

const EditActivity = ({
  activity,
  toggle,
  isOpen,
  onSubmit,
  onFileSelect,
  isLoading,
  activityImage,
  isRemoveActivity,
  onRemoveActivity,
  onConfirmRemoveActivity,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('edit_activity')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          <TextInput
            value={I18n.t('activity_name')}
            name="title"
            defaultValue={activity.title}
            required
          />
          <TextInput
            value={I18n.t('description')}
            type="textarea"
            rows="4"
            name="description"
            defaultValue={activity.description}
          />
          <TextInput value={I18n.t('contact')} name="contact" defaultValue={activity.contact} />
          <FormGroup>
            <Label className="fz-18 fw-500 mt-1 cursor-pointer" for="select-image">
              {I18n.t('add_picture')}
              <FontAwesomeIcon className="ml-3 fz-32" icon={faCamera} size="2x" />
            </Label>
            <ImageInput
              id="select-image"
              className="d-none"
              type="file"
              name="file"
              onChange={onFileSelect}
            />
            <div className="create-activity-image">
              {activity.imageId && <Image imageId={activity.imageId} image={activityImage} />}
            </div>
          </FormGroup>

          <FormGroup>
            {isRemoveActivity && (
              <div className="mt-4 mb-4 fz-18 fw-500">{I18n.t('delete_activity_question')}</div>
            )}
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
              {isRemoveActivity && (
                <div className="d-inline-block">
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2 mr-2"
                    color="primary"
                    size="lg"
                    isLoading={isLoading}
                    onClick={onConfirmRemoveActivity}
                  >
                    {I18n.t('yes')}
                  </Button>
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                    color="danger"
                    size="lg"
                    onClick={onRemoveActivity}
                  >
                    {I18n.t('no')}
                  </Button>
                </div>
              )}
              {!isRemoveActivity && (
                <Button
                  className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                  color="danger"
                  size="lg"
                  onClick={onRemoveActivity}
                >
                  {I18n.t('delete_activity')}
                </Button>
              )}
              <Button
                className="rounded-0 fz-24 fw-900"
                color="primary"
                type="submit"
                size="lg"
                isLoading={isLoading}
              >
                {I18n.t('save_changes')}
              </Button>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

EditActivity.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  activity: PropTypes.object.isRequired,
  activityImage: PropTypes.object,
  isRemoveActivity: PropTypes.bool.isRequired,
  onRemoveActivity: PropTypes.func.isRequired,
  onConfirmRemoveActivity: PropTypes.func.isRequired,
};

EditActivity.defaultProps = {
  activityImage: '',
};

export default EditActivity;
