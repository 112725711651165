const jsonToFormData = (inJSON, parent, inFormData, parentKey) => {
  const formData = inFormData || new FormData(); // eslint-disable-line
  const parentJSON = parent || {};
  // eslint-disable-next-line
  for (const key in inJSON) {
    let constructedKey = key;
    if (parentKey) {
      constructedKey = `${parentKey}.${key}`;
    }

    const value = inJSON[key];
    if (value && value.constructor === {}.constructor) {
      jsonToFormData(value, parentJSON, formData, constructedKey);
    } else {
      formData.append(constructedKey, inJSON[key]);
      parentJSON[constructedKey] = inJSON[key];
    }
  }
  return formData;
};

export { jsonToFormData };
