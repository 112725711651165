import { get } from 'lodash';
import * as actions from './actions';
import types from './types';

const acceptPrivacyPolicy = () => dispatch => dispatch(actions.acceptPrivacyPolicy());

const rejecyPrivacyPolicy = () => dispatch => dispatch(actions.rejectPrivacyPolicy());

const catchPrivacyPolicyError = error => (dispatch) => {
  if (
    get(error, 'response.data.message') === types.REJECT_PRIVACYPOLICY_MESSAGE &&
    get(error, 'response.data.code') === types.REJECT_PRIVACYPOLICY_CODE
  ) {
    dispatch(actions.rejectPrivacyPolicy());
  }
};

export default {
  acceptPrivacyPolicy,
  rejecyPrivacyPolicy,
  catchPrivacyPolicyError,
};
