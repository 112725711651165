import moment from 'moment';
import { TIMEZONES } from '../../constants';

const isSmallDate = value => typeof value === 'string' && value.length < 6;
const getHour = str => str.split(':')[0];
const getMinutes = str => str.split(':')[1];

const getDSTPointStartsAt = ({ now, isDSTPointSummer, timezoneKey }) => {
  const { month, date, hour } = TIMEZONES[timezoneKey][isDSTPointSummer ? 'summer' : 'winter'];

  const pointStartAt = moment(now).set({
    month,
    date,
    hour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return isDSTPointSummer ? pointStartAt.add(1, 'year') : pointStartAt;
};

const getIsWinterTime = ({ now, timezoneKey }) => {
  const winterTimeStartsAt = getDSTPointStartsAt({ now, timezoneKey });
  const summerTimeStartsAt = getDSTPointStartsAt({ now, timezoneKey, isDSTPointSummer: true });

  return now.isAfter(winterTimeStartsAt) && now.isBefore(summerTimeStartsAt);
};

export const prepareDate = date =>
  isSmallDate(date)
    ? moment
      .utc()
      .set({
        minute: getMinutes(date),
        hour: getHour(date),
      })
      .toISOString()
    : new Date(date).toISOString();

export const formatDate = (startAt, endAt) => {
  const preparedStartDate = prepareDate(startAt);
  const preparedEndDate = prepareDate(endAt);
  if (moment(preparedStartDate).isAfter(preparedEndDate)) {
    return {
      preparedStartDate: moment(preparedStartDate)
        .add(-1, 'day')
        .toISOString(),
      preparedEndDate,
    };
  }
  return {
    preparedStartDate,
    preparedEndDate,
  };
};

export const getScheduledTime = ({ date, timezoneKey, isLocalTime }) => {
  if (!date) return '';
  const now = moment();
  const shouldShiftTime = !isLocalTime && getIsWinterTime({ now, timezoneKey });
  const mDate = moment(date);

  if (shouldShiftTime) {
    const { summer, winter } = TIMEZONES[timezoneKey];
    const shiftMinutes = summer.utcOffset - winter.utcOffset;
    mDate.add(shiftMinutes, 'minutes');
  }

  return mDate.format('HH:mm');
};

export const getScheduledPeriod = ({
  startDate, endDate, timezoneKey, isLocalTime,
}) => ({
  start: getScheduledTime({ date: startDate, timezoneKey, isLocalTime }),
  end: getScheduledTime({ date: endDate, timezoneKey, isLocalTime }),
});
