import { get, query, APIAddresses, getFile } from '../../utils/api';
import * as actions from './actions';
import types from './types';

const fetchStatistics = (from, to, activityId, groupId) => async (dispatch) => {
  try {
    const { data } = await get(
      `${APIAddresses.STATISTICS}${query({
        filter: {
          from,
          to,
          activityId,
          groupId,
        },
      })}`,
      dispatch,
      types.FETCH_STATISTICS
    );
    const { statistics } = data;
    dispatch(actions.fetchStatistics(statistics));
  } catch (error) {
    throw error;
  }
};

const clearStatistics = () => dispatch => dispatch(actions.clearStatistics());

const fetchExcelStatistics = filter => async (dispatch) => {
  try {
    const { data } = await getFile(
      `${APIAddresses.STATISTICS}/excel${query({ filter })}`,
      dispatch
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export default {
  fetchStatistics,
  clearStatistics,
  fetchExcelStatistics,
};
