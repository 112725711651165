import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { Modal, ModalHeader, ModalBody, Form, FormGroup } from 'reactstrap';
import { Button, TextInput } from '../../../components/index';

const EditNote = ({
  note,
  toggle,
  isOpen,
  onSubmit,
  isLoading,
  isRemoveNote,
  onRemoveNote,
  onConfirmRemoveNote,
}) => (
  <div>
    <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pr-4 pl-4 pt-4 pb-2 border-0" toggle={toggle}>
        {I18n.t('edit_note')}
      </ModalHeader>
      <ModalBody className="pr-4 pl-4">
        <Form onSubmit={onSubmit}>
          <TextInput value={I18n.t('Overskrift')} name="title" defaultValue={note.title} required />
          <TextInput
            value={I18n.t('contents')}
            type="textarea"
            rows="4"
            name="description"
            defaultValue={note.description}
          />

          <FormGroup>
            {isRemoveNote && (
              <div className="mt-4 mb-4 fz-18 fw-500">{I18n.t('delete_note_question')}</div>
            )}
            <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
              {isRemoveNote && (
                <div className="d-inline-block">
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2 mr-2"
                    color="primary"
                    size="lg"
                    isLoading={isLoading}
                    onClick={onConfirmRemoveNote}
                  >
                    {I18n.t('yes')}
                  </Button>
                  <Button
                    className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                    color="danger"
                    size="lg"
                    onClick={onRemoveNote}
                  >
                    {I18n.t('no')}
                  </Button>
                </div>
              )}
              {!isRemoveNote && (
                <Button
                  className="rounded-0 fz-24 fw-900 mb-sm-0 mb-2"
                  color="danger"
                  size="lg"
                  onClick={onRemoveNote}
                >
                  {I18n.t('delete_note')}
                </Button>
              )}
              <Button
                className="rounded-0 fz-24 fw-900"
                color="primary"
                type="submit"
                size="lg"
                isLoading={isLoading}
              >
                {I18n.t('save_changes')}
              </Button>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  </div>
);

EditNote.propTypes = {
  note: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRemoveNote: PropTypes.bool.isRequired,
  onRemoveNote: PropTypes.func.isRequired,
  onConfirmRemoveNote: PropTypes.func.isRequired,
};

export default EditNote;
