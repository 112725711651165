import _ from 'lodash';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { messagesTypes } from '../../../../modules/messages';
import { actionsOperations, actionsSelectors } from '../../../../modules/actions';

import Component from './Component';

const enhancer = compose(
  connect(
    state => ({
      ...actionsSelectors.getFilter(messagesTypes.MESSAGES)(state),
    }),
    { filterBy: actionsOperations.filter(messagesTypes.MESSAGES) }
  ),
  withState('date', 'setDate', ''),
  withHandlers({
    onDateChange: ({ filterBy }) => name => data => filterBy(name, data[0]),
    onUserSelected: ({ filterBy }) => user => filterBy('userId', _.get(user, '_id') || ''),
    onAddActivity: ({ filterBy }) => activity =>
      filterBy('activityId', _.get(activity, '_id') || ''),
    onResetDateFilter: ({ filterBy, setDate }) => () => {
      filterBy('from', undefined);
      filterBy('to', undefined);
      setDate('');
    },
    onGroupSelect: ({ filterBy }) => group => filterBy('groupId', _.get(group, '_id') || ''),
  })
);

export default enhancer(Component);
