import { connect } from 'react-redux';
import { compose, withState, withProps, withHandlers } from 'recompose';
import HeaderComponent from './Component';
import { routeList } from '../../pages/routes';
import { authSelectors } from '../../modules/authorize';

const mapStateToProps = state => ({
  user: authSelectors.getAutUser(state),
});

const menuItems = [
  {
    title: 'profiles',
    url: routeList.HOME,
  },
  {
    title: 'statistics',
    url: routeList.STATISTICS,
  },
  {
    title: 'activities',
    url: routeList.ACTIVITIES,
  },
  {
    title: 'participant_lists',
    url: routeList.PARTICIPANTS,
  },
  {
    title: 'message',
    url: routeList.MESSAGE,
  },
];

const enhancer = compose(
  connect(mapStateToProps),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    toggle: ({ isOpen, setIsOpen }) => () => setIsOpen(!isOpen),
  }),
  withProps({
    menuItems,
  })
);

export default enhancer(HeaderComponent);
