import types from './types';
import { normalize } from '../../utils/data';

const fetchGroups = (groups, count) => ({
  count,
  type: types.FETCH_GROUPS,
  groups: normalize('_id', groups),
});

const createGroup = group => ({
  group,
  type: types.CREATE_GROUP,
});

const clearGroups = () => ({
  type: types.CLEAR_GROUPS,
});

const updateGroup = group => ({
  group,
  type: types.UPDATE_GROUP,
});

const removeGroup = id => ({
  id,
  type: types.REMOVE_GROUP,
});

const setActiveGroup = groupId => ({
  groupId,
  type: types.SET_ACTIVE_GROUP,
});

export { fetchGroups, createGroup, updateGroup, setActiveGroup, removeGroup, clearGroups };
