import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { I18n, Translate } from 'react-i18nify';
import { Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { faCamera, faMale, faFemale, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';

import { authTypes } from '../../../modules/authorize';
import { FEMALE, MALE } from '../../../modules/authorize/types';
import { TextInput, ImageInput, Image } from '../../../components';

const {
  ADMIN, USER, VOLUNTEER, ROLES_TRANSLATE,
} = authTypes;
const roles = [ADMIN, USER, VOLUNTEER];

const Common = ({
  onFileSelect,
  user,
  avatar,
  getUserProp,
  formData,
  changeFormData,
  onFieldChange,
  formState,
}) => (
  <Fragment>
    <Row>
      <Col>
        <FormGroup>
          <Label className="fz-18 fw-500 mt-1 cursor-pointer" for="select-image">
            {I18n.t('add_picture')}
            <FontAwesomeIcon className="ml-3 fz-32" icon={faCamera} size="2x" />
          </Label>
          <ImageInput
            id="select-image"
            className="d-none"
            type="file"
            name="file"
            onChange={onFileSelect}
          />
          <div>
            <Image imageId={getUserProp(user, 'imageId')} image={avatar} />
          </div>
        </FormGroup>
      </Col>
      <Col>
        <Label className="fz-18 fw-500 mt-0 mt-sm-4 pt-3">{I18n.t('gender')}</Label>
        <FormGroup className="d-flex">
          <Label className="custom-input d-flex align-items-center mr-3 cursor-pointer">
            <FontAwesomeIcon className="fz-40 mr-2" icon={faFemale} size="2x" />
            <Input
              type="radio"
              checked={formData.gender === FEMALE}
              onChange={() => changeFormData('gender', FEMALE)}
            />
            <FontAwesomeIcon className="unchecked" icon={faCircle} />
            <FontAwesomeIcon className="checked" icon={faDotCircle} />
          </Label>
          <Label className="custom-input d-flex align-items-center cursor-pointer">
            <FontAwesomeIcon className="fz-40 mr-2" icon={faMale} size="2x" />
            <Input
              type="radio"
              checked={formData.gender === MALE}
              onChange={() => changeFormData('gender', MALE)}
            />
            <FontAwesomeIcon className="unchecked" icon={faCircle} />
            <FontAwesomeIcon className="checked" icon={faDotCircle} />
          </Label>
        </FormGroup>
      </Col>
    </Row>
    <FormGroup className="select-customize mb-3">
      <Label className="fz-18 fw-500 mt-1">{I18n.t('user_groups')}</Label>
      <FormGroup className="select-customize">
        <FontAwesomeIcon icon={faAngleDown} />
        <Input
          disabled={formData.isSelfEditMode}
          type="select"
          name="role"
          value={formData.userRole}
          onChange={e => changeFormData('userRole', e.target.value)}
        >
          {roles.map(role => (
            <option key={role} value={role}>
              {I18n.t(ROLES_TRANSLATE[role])}
            </option>
          ))}
        </Input>
      </FormGroup>
    </FormGroup>
    <TextInput
      value={`${I18n.t('first_name')}*`}
      name="firstName"
      onChange={onFieldChange('firstName')}
      defaultValue={getUserProp(user, 'firstName', '')}
      required
      message={_.get(formState, 'errors.firstName.message') || ''}
    />
    <TextInput
      value={`${I18n.t('last_name')}*`}
      name="lastName"
      defaultValue={getUserProp(user, 'lastName', '')}
      required
      onChange={onFieldChange('lastName')}
      message={_.get(formState, 'errors.lastName.message') || ''}
    />
    <TextInput
      value={`${I18n.t('address')}*`}
      name="address"
      defaultValue={getUserProp(user, 'address')}
      required
      onChange={onFieldChange('address')}
      message={_.get(formState, 'errors.address.message') || ''}
    />
    <TextInput
      value={I18n.t('facebook_profile')}
      name="facebookUrl"
      defaultValue={getUserProp(user, 'facebookUrl')}
    />
    <FormGroup>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label className="fz-18 fw-500">{I18n.t('zip_code')}*</Label>
            <Input
              type="number"
              name="zipCode"
              defaultValue={getUserProp(user, 'zipCode')}
              required
              onChange={onFieldChange('zipCode')}
              invalid={!!_.get(formState, 'errors.zipCode.message')}
            />
            <FormFeedback>
              <Translate value={_.get(formState, 'errors.zipCode.message') || ''} />
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col md={9}>
          <Label className="fz-18 fw-500">By</Label>
          <Input type="text" name="city" defaultValue={getUserProp(user, 'city')} />
        </Col>
      </Row>
    </FormGroup>
    <TextInput
      value={`${I18n.t('email')}*`}
      name="email"
      defaultValue={getUserProp(user, 'email')}
      required
      onChange={onFieldChange('email')}
      message={_.get(formState, 'errors.email.message') || ''}
    />
  </Fragment>
);

Common.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  avatar: PropTypes.object,
  getUserProp: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  changeFormData: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

Common.defaultProps = {
  avatar: null,
};

export default Common;
